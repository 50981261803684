import React from "react";

import { Dialog, Stack, styled, Typography, useTheme } from "@mui/material";
import { CloseButton } from "Components/CloseButton";
import { Link } from "react-router-dom";
import { routesPaths } from "routesPaths";
import { INotification } from "types/Models";
import { formatDate } from "utils/helpers";

type ModalPropsType = {
    isModalOpen: boolean;
    handleClose: () => void;
    element: INotification | undefined;
};

const BottomSectionStyled = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    gap: "15px",
    alignSelf: "start",
    [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        alignItems: "flex-start",
    },
}));

const TitleStyled = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
        fontSize: "22px",
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
    },
}));

const MessageStyled = styled(Typography)(({ theme }) => ({
    whiteSpace: "pre-wrap",
    [theme.breakpoints.down("md")]: {
        fontSize: "18px",
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
    },
}));

const ModalContainer = styled(Stack)(({ theme }) => ({
    width: "40vw",
    minHeight: "40vh",
    padding: "40px 50px",
    boxSizing: "border-box",
    justifyContent: "space-between",
    gap: "20px",
    [theme.breakpoints.down("md")]: {
        padding: "20px",
        width: "80vw",
        minHeight: "40vh",
    },
    [theme.breakpoints.down("sm")]: {
        width: "80vw",
        minHeight: "60vh",
    },
}));

function getContent(element: INotification | undefined, handleClose: () => void) {
    const theme = useTheme();
    return (
        <>
            <Stack spacing={3}>
                <Stack direction="row" justifyContent="space-between" width="100%" spacing={2}>
                    <TitleStyled variant="h4" color="primary.dark">
                        {element && element.subject}
                    </TitleStyled>
                    <CloseButton handleClose={handleClose} />
                </Stack>
                <MessageStyled
                    variant="body1"
                    maxWidth="400px"
                    color="info.darker"
                    fontWeight="600"
                >
                    {element && element.message.replace("\n ", "\n")}
                    <br />
                    Kindly check under{" "}
                    <Link
                        to={routesPaths.submissions}
                        style={{ color: `${theme.palette.primary.main}` }}
                    >
                        submissions
                    </Link>{" "}
                    for further detail
                </MessageStyled>
            </Stack>
            <BottomSectionStyled>
                <Typography variant="button" align="center" color="primary.main">
                    Log Ref: {element && element.id}
                </Typography>
                <Typography variant="subtitle2" align="center" color="action.disabled">
                    {element && formatDate(element.sentTime)}
                </Typography>
            </BottomSectionStyled>
        </>
    );
}

export function Modal({ isModalOpen, handleClose, element }: ModalPropsType) {
    return (
        <Dialog open={isModalOpen}>
            <ModalContainer>{getContent(element, handleClose)}</ModalContainer>
        </Dialog>
    );
}
