import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Alert, AlertColor, AlertTitle, IconButton, Snackbar } from "@mui/material";

interface IModalPropType {
    setModalOpen: (value: boolean) => void;
    modalMessage: string;
    modalType: AlertColor | undefined;
    isModalOpen: boolean;
}

export function SnackBar({ setModalOpen, isModalOpen, modalMessage, modalType }: IModalPropType) {
    return (
        <Snackbar
            open={isModalOpen}
            autoHideDuration={10000}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            onClose={() => setModalOpen(false)}
            sx={{ boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)", borderRadius: "6px" }}
        >
            <Alert
                severity={modalType}
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setModalOpen(false);
                        }}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
            >
                <AlertTitle>{modalType === "error" ? "Oops!" : "Success!"}</AlertTitle>
                {modalMessage}
            </Alert>
        </Snackbar>
    );
}
