import React from "react";

import { Box, Button, Stack, styled, SvgIcon, Typography } from "@mui/material";
import { ReactComponent as arrow } from "Assets/Images/arrow_right.svg";
import console_img from "Assets/Images/console.png";

const Container = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    backgroundColor: theme.palette.primary.light,
    padding: "60px 60px 60px 10px",
    boxSizing: "border-box",
    gap: "30px",
    maxWidth: "911px",
    border: "1px solid",
    borderRadius: "8px",
    borderColor: theme.palette.warning.contrastText,
    [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
    },
}));

const Image = styled(Box)(({ theme }) => ({
    width: "344px",
    height: "242px",
    backgroundImage: `url(${console_img})`,
    backgroundSize: "cover",
    flexShrink: 0,
    [theme.breakpoints.down("sm")]: {
        display: "none",
    },
}));

export function ConsoleSection() {
    return (
        <Container>
            <Image />
            <Stack maxWidth="454px" spacing={3}>
                <Typography
                    sx={{
                        fontWeight: "700",
                        fontSize: "40px",
                        lineHeight: "1.2",
                        color: ({ palette }) => palette.warning.dark,
                        maxWidth: "385px",
                    }}
                >
                    Interswitch Developer Console
                </Typography>
                <Typography>
                    Click on the Get Started button to onboard on our Transfer Service
                </Typography>
                <a
                    href={process.env.REACT_APP_INTERSWITCH_CONSOLE_URL}
                    target="_blank"
                    rel="noreferrer"
                >
                    <Button
                        sx={{
                            display: "flex",
                            gap: "5px",
                            backgroundColor: ({ palette }) => palette.warning.dark,
                            "&:hover": {
                                backgroundColor: ({ palette }) => palette.secondary.light,
                            },
                        }}
                    >
                        <Typography>Get started</Typography>
                        <SvgIcon
                            component={arrow}
                            inheritViewBox
                            sx={{ color: "transparent", fontSize: "20px" }}
                        />
                    </Button>
                </a>
            </Stack>
        </Container>
    );
}
