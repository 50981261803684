import React, { useEffect, useState } from "react";

import { Box, Stack, styled, Typography, useTheme } from "@mui/material";
import { api } from "Assets/API/api";
import mail from "Assets/Images/mail.png";
import { Loader } from "Components/Loader";
import { SmallNotification } from "Components/SmallNotification";
import { SnackBar } from "Components/SnackBar";
import { StyledButton } from "Components/StyledButton";
import { useLocation, useNavigate } from "react-router-dom";
import { routesPaths } from "routesPaths";
import { getDecryptedFromStorage } from "utils/encryption";

type ModalProps = {
    type: string;
};

type DataType = {
    h1: string;
    description: string;
    onGotItUrl: string;
};

const RegModalData = {
    h1: "Verify your Account",
    description:
        "Thank you for registering on MyBanq. We just sent a verification link to your email. Click on it to complete your registration and get started.",
    onGotItUrl: "/",
};

const ResetModalData = {
    h1: "Reset your password",
    description:
        "We'll send a reset password link to your email if it's associated with your account. Click on it to set a new password",
    onGotItUrl: `${routesPaths.index}`,
};

const Container = styled(Stack)(({ theme }) => ({
    maxWidth: "590px",
    padding: "45px 60px 45px",
    boxSizing: "border-box",
    gap: "30px",
    alignItems: "center",
    backgroundColor: theme.palette.info.main,
    border: "solid 1px",
    borderColor: theme.palette.warning.contrastText,
    borderRadius: "4px",
    [theme.breakpoints.down("sm")]: {
        padding: "25px 20px 25px",
    },
}));

export function VerificationRegModal({ type }: ModalProps) {
    const Data: DataType = type === "reg" ? RegModalData : ResetModalData;
    const navigate = useNavigate();
    const location = useLocation();
    const prevPage = location.state?.from?.pathname;
    const onCGotItClick = () => {
        navigate(Data.onGotItUrl);
    };
    const theme = useTheme();
    const email = getDecryptedFromStorage(localStorage, "newUserEmail");
    const password = getDecryptedFromStorage(localStorage, "newUserPassword");
    const forgEmail = getDecryptedFromStorage(localStorage, "resetEmail");
    const [isModalOpen, setModalOpen] = useState(false);
    const [isLoaderOpen, setLoaderOpen] = useState(false);
    const [isSnackOpen, setSnackOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const cleanUpLocalStorage = () => {
        localStorage.removeItem("resetEmail");
        localStorage.removeItem("newUserEmail");
        localStorage.removeItem("newUserPassword");
    };
    useEffect(() => {
        if (type === "reg" && (prevPage !== "/registration" || !email))
            navigate(routesPaths.registration);
        if (type !== "reg" && (prevPage !== "/reset-password-page" || !forgEmail))
            navigate(routesPaths.reset_password_page);
        return () => {
            window.removeEventListener("beforeunload", cleanUpLocalStorage);
        };
    });
    window.addEventListener("beforeunload", cleanUpLocalStorage);

    return (
        <Container>
            <Stack sx={{ alignItems: "center", gap: "5px" }}>
                <Box
                    sx={{
                        width: "129px",
                        height: "129px",
                        backgroundImage: `url(${mail})`,
                        backgroundSize: "contain",
                    }}
                />
                <Typography
                    variant="h2"
                    sx={{
                        color: ({ palette }) => palette.primary.contrastText,
                        textAlign: "center",
                    }}
                >
                    {Data.h1}
                </Typography>
                <Typography
                    sx={{
                        color: ({ palette }) => palette.secondary.darker,
                        fontWeight: 400,
                        fontSize: "18px",
                    }}
                >
                    Please check your email
                </Typography>
            </Stack>

            <Typography variant="body2" align="center">
                {Data.description}
            </Typography>
            <StyledButton
                text="Got it!"
                width="197px"
                isDisabled={false}
                onHandleClick={onCGotItClick}
            />
            <Typography variant="body2" align="center">
                Didn’t see the email? Click{" "}
                <Typography
                    onClick={() => {
                        setLoaderOpen(true);
                        api.post(
                            type === "reg" ? "/auth/sign-up" : "/auth/password-reset/initate",
                            type === "reg" ? { email, password } : { email: forgEmail },
                        )
                            .then(() => {
                                setModalOpen(true);
                            })
                            .catch((err) => {
                                setSnackMessage(err.message);
                                setSnackOpen(true);
                            })
                            .finally(() => setLoaderOpen(false));
                    }}
                    sx={{
                        cursor: "pointer",
                        color: `${theme.palette.primary.main}`,
                        display: "inline-block",
                    }}
                >
                    here
                </Typography>{" "}
                to resend it.
            </Typography>
            {isModalOpen && (
                <SmallNotification
                    modalHeader="Please check your email"
                    modalMessage="The link was resent"
                    setModalOpen={setModalOpen}
                />
            )}
            <Loader isLoaderOpen={isLoaderOpen} />
            <SnackBar
                isModalOpen={isSnackOpen}
                setModalOpen={setSnackOpen}
                modalMessage={snackMessage}
                modalType="error"
            />
        </Container>
    );
}
