import React from "react";

import { Box } from "@mui/material";
import { BusinessInfoSection } from "Pages/ApprovalPage/Components/BusinessInfoSection";
import { routesPaths } from "routesPaths";

import { SectionFooter } from "../SectionFooter";
import { SectionHeader } from "../SectionHeader";
import { SimpleVerticalStepper } from "../SimpleVerticalStepper";
import { useData } from "../SubmissionPage";
import { Container } from "./API";

export function BusinessInfo() {
    const data = useData()?.data?.profile;
    return (
        <>
            <SimpleVerticalStepper step={1} />
            <Container>
                <SectionHeader
                    name="Business Information"
                    needPercentage={false}
                    needStatus={false}
                />
                <BusinessInfoSection data={data} />
                <Box
                    sx={{
                        width: "100%",
                        height: "1px",
                        margin: "auto",
                        m: "37px 0",
                        bgcolor: ({ palette }) => palette.success.light,
                    }}
                />
                <SectionFooter
                    status={data?.reviewStatus}
                    message={data?.message}
                    editURL={routesPaths.business_info}
                />
            </Container>
        </>
    );
}
