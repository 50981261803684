import React from "react";

import { Stack, Typography } from "@mui/material";
import { formatDate } from "utils/helpers";

import { IInsightStatuses } from "./DashboardHeader";
import { Container, Header } from "./NotificationSection";
import { StatusStep } from "./StatusStep";

interface IStatusProps {
    statuses: IInsightStatuses | undefined;
}

export function StatusSection({ statuses }: IStatusProps) {
    let step = 1;
    if (statuses)
        switch (statuses.reviewStatus) {
            case null:
                step = 1;
                break;
            case "PENDING_REVIEW":
                step = 2;
                break;
            case "REVIEW_IN_PROGRESS":
                step = 3;
                break;
            default:
                step = 4;
        }
    return (
        <Container>
            <Header>
                <Typography variant="button" color="common.black">
                    Your Application Status
                </Typography>
            </Header>
            <Stack sx={{ padding: "30px 20px" }}>
                <StatusStep
                    step={step}
                    id={1}
                    header="Application Created"
                    date={
                        statuses?.applicationStatus?.dateCreated
                            ? formatDate(statuses.applicationStatus.dateCreated)
                            : ""
                    }
                />
                <StatusStep
                    step={step}
                    id={2}
                    header="Application Pending Review"
                    date={
                        statuses?.applicationStatus?.dateSubmitted
                            ? formatDate(statuses.applicationStatus.dateSubmitted)
                            : ""
                    }
                />
                <StatusStep
                    step={step}
                    id={3}
                    header="Application In Review"
                    date={
                        statuses?.applicationStatus?.dateReviewStarted
                            ? formatDate(statuses.applicationStatus.dateReviewStarted)
                            : ""
                    }
                />
                <StatusStep
                    step={step}
                    id={4}
                    header={
                        statuses?.reviewStatus === "APPROVED"
                            ? "Application Approved"
                            : "Update requested"
                    }
                    date={
                        statuses?.applicationStatus?.dateOverallApproved
                            ? formatDate(statuses.applicationStatus.dateOverallApproved)
                            : ""
                    }
                />
            </Stack>
        </Container>
    );
}
