import React, { useEffect } from "react";

import { Stack } from "@mui/material";
import { Footer } from "Components/Footer";
import { HeaderWithNavigation } from "Components/HeaderWithNavigation";
import { useLocation } from "react-router-dom";

import { Main } from "./Components/Main";

export function HomePage() {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (
        <Stack sx={{ justifyContent: "center", alignItems: "center" }}>
            <Stack sx={{ width: "100%", maxWidth: "1920px" }}>
                <HeaderWithNavigation />
                <Main />
                <Footer />
            </Stack>
        </Stack>
    );
}
