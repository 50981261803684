/* eslint-disable no-param-reassign */
import React, { useState } from "react";

import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Box, Checkbox, IconButton, Stack, styled, TextField, Typography } from "@mui/material";
import { api } from "Assets/API/api";
import { CustomInputLabel } from "Components/CustomInputLabel";
import { ErrorField } from "Components/ErrorField";
import { Loader } from "Components/Loader";
import { SnackBar } from "Components/SnackBar";
import { StyledButton } from "Components/StyledButton";
import { Form, Formik, FormikErrors } from "formik";
import useAuthService from "Pages/Authorization/useAuthService";
import { useLocation, useNavigate } from "react-router-dom";
import { routesPaths } from "routesPaths";
import { addEncryptedToStorage } from "utils/encryption";

import { validationSchema } from "../Registration.validation";

type DataType = {
    email: string;
    password: string;
    passwordConfirmation: string;
    isAgreed: boolean;
};

const FormContainer = styled(Stack)(({ theme }) => ({
    alignItems: "center",
    alignSelf: "flex-end",
    maxWidth: "520px",
    [theme.breakpoints.down("md")]: {
        margin: "auto",
    },
}));

const StyledLink = styled("a")(({ theme }) => ({
    fontWeight: 600,
    fontSize: "14px",
    color: theme.palette.primary.dark,
    textDecoration: "underline",
}));

export function RegistrationForm() {
    const navigate = useNavigate();
    const location = useLocation();
    const { logout } = useAuthService();
    const [isPassVisible, setPassVisibility] = useState(false);
    const [isLoaderOpen, setLoaderOpen] = useState(false);
    const [isSnackOpen, setSnackOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const handlePassClick = () => {
        setPassVisibility(!isPassVisible);
    };
    const [isPassConfVisible, setPassConfVisibility] = useState(false);
    const handlePassConfClick = () => {
        setPassConfVisibility(!isPassConfVisible);
    };
    const onSubmitForm = (values: DataType, errors: FormikErrors<DataType>) => {
        setLoaderOpen(true);
        api.post("/auth/sign-up", {
            email: values.email,
            password: values.password,
        })
            .then(() => {
                addEncryptedToStorage(localStorage, "newUserEmail", values.email);
                addEncryptedToStorage(localStorage, "newUserPassword", values.password);
                logout();
                navigate(routesPaths.reg_verification, {
                    state: { from: location },
                });
            })
            .catch((err) => {
                setSnackMessage(err.message);
                setSnackOpen(true);
                if (err.response && err.response.request.status === 403) {
                    // eslint-disable-next-line no-param-reassign
                    errors.email = "User with specified email already exists";
                }
            })
            .finally(() => setLoaderOpen(false));
    };
    return (
        <>
            <Formik<DataType>
                initialValues={{
                    email: "",
                    password: "",
                    passwordConfirmation: "",
                    isAgreed: false,
                }}
                onSubmit={() => console.log("here")}
                validationSchema={validationSchema}
            >
                {({ values, errors, handleBlur, handleChange, touched, setErrors }) => (
                    <Form id="registration" data-testid="form">
                        <FormContainer>
                            <Box mt="20px" width="100%">
                                <Box>
                                    <CustomInputLabel label="Email Address" isRequired />
                                    <TextField
                                        inputProps={{
                                            style: {
                                                fontSize: 16,
                                            },
                                        }}
                                        name="email"
                                        error={errors.email !== undefined && touched.email}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            e.target.value = e.target.value.trim();
                                            handleChange(e);
                                            setErrors({ ...errors, email: undefined });
                                        }}
                                        value={values.email}
                                        placeholder="Enter official email address"
                                        autoComplete="new-password"
                                    />
                                    <ErrorField errors={errors.email} touched={touched.email} />
                                </Box>
                                <Box>
                                    <CustomInputLabel label="Password" isRequired />
                                    <TextField
                                        InputProps={{
                                            style: {
                                                fontSize: 16,
                                            },
                                            endAdornment: (
                                                <IconButton onClick={handlePassClick} edge="end">
                                                    {isPassVisible ? (
                                                        <VisibilityOutlinedIcon />
                                                    ) : (
                                                        <VisibilityOffOutlinedIcon />
                                                    )}
                                                </IconButton>
                                            ),
                                        }}
                                        name="password"
                                        error={errors.password !== undefined && touched.password}
                                        type={isPassVisible ? "text" : "password"}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            e.target.value = e.target.value.trim();
                                            handleChange(e);
                                            setErrors({ ...errors, password: undefined });
                                        }}
                                        value={values.password}
                                        placeholder="Enter password"
                                        autoComplete="new-password"
                                    />
                                    <ErrorField
                                        errors={errors.password}
                                        touched={touched.password}
                                    />
                                </Box>
                                <Box>
                                    <CustomInputLabel label="Confirm Password" isRequired />
                                    <TextField
                                        InputProps={{
                                            style: {
                                                fontSize: 16,
                                            },
                                            endAdornment: (
                                                <IconButton
                                                    onClick={handlePassConfClick}
                                                    edge="end"
                                                >
                                                    {isPassConfVisible ? (
                                                        <VisibilityOutlinedIcon />
                                                    ) : (
                                                        <VisibilityOffOutlinedIcon />
                                                    )}
                                                </IconButton>
                                            ),
                                        }}
                                        name="passwordConfirmation"
                                        error={
                                            errors.passwordConfirmation !== undefined &&
                                            touched.passwordConfirmation
                                        }
                                        type={isPassConfVisible ? "text" : "password"}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            e.target.value = e.target.value.trim();
                                            handleChange(e);
                                            setErrors({
                                                ...errors,
                                                passwordConfirmation: undefined,
                                            });
                                        }}
                                        value={values.passwordConfirmation}
                                        placeholder="Re enter password"
                                        autoComplete="new-password"
                                    />
                                    <ErrorField
                                        errors={errors.passwordConfirmation}
                                        touched={touched.passwordConfirmation}
                                    />
                                </Box>
                            </Box>
                            <Stack
                                direction="row"
                                spacing="13px"
                                sx={{ alignItems: "flex-start", mb: "25px" }}
                            >
                                <Checkbox onChange={handleChange} name="isAgreed" />
                                <Typography variant="body2">
                                    By signing up to this portal, you agree to our{" "}
                                    <StyledLink
                                        href="https://wl-dev.vanso.com/api/file-service/onboarding-service/198d963f-51f4-4c56-b1e2-f2dfc1a5d046--Ts&CsOnboardingPortal.docx"
                                        rel="noreferrer"
                                        download
                                    >
                                        Terms & Conditions and Privacy Policy
                                    </StyledLink>
                                </Typography>
                            </Stack>
                            <StyledButton
                                text="Submit"
                                width="100%"
                                isDisabled={
                                    !!errors.email ||
                                    !!errors.password ||
                                    !!errors.passwordConfirmation ||
                                    !values.email ||
                                    !values.passwordConfirmation ||
                                    !values.password ||
                                    !values.isAgreed
                                }
                                onHandleClick={() => onSubmitForm(values, errors)}
                            />
                        </FormContainer>
                    </Form>
                )}
            </Formik>
            <Loader isLoaderOpen={isLoaderOpen} />
            <SnackBar
                isModalOpen={isSnackOpen}
                setModalOpen={setSnackOpen}
                modalMessage={snackMessage}
                modalType="error"
            />
        </>
    );
}
