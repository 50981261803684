import React, { useEffect } from "react";

import { Stack, styled, Typography } from "@mui/material";
import { LinkMoveBack } from "Components/LinkMoveBack";
import { Logo, logoColors } from "Components/Logo";
import { FormContainer } from "Pages/Authorization/AuthorizationPage";
import { Slider } from "Pages/RegistrationPage/Components/Slider";
import { useLocation } from "react-router-dom";

import { AddNewPasswordForm } from "./Components/AddNewPasswordForm";
import { ResetPasswordForm } from "./Components/ResetPasswordForm";

type PageProps = {
    type: string;
};

const PageContainer = styled(Stack)(({ theme }) => ({
    width: "100%",
    height: "100vh",
    maxWidth: "1920px",
    padding: "20px 20px 0",
    margin: "auto",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    gap: "20px",
    [theme.breakpoints.down("md")]: {
        flexDirection: "column",
    },
    [theme.breakpoints.down("sm")]: {
        padding: "50px 20px 0",
    },
}));

export function ResetPasswordPage({ type }: PageProps) {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (
        <PageContainer>
            {type === "reset" ? (
                <FormContainer>
                    <Stack sx={{ gap: "35px" }}>
                        <Logo color={logoColors.green} />
                        <LinkMoveBack url="/" text="Back" />
                        <Stack gap="12px">
                            <Typography variant="h2">Forgotten your password?</Typography>
                            <Typography maxWidth="432px" variant="h6" lineHeight="1.2">
                                Type the email you used to sign up on MyBanq and we&apos;ll send you
                                a password reset email
                            </Typography>
                        </Stack>
                    </Stack>
                    <ResetPasswordForm />
                </FormContainer>
            ) : (
                <FormContainer>
                    <Stack sx={{ gap: "35px" }}>
                        <Logo color={logoColors.green} />
                        <LinkMoveBack url="/" text="Back" />
                        <Stack gap="12px">
                            <Typography variant="h2">Reset password</Typography>
                            <Typography maxWidth="432px" variant="h6" lineHeight="1.2">
                                Please type a new password
                            </Typography>
                        </Stack>
                    </Stack>
                    <AddNewPasswordForm />
                </FormContainer>
            )}
            <Slider issideSlider={false} />
        </PageContainer>
    );
}
