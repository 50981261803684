/* eslint-disable no-param-reassign */
import axios from "axios";
import { routesPaths } from "routesPaths";
import { getDecryptedFromStorage } from "utils/encryption";

export const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE,
    withCredentials: true,
});

api.interceptors.request.use((config) => {
    if (!config.headers) config.headers = {};
    config.headers.sessionID = getDecryptedFromStorage(localStorage, "token");
    return config;
});
api.interceptors.response.use(
    // eslint-disable-next-line arrow-body-style
    (response) => {
        return response;
    },
    (error) => {
        if (
            error.response &&
            error.response.status === 401 &&
            window.location.pathname !== `${process.env.PUBLIC_URL}${routesPaths.authorization}`
        ) {
            window.location.assign(`${process.env.PUBLIC_URL}${routesPaths.authorization}`);
            localStorage.removeItem("token");
            localStorage.removeItem("currentUser");
            localStorage.removeItem("institutionId");
            localStorage.removeItem("bankName");
            localStorage.removeItem("isSubmitted");
        }
        return Promise.reject(error);
    },
);
