import React from "react";

import { Box, Stack, styled } from "@mui/material";
import slider1 from "Assets/Images/s1.png";
import slider2 from "Assets/Images/s2.png";
import slider3 from "Assets/Images/s3.png";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

const sliderImages = [
    {
        id: 0,
        src: slider1,
        alt: "Man with mobile phone",
    },
    {
        id: 1,
        src: slider2,
        alt: "Smiling woman talking on the phone",
    },
    {
        id: 2,
        src: slider3,
        alt: "Wallet with cards",
    },
];

const SliderContainer = styled(Stack)(({ theme }) => ({
    maxWidth: "530px",
    flexShrink: 0,
    [theme.breakpoints.down("md")]: {
        display: "none",
    },
    [theme.breakpoints.down("lg")]: {
        flexShrink: 1,
    },
}));

export function Slider({ issideSlider }: { issideSlider: boolean }) {
    return (
        <SliderContainer data-testid="slider">
            <Box>
                <Swiper
                    spaceBetween={50}
                    pagination={{ clickable: true }}
                    modules={[Autoplay, Pagination]}
                    autoplay={{
                        delay: 4000,
                    }}
                    speed={300}
                >
                    {sliderImages.map(({ id, src, alt }) => (
                        <SwiperSlide>
                            <Box key={id}>
                                <Box
                                    component="img"
                                    sx={{
                                        maxHeight: 630,
                                        maxWidth: 530,
                                        borderRadius: issideSlider ? "45px 0px 0px 45px" : "45px",
                                    }}
                                    src={src}
                                    alt={alt}
                                />
                            </Box>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Box>
        </SliderContainer>
    );
}
