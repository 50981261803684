import React from "react";

import { Box, Stack, Typography } from "@mui/material";
import { KYCType } from "Pages/KYCInfoPage/KYC.model";
import { routesPaths } from "routesPaths";

import { useData } from "../SubmissionPage";
import { AccordionFile } from "./AccordionFile";

export function KYCContent() {
    const data = useData()?.data;
    return data ? (
        <Stack spacing={3}>
            <Box>
                <Typography
                    variant="body2"
                    sx={{ color: ({ palette }) => palette.grey[500], pb: "10px" }}
                >
                    Certification
                </Typography>
                <Stack spacing={1}>
                    {data.certifications?.map((item: KYCType["documents"][0]) => (
                        <AccordionFile key={item.id} item={item} url={routesPaths.certification} />
                    ))}
                </Stack>
            </Box>
            <Box>
                <Typography
                    variant="body2"
                    sx={{ color: ({ palette }) => palette.grey[500], pb: "10px" }}
                >
                    Identity Verification
                </Typography>
                <Stack spacing={1}>
                    {data.identity?.map((item: KYCType["documents"][0]) => (
                        <AccordionFile key={item.id} item={item} url={routesPaths.identity} />
                    ))}
                </Stack>
            </Box>
            <Box>
                <Typography
                    variant="body2"
                    sx={{ color: ({ palette }) => palette.grey[500], pb: "10px" }}
                >
                    License Verification
                </Typography>
                <Stack spacing={1}>
                    {data.license?.map((item: KYCType["documents"][0]) => (
                        <AccordionFile key={item.id} item={item} url={routesPaths.license} />
                    ))}
                </Stack>
            </Box>
            <Box>
                <Typography
                    variant="body2"
                    sx={{ color: ({ palette }) => palette.grey[500], pb: "10px" }}
                >
                    Policy Documents
                </Typography>
                <Stack spacing={1}>
                    {data.policy?.map((item: KYCType["documents"][0]) => (
                        <AccordionFile key={item.id} item={item} url={routesPaths.policy} />
                    ))}
                </Stack>
            </Box>
            <Box>
                <Typography
                    variant="body2"
                    sx={{ color: ({ palette }) => palette.grey[500], pb: "10px" }}
                >
                    Other Documents
                </Typography>
                <Stack spacing={1}>
                    {data.other_documents?.map((item: KYCType["documents"][0]) => (
                        <AccordionFile key={item.id} item={item} url={routesPaths.other} />
                    ))}
                </Stack>
            </Box>
        </Stack>
    ) : (
        <Typography variant="body1" color="warning.main" fontStyle="italic">
            No information to display. Please fill out the appropriate form
        </Typography>
    );
}
