import React, { useEffect, useState } from "react";

import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import { Box, Stack, SvgIcon, Tooltip, Typography, useTheme } from "@mui/material";
import { ReactComponent as close } from "Assets/Images/close.svg";
import { ReactComponent as download } from "Assets/Images/download.svg";
import { ReactComponent as Info } from "Assets/Images/info.svg";
import axios, { AxiosRequestConfig } from "axios";
import { trimName } from "utils/helpers";

import { SnackBar } from "./SnackBar";

type InputPropsType = {
    name: string;
    id: string;
    // eslint-disable-next-line react/require-default-props
    handleBlur?: (e: React.FocusEvent<HTMLElement>) => void;
    setImageURL: (arg0: string, arg1: string) => void;
    imageURL: string;
    imageName: string;
    isLogoType: boolean;
};

type ImagePromType = { width: number; height: number };

export function DragnDropInput({
    name,
    id,
    handleBlur,
    setImageURL,
    imageURL,
    imageName,
    isLogoType,
}: InputPropsType) {
    const theme = useTheme();
    const [logoImage, setLogoImage] = useState<File | null>();
    const [isFormatCorrect, setFormat] = useState(true);
    const [errorMessage, setErrorMessage] = useState("Incorrect format");
    const [isSnackOpen, setSnackOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");

    function addImageProcess(src: string) {
        return new Promise<ImagePromType>((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve({ width: img.width, height: img.height });
            img.onerror = reject;
            img.src = src;
        });
    }
    const checkImageConfig = (logo: File) =>
        new Promise((resolve) => {
            const image = new Image();
            const URL = window.URL || window.webkitURL;
            const objectUrl = URL.createObjectURL(logo);
            image.src = objectUrl;
            addImageProcess(objectUrl)
                .then((res) => {
                    if (
                        logo.type !== "image/svg+xml" &&
                        logo.type !== "image/png" &&
                        logo.type !== "image/jpeg"
                    ) {
                        setErrorMessage("File format must be SVG, JPG or PNG");
                    } else if (logo.size < 50000 || logo.size > 5000000) {
                        setErrorMessage("File size must be 50KB min. - 5MB max.");
                    } else if (
                        isLogoType &&
                        res.width !== res.height &&
                        isLogoType &&
                        res.height !== res.width * 2
                    ) {
                        setErrorMessage("Aspect ratio must be 1:1 square or 2:1 rectangular");
                    } else if (!isLogoType && res.width !== res.height) {
                        setErrorMessage("Aspect ratio must be 1:1 square");
                    } else if (res.width < 500 || res.width > 2000)
                        setErrorMessage("Dimension must be 500px min. - 2,000px max.");
                    if (
                        logo.type === "image/svg+xml" &&
                        logo.size >= 50000 &&
                        logo.size < 5000000 &&
                        (isLogoType
                            ? res.width === res.height || res.height === res.width * 2
                            : res.width === res.height)
                    ) {
                        resolve(true);
                    }
                    if (
                        (logo.type === "image/png" ||
                            logo.type === "image/jpeg" ||
                            logo.type === "image/svg+xml") &&
                        logo.size >= 50000 &&
                        logo.size <= 5000000 &&
                        (isLogoType
                            ? res.width === res.height || res.height === res.width * 2
                            : res.width === res.height) &&
                        res.width >= 500 &&
                        res.width <= 2000
                    ) {
                        resolve(true);
                    }
                    setFormat(false);
                    resolve(false);
                })
                .catch(() => {
                    setFormat(false);
                    setErrorMessage("File format must be SVG, JPG or PNG");
                });
        });

    const uploadFile = (
        fileToSend:
            | string
            | Blob
            | Document
            | ArrayBufferView
            | ArrayBuffer
            | FormData
            | null
            | undefined,
    ) => {
        const config: AxiosRequestConfig = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };
        axios
            .post(
                `${process.env.REACT_APP_FILE_SERVICE_URL}upload`,
                { file: fileToSend, service: "onboarding-service" },
                config,
            )
            .then((res) => {
                setImageURL(res.data.data.url, imageName);
            })
            .catch((err) => {
                setSnackMessage(err.message);
                setSnackOpen(true);
            });
    };

    useEffect(() => {
        if (logoImage) {
            checkImageConfig(logoImage)
                .then((result) => {
                    if (logoImage && result) {
                        setFormat(true);
                        uploadFile(logoImage);
                    }
                })
                .catch((err) => console.error(err));
        }
    }, [logoImage]);
    const handleLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.target.files && e.target.files.length) {
            setLogoImage(e.target.files[0]);
            setImageURL(imageURL, trimName(e.target.files[0].name));
            e.target.value = "";
        }
    };
    const handleLogoDelete = () => {
        setLogoImage(null);
        setImageURL("", "");
    };
    const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleImageDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer.files && e.dataTransfer.files.length) {
            setLogoImage(e.dataTransfer.files[0]);
            setImageURL(imageURL, trimName(e.dataTransfer.files[0].name));
        }
    };
    return (
        <>
            <Box
                onDrop={handleImageDrop}
                onDragEnter={handleDrag}
                onDragOver={handleDrag}
                sx={{
                    width: "100%",
                    maxWidth: "545px",
                    backgroundColor: ({ palette }) => palette.info.main,
                    padding: "13px 0",
                    borderRadius: "4px",
                    textAlign: "center",
                    fontWeight: 400,
                    fontSize: "14px",
                    color: ({ palette }) => palette.info.dark,
                    position: "relative",
                }}
            >
                <Tooltip
                    title={
                        isLogoType ? (
                            "File format must be SVG, JPG or PNG Dimension: 500 x 500px - 2,000 x 2,000px Aspect ratio: 1:1 square or 2:1 rectangular Size: 50KB min. - 5MB max."
                        ) : (
                            <>
                                <div>
                                    File format must be SVG, JPG or PNG Dimension: 500 x 500px -
                                    2,000 x 2,000px Aspect ratio: 1:1 square
                                </div>
                                <div>Size: 50KB min. - 5MB max.</div>
                            </>
                        )
                    }
                    placement="right-start"
                >
                    <SvgIcon
                        component={Info}
                        inheritViewBox
                        sx={{
                            fontSize: "16px",
                            position: "absolute",
                            left: "101%",
                            top: 0,
                        }}
                    />
                </Tooltip>
                {imageURL ? (
                    <Stack direction="row" p="0 36px" justifyContent="space-between">
                        <Stack direction="row" spacing={1} alignItems="flex-start">
                            <Box
                                component="img"
                                sx={{
                                    maxHeight: "100px",
                                    maxWidth: "100px",
                                }}
                                alt="Logo/Icon image"
                                src={imageURL}
                            />
                            <SvgIcon
                                inheritViewBox
                                component={close}
                                data-testid="delete-button"
                                sx={{
                                    width: "12px",
                                    height: "12px",
                                    color: ({ palette }) => palette.info.main,
                                    position: "relative",
                                    left: "-10px",
                                    bottom: "10px",
                                    "&:hover": {
                                        cursor: "pointer",
                                    },
                                }}
                                onClick={handleLogoDelete}
                            />
                        </Stack>
                        <Stack
                            direction="row"
                            spacing={1}
                            sx={{
                                fontWeight: 600,
                                fontSize: "14px",
                                color: ({ palette }) => palette.success.main,
                            }}
                        >
                            <Typography>Uploaded</Typography>
                            <CheckRoundedIcon inheritViewBox />
                        </Stack>
                    </Stack>
                ) : (
                    <>
                        <SvgIcon
                            component={download}
                            inheritViewBox
                            sx={{ color: ({ palette }) => palette.info.main, height: "14px" }}
                        />
                        <label htmlFor={id}>
                            Drag file here to upload document or{" "}
                            <span style={{ color: `${theme.palette.info.contrastText}` }}>
                                choose file
                            </span>
                        </label>
                    </>
                )}
                <input
                    type="file"
                    name={name}
                    id={id}
                    data-testid="input"
                    onBlur={handleBlur}
                    onInput={handleLogoChange}
                    style={{ display: "none" }}
                />
            </Box>
            {isFormatCorrect ? (
                <Typography style={{ width: "19px", height: "40px" }} />
            ) : (
                <Typography variant="body2" sx={{ color: "red", height: "40px" }}>
                    <ErrorRoundedIcon
                        fontSize="small"
                        color="warning"
                        sx={{ position: "relative", top: "6px", mr: "5px" }}
                    />
                    {errorMessage}
                </Typography>
            )}
            <SnackBar
                isModalOpen={isSnackOpen}
                setModalOpen={setSnackOpen}
                modalMessage={snackMessage}
                modalType="error"
            />
        </>
    );
}
