import React from "react";

import { FormFrame } from "Components/FormFrame";
import { StepperFrame } from "Components/StepperFrame";

import { DevConsoleForm } from "./Components/DevConsoleForm";

export function DevConsolePage() {
    return (
        <FormFrame>
            <StepperFrame step={5}>
                <DevConsoleForm />
            </StepperFrame>
        </FormFrame>
    );
}
