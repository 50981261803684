import React, { useState } from "react";

import { Drawer, Stack } from "@mui/material";
import { DashboardHeader, IInsightStatuses } from "Pages/Dashboard/Components/DashboardHeader";
import { DashboardSideBar } from "Pages/Dashboard/Components/DashboardSideBar";

import { ContentContainer, FrameContainer, SideMenuContainer } from "./FormFrame";

interface IFrameProps {
    children: JSX.Element;
    // eslint-disable-next-line react/require-default-props
    notificationsCount: number;
    statuses: IInsightStatuses | undefined;
}

export function Frame({ children, notificationsCount, statuses }: IFrameProps) {
    const [open, setOpen] = useState(false);
    return (
        <FrameContainer>
            <Stack width="100%" minHeight="100vh" direction="row">
                <Drawer open={open} onClose={() => setOpen(false)} variant="temporary">
                    <DashboardSideBar />
                </Drawer>
                <SideMenuContainer>
                    <DashboardSideBar />
                </SideMenuContainer>
                <ContentContainer paddingLeft="270px">
                    <DashboardHeader
                        onOpenSidebar={() => setOpen(true)}
                        notificationsCount={notificationsCount}
                        statuses={statuses}
                    />
                    {children}
                </ContentContainer>
            </Stack>
        </FrameContainer>
    );
}
