import React from "react";

import { SectionHeader } from "../SectionHeader";
import { SimpleVerticalStepper } from "../SimpleVerticalStepper";
import { useData } from "../SubmissionPage";
import { Container } from "./API";
import { KYCContent } from "./KYCContent";

export function KYC() {
    const data = useData()?.data;
    return (
        <>
            <SimpleVerticalStepper step={3} />
            <Container>
                <SectionHeader
                    name="KYC Information"
                    needPercentage
                    needStatus={false}
                    percentage={Math.round(data?.documentStats?.approvedPercentage) || ""}
                />
                <KYCContent />
            </Container>
        </>
    );
}
