
export default function Checkbox() {
    return {
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    padding: 0,
                    "&:hover": {
                        backgroundColor: "transparent",
                    },
                },
            },
        },
    };
}