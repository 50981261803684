import React from "react";

import { Button } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

type StyledButtonProps = {
    text: string;
    width: string;
    isDisabled: boolean;
    onHandleClick: () => void;
    autoFocus?: boolean;
};

export function StyledButton({
    text,
    width,
    isDisabled,
    onHandleClick,
    autoFocus,
}: StyledButtonProps) {
    const theme = useTheme();
    const MyStyledButton = styled(Button)({
        width: `${width}`,
        backgroundColor: `${theme.palette.primary.main}`,
        color: `${theme.palette.primary.light}`,
        "&:hover": {
            backgroundColor: "#019485",
        },
    });
    return (
        <MyStyledButton
            disabled={isDisabled}
            onMouseDown={(e) => {
                if (e.button === 0) {
                    onHandleClick();
                }
            }}
            autoFocus={autoFocus}
        >
            {text}
        </MyStyledButton>
    );
}

StyledButton.defaultProps = {
    autoFocus: false,
};
