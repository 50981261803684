import React from "react";

import Page404 from "Components/404Page";
import { APIDocumentationPage } from "Pages/APIDocumentationPage/APIDocumentationPage";
import { ApprovalPage } from "Pages/ApprovalPage/ApprovalPage";
import { AuthorizationPage } from "Pages/Authorization/AuthorizationPage";
import useAuthService from "Pages/Authorization/useAuthService";
import { BusinessInfoPage } from "Pages/BusinessInfoPage/BusinessInfoPage";
import { CommonPage } from "Pages/CommonPage/CommonPage";
import { ContactUsPage } from "Pages/ContactUsPage/ContactUsPage";
import { DevConsolePage } from "Pages/DevConsolePage/DevConsolePage";
import { HomePage } from "Pages/HomePage/HomePage";
import { Certification } from "Pages/KYCInfoPage/Components/Certification";
import { Identity } from "Pages/KYCInfoPage/Components/Identity";
import { License } from "Pages/KYCInfoPage/Components/License";
import { OtherDocuments } from "Pages/KYCInfoPage/Components/OtherDocuments";
import { Policy } from "Pages/KYCInfoPage/Components/Policy";
import { PersonalizationPage } from "Pages/Personalization/PersonalizationPage";
import { RegistrationPage } from "Pages/RegistrationPage/RegistrationPage";
import { ResetPasswordPage } from "Pages/ResetPasswordPage/ResetPasswordPage";
import { API } from "Pages/Submissions/Components/API";
import { Approval } from "Pages/Submissions/Components/Approval";
import { BusinessInfo } from "Pages/Submissions/Components/BusinessInfo";
import { DevConsole } from "Pages/Submissions/Components/DevConsole";
import { KYC } from "Pages/Submissions/Components/KYC";
import { Personalization } from "Pages/Submissions/Components/Personalization";
import { VerificationFrame } from "Pages/VerificationPage/VerificationFrame";
import { VerifyPage } from "Pages/VerifyPage/VerifyPage";
import { VerifyPasswordChange } from "Pages/VerifyPasswordChange/VerifyPasswordChange";
import { Navigate, Route, Routes } from "react-router-dom";
import { routesPaths } from "routesPaths";

type ProtectedRoutePropType = {
    element: JSX.Element;
};

function ProtectedRoute({ element }: ProtectedRoutePropType): JSX.Element {
    const { isAuthenticated } = useAuthService();
    if (!isAuthenticated) {
        return <Navigate to={routesPaths.authorization} replace />;
    }
    return element;
}

function GuestRoute({ element }: ProtectedRoutePropType): JSX.Element {
    const { isAuthenticated } = useAuthService();
    if (isAuthenticated) {
        return <Navigate to={routesPaths.business_info} replace />;
    }
    return element;
}

export function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Navigate to={routesPaths.index} replace />} />
            <Route path={routesPaths.index} element={<GuestRoute element={<HomePage />} />} />
            <Route
                path={routesPaths.contacts}
                element={<GuestRoute element={<ContactUsPage />} />}
            />
            <Route
                path={routesPaths.business_info}
                element={<ProtectedRoute element={<BusinessInfoPage />} />}
            />
            <Route
                path={routesPaths.personalization}
                element={<ProtectedRoute element={<PersonalizationPage />} />}
            />
            <Route
                path={routesPaths.certification}
                element={<ProtectedRoute element={<Certification />} />}
            />
            <Route
                path={routesPaths.identity}
                element={<ProtectedRoute element={<Identity />} />}
            />
            <Route path={routesPaths.license} element={<ProtectedRoute element={<License />} />} />
            <Route path={routesPaths.policy} element={<ProtectedRoute element={<Policy />} />} />
            <Route
                path={routesPaths.other}
                element={<ProtectedRoute element={<OtherDocuments />} />}
            />
            <Route
                path={routesPaths.api}
                element={<ProtectedRoute element={<APIDocumentationPage />} />}
            />
            <Route
                path={routesPaths.dev_console}
                element={<ProtectedRoute element={<DevConsolePage />} />}
            />
            <Route
                path={routesPaths.approval}
                element={<ProtectedRoute element={<ApprovalPage />} />}
            />
            <Route
                path={routesPaths.registration}
                element={<GuestRoute element={<RegistrationPage />} />}
            />
            <Route
                path={routesPaths.authorization}
                element={<GuestRoute element={<AuthorizationPage />} />}
            />
            <Route
                path={routesPaths.reg_verification}
                element={<GuestRoute element={<VerificationFrame type="reg" />} />}
            />
            <Route
                path={routesPaths.reset_verification}
                element={<GuestRoute element={<VerificationFrame type="reset" />} />}
            />
            <Route
                path={routesPaths.verify_page}
                element={<GuestRoute element={<VerifyPage />} />}
            />
            <Route
                path={routesPaths.reset_page}
                element={<GuestRoute element={<VerifyPasswordChange />} />}
            />
            <Route
                path={routesPaths.reset_password_page}
                element={<GuestRoute element={<ResetPasswordPage type="reset" />} />}
            />
            <Route
                path={routesPaths.new_password_page}
                element={<GuestRoute element={<ResetPasswordPage type="new" />} />}
            />
            <Route
                path={routesPaths.dashboard}
                element={<ProtectedRoute element={<CommonPage type="dashboard" />} />}
            />
            <Route
                path={routesPaths.submissions}
                element={<ProtectedRoute element={<CommonPage type="submissions" />} />}
            >
                <Route index element={<BusinessInfo />} />
                <Route path={routesPaths.personalization_review} element={<Personalization />} />
                <Route path={routesPaths.kyc_review} element={<KYC />} />
                <Route path={routesPaths.api_review} element={<API />} />
                <Route path={routesPaths.dev_console_review} element={<DevConsole />} />
                <Route path={routesPaths.approval_review} element={<Approval />} />
            </Route>
            <Route
                path={routesPaths.notifications}
                element={<ProtectedRoute element={<CommonPage type="notifications" />} />}
            />
            <Route path="*" element={<Page404 />} />
        </Routes>
    );
}
