import React, { useState } from "react";

import DoneIcon from "@mui/icons-material/Done";
import { Box, Button, Stack, styled, Typography, useTheme } from "@mui/material";
import { Loader } from "Components/Loader";
import { Modal } from "Components/Modal";
import { SnackBar } from "Components/SnackBar";
import { StyledButton } from "Components/StyledButton";
import { useNavigate } from "react-router-dom";
import { routesPaths } from "routesPaths";

import { DetailsPropsType } from "../ApprovalPage.model";
import { submitAll } from "../ApprovalPage.service";
import { APISection } from "./APISection";
import { BusinessInfoSection } from "./BusinessInfoSection";
import { DevConsoleSection } from "./DevConsoleSection";
import { KYCInfoSection } from "./KYCInfoSection";
import { PersonalizationSection } from "./PersonalizationSection";
import { SectionHeader } from "./SectionHeader";

const HiddenContainer = styled(Stack)(({ theme }) => ({
    maxHeight: "1000px",
    overflowY: "scroll",
    overflowX: "hidden",
    gap: "130px",
    marginRight: "-270px",
    [theme.breakpoints.down("md")]: {
        marginRight: "-200px",
    },
    [theme.breakpoints.down("sm")]: {
        marginRight: "-100px",
    },
}));

export function Details({
    setIsSubmitted,
    statuses,
    submissionData,
    percentage,
}: DetailsPropsType) {
    const theme = useTheme();
    const navigate = useNavigate();
    const [isModalOpen, setModalOpen] = useState(false);
    const [isLoaderOpen, setLoaderOpen] = useState(false);
    const [isSnackOpen, setSnackOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");

    const handleClose = () => {
        setModalOpen(false);
        navigate(routesPaths.dashboard);
    };

    const onSubmitForm = () => {
        window.scrollTo(0, 0);
        setLoaderOpen(true);
        submitAll()
            .then(() => {
                setIsSubmitted(true);
                setModalOpen(true);
            })
            .catch((err) => {
                setSnackMessage(err.message);
                setSnackOpen(true);
            })
            .finally(() => setLoaderOpen(false));
    };
    const onBack = () => {
        navigate(routesPaths.dev_console);
    };
    const modalConfig = {
        header: "Successful",
        icon: DoneIcon,
        content: "Your bank information has been successfully recorded",
        buttonText: "Proceed to Dashboard",
        color: theme.palette.primary.main,
    };
    return (
        <Stack>
            <Stack
                sx={{
                    bgcolor: ({ palette }) => palette.primary.light,
                    p: "24px 40px 40px 40px",
                    maxWidth: "870px",
                    boxSizing: "border-box",
                    overflow: "hidden",
                    border: "1px solid",
                    borderRadius: "8px",
                    borderColor: ({ palette }) => palette.warning.contrastText,
                }}
            >
                <Stack direction="row" justifyContent="space-between">
                    <Typography
                        sx={{
                            fontWeight: 700,
                            fontSize: "24px",
                        }}
                    >
                        Review Information
                    </Typography>
                    <StyledButton
                        text="Edit records"
                        width="147px"
                        isDisabled={false}
                        onHandleClick={() => navigate(routesPaths.business_info)}
                    />
                </Stack>
                <Box
                    sx={{
                        width: "95%",
                        height: "1.5px",
                        margin: "auto",
                        m: "37px 0",
                        bgcolor: ({ palette }) => palette.info.main,
                    }}
                />
                <HiddenContainer>
                    <Stack spacing={5} width="75%">
                        <SectionHeader
                            name="Business Information"
                            isCompleted={statuses?.profile === "SUBMITTED"}
                        />
                        <BusinessInfoSection data={submissionData?.profile} />
                    </Stack>
                    <Stack width="75%">
                        <SectionHeader
                            name="Personalisation "
                            isCompleted={statuses?.personalization === "SUBMITTED"}
                        />
                        <PersonalizationSection data={submissionData?.personalization} />
                    </Stack>
                    <KYCInfoSection data={submissionData} statuses={statuses} />
                    <Stack width="75%">
                        <SectionHeader
                            name="API Documentation"
                            isCompleted={statuses?.settlements === "SUBMITTED"}
                        />
                        <APISection data={submissionData?.settlements} />
                    </Stack>
                    <Stack width="75%">
                        <SectionHeader
                            name="Interswitch Developer Console"
                            isCompleted={statuses?.iswPortal === "SUBMITTED"}
                        />
                        <DevConsoleSection data={submissionData?.iswPortal} />
                    </Stack>
                </HiddenContainer>
            </Stack>
            <Stack direction="row" sx={{ gap: "35px", mt: "88px" }}>
                <Button variant="outlined" onClick={onBack}>
                    Back
                </Button>
                <StyledButton
                    text="Submit"
                    width="197px"
                    isDisabled={!(percentage === 100)}
                    onHandleClick={() => onSubmitForm()}
                />
            </Stack>
            <Modal isModalOpen={isModalOpen} handleClose={handleClose} modalConfig={modalConfig} />
            <Loader isLoaderOpen={isLoaderOpen} />
            <SnackBar
                isModalOpen={isSnackOpen}
                setModalOpen={setSnackOpen}
                modalMessage={snackMessage}
                modalType="error"
            />
        </Stack>
    );
}
