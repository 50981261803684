import React, { useEffect, useState } from "react";

import { Drawer, Stack } from "@mui/material";
import { Footer } from "Components/Footer";
import { Header } from "Components/Header";
import { useLocation } from "react-router-dom";

import { SideBar } from "./SideBar";
import { Stepper } from "./Stepper";

type StepperFramePropTypes = {
    children: JSX.Element;
    step: number;
};

export function StepperFrame({ children, step }: StepperFramePropTypes) {
    const { pathname } = useLocation();
    const [open, setOpen] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (
        <Stack sx={{ height: "100vh" }}>
            <Header onOpenSidebar={() => setOpen(true)} />
            <Drawer
                open={open}
                onClose={() => setOpen(false)}
                variant="temporary"
                sx={{ "& .MuiPaper-root": { backgroundColor: "primary.dark" } }}
            >
                <SideBar />
            </Drawer>
            <Stepper step={step} />
            {children}
            <Footer />
        </Stack>
    );
}
