import React from "react";

import { AppRoutes } from "AppRoutes";
import { BrowserRouter } from "react-router-dom";

export function App() {
    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <AppRoutes />
        </BrowserRouter>
    );
}

export default App;
