import React from "react";

import { CircularProgress, Dialog, Stack } from "@mui/material";

type LoaderPropTypes = {
    isLoaderOpen: boolean;
};

export function Loader({ isLoaderOpen }: LoaderPropTypes) {
    return (
        <Dialog
            open={isLoaderOpen}
            sx={{
                "& .MuiPaper-root": {
                    backgroundColor: "transparent",
                    boxShadow: "none",
                },
            }}
        >
            <Stack
                width="250px"
                height="250px"
                sx={{ alignItems: "center", justifyContent: "center" }}
            >
                <CircularProgress color="secondary" size="150px" />
            </Stack>
        </Dialog>
    );
}
