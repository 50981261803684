import React from "react";

import { Box, Stack, styled, Typography } from "@mui/material";
import { routesPaths } from "routesPaths";

import { SectionFooter } from "../SectionFooter";
import { SectionHeader } from "../SectionHeader";
import { SimpleVerticalStepper } from "../SimpleVerticalStepper";
import { useData } from "../SubmissionPage";
import { APIContent } from "./APIContent";

export const Container = styled(Stack)(({ theme }) => ({
    width: "75%",
    backgroundColor: theme.palette.common.white,
    padding: "40px",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05)",
    borderRadius: "7px",
    border: "1px solid",
    borderColor: theme.palette.warning.contrastText,
    [theme.breakpoints.down("lg")]: {
        width: "100%",
        margin: "auto",
    },
}));

export function API() {
    const data = useData()?.data?.settlements;
    return (
        <>
            <SimpleVerticalStepper step={4} />
            <Container>
                <SectionHeader name="API Documentation" needPercentage={false} needStatus={false} />
                {data ? (
                    <APIContent data={data} />
                ) : (
                    <Typography variant="body1" color="warning.main" fontStyle="italic">
                        No information to display. Please fill out the appropriate form
                    </Typography>
                )}
                <Box
                    sx={{
                        width: "100%",
                        height: "1px",
                        margin: "auto",
                        m: "37px 0",
                        bgcolor: ({ palette }) => palette.success.light,
                    }}
                />
                <SectionFooter
                    status={data?.reviewStatus}
                    message={data?.message}
                    editURL={routesPaths.api}
                />
            </Container>
        </>
    );
}
