import React, { ElementType } from "react";

import { Box, Dialog, Stack, styled, SvgIcon, Typography } from "@mui/material";
import { StyledButton } from "Components/StyledButton";

type ModalPropsType = {
    isModalOpen: boolean;
    handleClose: () => void;
    modalConfig: {
        header: string;
        icon: ElementType<any>;
        content: string;
        buttonText: string;
        color: string;
    };
};

const ModalContainer = styled(Stack)(({ theme }) => ({
    maxWidth: "380px",
    maxHeight: "418px",
    padding: "40px 50px",
    gap: "30px",
    alignItems: "center",
    boxSizing: "border-box",
    margin: "auto",
    [theme.breakpoints.down("md")]: {
        margin: "auto",
    },
    [theme.breakpoints.down("sm")]: {
        padding: "20px 20px",
    },
}));

export function Modal({ isModalOpen, handleClose, modalConfig }: ModalPropsType) {
    return (
        <Dialog open={isModalOpen}>
            <ModalContainer>
                <Typography variant="h3">{modalConfig.header}</Typography>
                <Box
                    width="110px"
                    height="110px"
                    sx={{
                        backgroundColor: ({ palette }) => palette.info.main,
                        borderRadius: "50%",
                    }}
                >
                    <SvgIcon
                        component={modalConfig.icon}
                        inheritViewBox
                        sx={{
                            mt: "30px",
                            ml: "34px",
                            fontSize: "46px",
                            color: modalConfig.color,
                        }}
                    />
                </Box>
                <Typography maxWidth="300px" variant="body1" align="center">
                    {modalConfig.content}
                </Typography>
                <StyledButton
                    text={modalConfig.buttonText}
                    width="min(100%, 210px)"
                    isDisabled={false}
                    onHandleClick={handleClose}
                />
            </ModalContainer>
        </Dialog>
    );
}
