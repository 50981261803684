import * as yup from "yup";

export const validationSchema = yup.object({
    shortName: yup
        .string()
        .trim()
        .max(15, "Name must not exceed 15 characters")
        .matches(/^[a-zA-Z,.' -]+$/, "Name contains invalid characters. Letters and ,.'- allowed")
        .required("This field is required"),
});
