import React from "react";

import { Stack, styled, Typography } from "@mui/material";
import { ISubmissionData } from "Pages/ApprovalPage/ApprovalPage.model";
import { Outlet, useOutletContext } from "react-router-dom";

const Container = styled(Stack)(() => ({
    padding: "25px",
    width: "100%",
    height: "100%",
    gap: "25px",
    marginBottom: "50px",
}));

const SectionContainer = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    gap: "30px",
    alignItems: "flex-start",
    [theme.breakpoints.down("lg")]: {
        flexDirection: "column",
    },
}));

export function SubmissionPage({ data }: { data: ISubmissionData | null }) {
    return (
        <Container>
            <Typography variant="subtitle1" color="action.focus" marginBottom="12px">
                Submissions
            </Typography>
            <SectionContainer>
                <Outlet context={{ data }} />
            </SectionContainer>
        </Container>
    );
}

export function useData() {
    return useOutletContext<any>();
}
