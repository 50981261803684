import React from "react";

import { Typography } from "@mui/material";

export function FileItem({ name, status }: { name: string; status: string }) {
    const reviewColor = status === "APPROVED" ? "green" : "warning.main";
    return name ? (
        <Typography
            sx={{
                fontWeight: 600,
                fontSize: "16px",
                color:
                    status === "APPROVED" || status === "UPDATE_REQUESTED"
                        ? reviewColor
                        : ({ palette }) => palette.secondary.darker,
            }}
        >
            {name}
        </Typography>
    ) : null;
}
