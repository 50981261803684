import React from "react";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Box, Stack, SvgIcon, Typography } from "@mui/material";

export function ButtonFail() {
    return (
        <Stack direction="row" spacing={0.5} alignItems="center">
            <Box
                sx={{
                    width: "12px",
                    height: "12px",
                    bgcolor: ({ palette }) => palette.warning.main,
                    borderRadius: "50%",
                }}
            >
                <SvgIcon
                    component={CloseRoundedIcon}
                    inheritViewBox
                    sx={{
                        color: ({ palette }) => palette.primary.light,
                        fill: ({ palette }) => palette.primary.light,
                        fontSize: "8px",
                        position: "relative",
                        top: "-8px",
                        left: "2px",
                    }}
                />
            </Box>
            <Typography
                sx={{
                    fontWeight: 400,
                    fontSize: "12px",
                    color: ({ palette }) => palette.warning.main,
                }}
            >
                Incomplete
            </Typography>
        </Stack>
    );
}
