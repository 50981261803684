import React from "react";

import { Box, Button, Stack, styled, SvgIcon, Typography } from "@mui/material";
import logoMock from "Assets/Images/bankLogo.png";
import { ReactComponent as call } from "Assets/Images/call.svg";
import display from "Assets/Images/display.svg";
import display_2 from "Assets/Images/display_2.svg";
import { ReactComponent as ellipsis } from "Assets/Images/ellipsis.svg";
import { ReactComponent as hide } from "Assets/Images/hide.svg";
import { ReactComponent as home } from "Assets/Images/home.svg";
import { ReactComponent as paper } from "Assets/Images/paper.svg";
import phone from "Assets/Images/phone_new.png";
import { ReactComponent as send } from "Assets/Images/send.svg";
import { ReactComponent as time } from "Assets/Images/time.svg";
import { ReactComponent as wallet } from "Assets/Images/wallet.svg";

export const buttonsConfig = [
    {
        id: 1,
        component: send,
        text: "Transfer",
    },
    {
        id: 2,
        component: call,
        text: "Airtime",
    },
    {
        id: 3,
        component: paper,
        text: "Bills",
    },
    {
        id: 4,
        component: wallet,
        text: "Loan",
    },
];

export const navListConfig = [
    {
        id: 1,
        component: home,
        text: "Home",
    },
    {
        id: 2,
        component: time,
        text: "History",
    },
    {
        id: 3,
        component: ellipsis,
        text: "More",
    },
];

type PreviewPropsType = {
    primaryColor: string;
    secondaryColor: string;
    preview: number;
    logo: string;
    icon: string;
};

const PreviewContainer = styled(Stack)(({ theme }) => ({
    width: "385px",
    height: "640px",
    backgroundCcolor: theme.palette.info.main,
    color: "white",
    backgroundImage: `url(${phone})`,
}));

export function Preview({ primaryColor, secondaryColor, preview, logo, icon }: PreviewPropsType) {
    const image = icon || logo || logoMock;
    return preview === 1 ? (
        <PreviewContainer>
            <Box
                id="display"
                sx={{
                    width: "69%",
                    height: "87%",
                    backgroundImage: `url(${display})`,
                    backgroundSize: "cover",
                    position: "relative",
                    left: "54px",
                    top: "14px",
                }}
            >
                <Stack sx={{ position: "relative", top: "40px", left: "15px", width: "235px" }}>
                    <Stack
                        direction="row"
                        sx={{ alignItems: "center", justifyContent: "space-between", mb: "15px" }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: "11px",
                                color: ({ palette }) => palette.secondary.light,
                            }}
                        >
                            Hi Natasha
                        </Typography>
                        <Box
                            sx={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "50%",
                                backgroundImage: `url(${image})`,
                                backgroundSize: "contain",
                            }}
                        />
                    </Stack>
                    <Stack
                        sx={{
                            bgcolor: ({ palette }) => palette.primary.dark,
                            alignItems: "center",
                            borderRadius: "3.5px",
                            p: "17px",
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 600,
                                fontSize: "8px",
                                color: primaryColor || (({ palette }) => palette.primary.light),
                            }}
                        >
                            Acc No: 0123456789
                        </Typography>
                        <Stack direction="row" sx={{ alignItems: "baseline", gap: "5px" }}>
                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    fontSize: "16px",
                                    color: ({ palette }) => palette.primary.light,
                                }}
                            >
                                **************
                            </Typography>
                            <SvgIcon
                                component={hide}
                                inheritViewBox
                                sx={{
                                    color:
                                        primaryColor ||
                                        (({ palette }) => palette.primary.contrastText),
                                    fontSize: "14px",
                                }}
                            />
                        </Stack>
                        <Typography
                            sx={{
                                fontWeight: 600,
                                fontSize: "7px",
                                color: ({ palette }) => palette.primary.light,
                            }}
                        >
                            Savings Account
                        </Typography>
                    </Stack>
                    <Stack
                        direction="row"
                        sx={{ gap: "27px", p: "50px 0 30px" }}
                        data-testid="buttons"
                    >
                        {buttonsConfig.map(({ id, component, text }) => (
                            <Stack sx={{ alignItems: "center" }} key={id}>
                                <Box
                                    sx={{
                                        width: "38px",
                                        height: "38px",
                                        bgcolor:
                                            primaryColor ||
                                            (({ palette }) => palette.primary.contrastText),
                                        borderRadius: "50%",
                                    }}
                                >
                                    <SvgIcon
                                        component={component}
                                        inheritViewBox
                                        data-testid="buttonLogo"
                                        sx={{
                                            color: ({ palette }) => palette.primary.light,
                                            fontSize: "17px",
                                            m: "10px 0 0 10px",
                                        }}
                                    />
                                </Box>
                                <Typography
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: "10px",
                                        color: ({ palette }) => palette.info.contrastText,
                                    }}
                                >
                                    {text}
                                </Typography>
                            </Stack>
                        ))}
                    </Stack>
                    <Typography
                        variant="subtitle2"
                        sx={{ color: ({ palette }) => palette.primary.dark }}
                    >
                        Transaction History
                    </Typography>
                    <Stack
                        direction="row"
                        sx={{
                            width: "255px",
                            p: "5px 0px 15px",
                            mt: "175px",
                            ml: "-10px",
                            justifyContent: "space-around",
                            borderTop: "solid 0.7px",
                            borderColor: ({ palette }) => palette.warning.contrastText,
                        }}
                        data-testid="nav"
                    >
                        {navListConfig.map(({ id, component, text }) => (
                            <Stack sx={{ alignItems: "center" }} key={id}>
                                <Box sx={{ width: "30px", height: "25px" }}>
                                    <SvgIcon
                                        component={component}
                                        inheritViewBox
                                        data-testid="navLogo"
                                        sx={{
                                            color:
                                                id === 1
                                                    ? primaryColor ||
                                                      (({ palette }) =>
                                                          palette.primary.contrastText)
                                                    : ({ palette }) => palette.info.dark,
                                            fontSize: "12px",
                                            ml: "10px",
                                        }}
                                    />
                                </Box>
                                <Typography
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: "10px",
                                        color:
                                            id === 1
                                                ? primaryColor ||
                                                  (({ palette }) => palette.primary.contrastText)
                                                : ({ palette }) => palette.info.dark,
                                    }}
                                >
                                    {text}
                                </Typography>
                            </Stack>
                        ))}
                    </Stack>
                </Stack>
            </Box>
        </PreviewContainer>
    ) : (
        <Stack
            sx={{
                width: "385px",
                height: "640px",
                bgcolor: ({ palette }) => palette.info.main,
                color: "white",
                backgroundImage: `url(${phone})`,
            }}
        >
            <Box
                id="display"
                sx={{
                    width: "67%",
                    height: "87%",
                    backgroundImage: `url(${display_2})`,
                    backgroundSize: "cover",
                    position: "relative",
                    left: "57px",
                    top: "14px",
                }}
            >
                <Stack
                    sx={{
                        position: "relative",
                        top: "70px",
                        left: "11px",
                        width: "235px",
                        mt: "15px",
                    }}
                >
                    <Box
                        sx={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            backgroundImage: `url(${image})`,
                            backgroundSize: "contain",
                            alignSelf: "flex-end",
                        }}
                    />
                    <Typography
                        mt="15px"
                        sx={{
                            color: secondaryColor || (({ palette }) => palette.primary.main),
                            fontWeight: 700,
                            fontSize: "16px",
                        }}
                    >
                        Welcome Back,
                    </Typography>
                    <Button
                        sx={{
                            width: "234px",
                            height: "30px",
                            backgroundColor: primaryColor,
                            mt: "146px",
                            fontWeight: 500,
                            fontSize: "9px",
                            borderRadius: "4px",
                            "&:hover": {
                                backgroundColor: primaryColor,
                            },
                        }}
                    >
                        Login
                    </Button>
                </Stack>
            </Box>
        </Stack>
    );
}
