import React from "react";

import { Box, Stack, Typography } from "@mui/material";

import { ButtonDone } from "./ButtonDone";
import { ButtonFail } from "./ButtonFail";

type SectionHeaderPropsType = {
    name: string;
    isCompleted: boolean;
};

export function SectionHeader({ name, isCompleted }: SectionHeaderPropsType) {
    return (
        <>
            <Stack direction="row" spacing={2}>
                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: "18px",
                        color: ({ palette }) => palette.info.dark,
                    }}
                >
                    {name}
                </Typography>
                {isCompleted ? <ButtonDone /> : <ButtonFail />}
            </Stack>
            <Box
                sx={{
                    width: "100%",
                    height: "1px",
                    margin: "auto",
                    m: "37px 0",
                    bgcolor: ({ palette }) => palette.success.light,
                }}
            />
        </>
    );
}
