import React from "react";

import { Box, Dialog, Stack, Typography, useTheme } from "@mui/material";
import { StyledButton } from "Components/StyledButton";

export const APIs = [
    {
        id: 1,
        content: "An API to retrieve customer details",
    },
    {
        id: 2,
        content: "An API to retrieve customer accounts",
    },
    {
        id: 3,
        content: "An API to retrieve customer transactions",
    },
    {
        id: 4,
        content: "An API for local transfer name enquiry",
    },
    {
        id: 5,
        content: "An API for local transfer",
    },
    {
        id: 6,
        content: "An API for other bank name enquiry",
    },
    {
        id: 7,
        content: "An API for other bank transfer",
    },
    {
        id: 8,
        content: "An API for transfer reversal",
    },
];

type ModalPropsType = {
    isModalOpen: boolean;
    handleClose: () => void;
};

export function Modal({ isModalOpen, handleClose }: ModalPropsType) {
    const theme = useTheme();
    return (
        <Dialog open={isModalOpen}>
            <Stack
                sx={{
                    maxWidth: "420px",
                    padding: "30px 40px",
                    gap: "20px",
                    alignItems: "center",
                    boxSizing: "border-box",
                }}
            >
                <Box>
                    <Typography variant="subtitle1">Instructions</Typography>
                    <Typography
                        variant="body1"
                        sx={{ color: theme.palette.secondary.light, pt: "10px" }}
                    >
                        Please provide the following APIs:
                    </Typography>
                    <ul
                        style={{
                            color: theme.palette.secondary.light,
                            display: "flex",
                            flexDirection: "column",
                            gap: "20px",
                            paddingLeft: "20px",
                        }}
                    >
                        {APIs.map((elem) => (
                            <li key={elem.id}>
                                <Typography variant="body1">{elem.content}</Typography>
                            </li>
                        ))}
                    </ul>
                </Box>
                <StyledButton
                    text="Got it!"
                    width="70%"
                    isDisabled={false}
                    onHandleClick={handleClose}
                />
            </Stack>
        </Dialog>
    );
}
