import React from "react";

import DoneIcon from "@mui/icons-material/Done";
import { Box, Stack, styled, SvgIcon, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { steps } from "utils/constants";
import { getDecryptedFromStorage } from "utils/encryption";

type StepperPropTypes = {
    step: number;
};

const StepperContainer = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    borderTop: "1px solid",
    alignItems: "flex-start",
    borderColor: theme.palette.warning.contrastText,
    padding: "30px 20px",
    margin: "auto",
    gap: "20px",
    [theme.breakpoints.down("md")]: {
        display: "none",
    },
}));

export function Stepper({ step }: StepperPropTypes) {
    const theme = useTheme();
    const institution = getDecryptedFromStorage(localStorage, "institutionId");
    const color = institution ? theme.palette.secondary.main : theme.palette.action.disabled;
    return (
        <StepperContainer>
            {steps.map(({ id, header, content, url }) => (
                <Link
                    key={id}
                    to={institution || id === step ? url : "#"}
                    style={{
                        textDecoration: "none",
                        maxWidth: "190px",
                        cursor: institution || id === step ? "pointer" : "default",
                    }}
                >
                    <Stack direction="row" alignItems="center" spacing={2.5}>
                        <Box
                            sx={{
                                width: "26px",
                                height: "26px",
                                borderRadius: "50%",
                                border: "1px solid",
                                flexShrink: 0,
                                borderColor:
                                    institution || id === step
                                        ? ({ palette }) => palette.secondary.main
                                        : "transparent",
                                backgroundColor:
                                    // eslint-disable-next-line no-nested-ternary
                                    step === id ? "transparent" : color,
                            }}
                        >
                            {id < step && (
                                <SvgIcon
                                    component={DoneIcon}
                                    inheritViewBox
                                    sx={{
                                        mt: "2px",
                                        ml: "2px",
                                        fontSize: "20px",
                                        color: ({ palette }) => palette.primary.light,
                                    }}
                                />
                            )}
                            {id > step && (
                                <Typography
                                    sx={{
                                        mt: "-2px",
                                        ml: "6px",
                                        fontSize: "18px",
                                        color: ({ palette }) => palette.primary.light,
                                    }}
                                >
                                    {id}
                                </Typography>
                            )}
                        </Box>
                        {id !== steps.length && (
                            <Box
                                sx={{
                                    height: "1px",
                                    backgroundColor: ({ palette }) => palette.info.dark,
                                    maxWidth: "150px",
                                    width: "70%",
                                }}
                            />
                        )}
                    </Stack>
                    <Stack>
                        <Typography
                            sx={{
                                pt: "10px",
                                fontWeight: 600,
                                fontSize: "14px",
                                color:
                                    institution || id === step
                                        ? ({ palette }) => palette.secondary.main
                                        : ({ palette }) => palette.action.disabled,
                            }}
                        >
                            {header}
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: "12px",
                                color: ({ palette }) => palette.grey[500],
                            }}
                        >
                            {content}
                        </Typography>
                    </Stack>
                </Link>
            ))}
        </StepperContainer>
    );
}
