import * as yup from "yup";

export const validationSchema = yup.object({
    fullName: yup
        .string()
        .trim()
        .matches(/^[a-z ,.'-]+$/i, "You should use only letters and , - ' . characters")
        .min(2, "2 characters minimum")
        .max(100, "100 characters maximum")
        .required("This field is required"),
    email: yup
        .string()
        .trim()
        .matches(
            /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/,
            "Invalid email",
        )
        .min(6, "6 characters minimum")
        .max(254, "254 characters maximum")
        .required("This field is required"),
    phoneNumber: yup
        .string()
        .trim()
        .matches(
            /^\+234\d{10}$/,
            "Your phone number must match the specified format: +234xxxxxxxxxx",
        )
        .required("This field is required"),
    message: yup
        .string()
        .trim()
        .min(10, "10 characters minimum")
        .max(1000, "1000 characters maximum")
        .required("This field is required"),
});
