import React from "react";

import { ListItemText, Stack, Typography, useTheme } from "@mui/material";
import { NavLink } from "react-router-dom";

export interface IStep {
    step: {
        id: number;
        header: string;
        content: string;
        url: string;
    };
    isCollapseElem: boolean;
}

export function CustomLink({ step, isCollapseElem }: IStep) {
    const { id, url, header } = step;
    const theme = useTheme();

    return (
        <NavLink
            key={id}
            to={url}
            end
            style={({ isActive }) => ({
                width: "100%",
                flexDirection: "column",
                alignSelf: "flex-start",
                alignItems: "flex-start",
                color: isActive
                    ? theme.palette.secondary.main
                    : theme.palette.secondary.contrastText,
            })}
        >
            <Stack data-testid="list-item" direction="row" width="100%">
                <ListItemText
                    sx={{ justifyContent: isCollapseElem ? "flex-end" : "space-between" }}
                    primary={<Typography variant="h4">{header}</Typography>}
                />
            </Stack>
        </NavLink>
    );
}
