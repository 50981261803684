import React, { useRef } from "react";

import { Box, Stack, Typography, useTheme } from "@mui/material";
import { hexToHsva, hsvaToHex } from "@uiw/color-convert";
import { Wheel } from "@uiw/react-color";
import EditableInput from "@uiw/react-color-editable-input";
import ShadeSlider from "@uiw/react-color-shade-slider";
import { colors } from "utils/constants";

type PickerPropsType = {
    hex: string;
    setHex: (ag0: string) => void;
};

export function ColorPicker({ hex, setHex }: PickerPropsType) {
    const theme = useTheme();
    const inputRef = useRef(null);
    return (
        <Stack spacing={2}>
            <Wheel
                style={{}}
                color={hex}
                onChange={(color) => {
                    setHex(color.hex);
                    if (inputRef.current) (inputRef.current as HTMLElement).blur();
                }}
            />
            <ShadeSlider
                hsva={hexToHsva(hex)}
                onChange={(newShade) => {
                    if (inputRef.current) (inputRef.current as HTMLElement).blur();
                    setHex(hsvaToHex({ ...hexToHsva(hex), ...newShade }));
                }}
                style={{
                    borderRadius: "3px",
                    border: `solid 1px ${theme.palette.info.dark}`,
                }}
                width="189px"
                height="6px"
            />
            <Stack direction="row" spacing={1} justifyContent="center">
                <Typography>#</Typography>
                <EditableInput
                    value={hex.substring(1).toUpperCase()}
                    ref={inputRef}
                    inputStyle={{
                        height: "24px",
                        backgroundColor: theme.palette.info.main,
                        borderRadius: "3px",
                        width: "173px",
                        fontWeight: 400,
                        fontSize: "14px",
                        color: theme.palette.info.contrastText,
                    }}
                    onChange={(e) => {
                        if (e.target.value.length > 2)
                            setHex(hsvaToHex({ ...hexToHsva(e.target.value) }));
                        else setHex("#FFF");
                    }}
                />
            </Stack>
            <Stack direction="row" spacing={0.5} alignSelf="center">
                {colors.map(({ id, color }) => (
                    <Box
                        key={id}
                        onClick={() => setHex(color)}
                        style={{
                            width: "23px",
                            height: "23px",
                            backgroundColor: color,
                            borderRadius: "2px",
                        }}
                    />
                ))}
            </Stack>
        </Stack>
    );
}
