import * as yup from "yup";

export const validationSchema = yup.object({
    clientId: yup
        .string()
        .min(2, "Field must contain at least 2 characters")
        .max(100, "Field must not exceed 100 characters")
        .required("This field is required")
        .typeError("This field is required"),
    clientSecret: yup
        .string()
        .min(2, "Field must contain at least 2 characters")
        .max(100, "Field must not exceed 100 characters")
        .required("This field is required")
        .typeError("This field is required"),
});
