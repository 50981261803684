import React from "react";

import { Typography, useTheme } from "@mui/material";

interface LabelPropsType {
    label: string;
    isRequired: boolean;
}

export function CustomInputLabel({ label, isRequired }: LabelPropsType) {
    const theme = useTheme();
    return (
        <Typography variant="subtitle2" paddingBottom="5px">
            <>
                {label}
                {isRequired ? (
                    <sup style={{ color: `${theme.palette.primary.main}`, fontSize: "12px" }}>
                        {" "}
                        *{" "}
                    </sup>
                ) : null}
            </>
        </Typography>
    );
}
