export const initialState = {
    profile: {
        name: "",
        rcNo: "",
        addressLine1: "",
        addressLine2: "",
        state: "",
        zipCode: "",
        email: "",
        phoneNo: "",
        contactName: "",
        contactEmail: "",
        contactPhoneNo: "",
    },
    personalization: {
        shortName: "",
        facebook: "",
        instagram: "",
        twitter: "",
        linkedin: "",
        whatsapp: "",
        website: "",
        primaryColor: "",
        secondaryColor: "",
        logoURL: "",
        faqURL: "",
        iconURL: "",
        termsDocURL: "",
        iconName: "",
        logoName: "",
        faqName: "",
        termsDocName: "",
    },
    settlements: {
        collectionBank: "",
        collectionAccountNumber: "",
        liveAccount1: "",
        liveAccount2: "",
        liveAccount3: "",
        testAccount1: "",
        testAccount2: "",
        testAccount3: "",
        settlementLiveGLAcct: "",
        settlementTestGLAcct: "",
        settlementLetterURL: "",
        transferRequestDocURL: "",
        apiDocURL: "",
        settlementLetterName: "",
        transferRequestDocName: "",
        apiDocName: "",
    },
    certifications: {
        documents: [
            { type: "INCORPORATION", documentURL: "", documentName: "", reviewStatus: "" },
            { type: "DIRECTORS_PARTICULARS", documentURL: "", documentName: "", reviewStatus: "" },
            { type: "SHAREHOLDER_STRUCTURE", documentURL: "", documentName: "", reviewStatus: "" },
            { type: "AUTHORIZATION_LETTER", documentURL: "", documentName: "", reviewStatus: "" },
        ],
    },
    other_documents: {
        documents: [
            {
                type: "MEMORANDUM_OF_ASSOCIATION",
                documentURL: "",
                documentName: "",
                reviewStatus: "",
            },
            { type: "TAX_CLEARANCE", documentURL: "", documentName: "", reviewStatus: "" },
            { type: "SANCTIONS_SCREENING", documentURL: "", documentName: "", reviewStatus: "" },
            {
                type: "AML_TRAINING_CERTIFICATE",
                documentURL: "",
                documentName: "",
                reviewStatus: "",
            },
        ],
    },
    identity: {
        documents: [
            {
                type: "ULTIMATE_BENEFICAL_OWNER_ID",
                documentURL: "",
                documentName: "",
                reviewStatus: "",
            },
            {
                type: "ULTIMATE_BENEFICAL_OWNER_ID_ID_COPY",
                documentURL: "",
                documentName: "",
                reviewStatus: "",
            },
            {
                type: "OPERATING_ADDRESS_PROOF",
                documentURL: "",
                documentName: "",
                reviewStatus: "",
            },
        ],
    },
    license: {
        documents: [
            { type: "MFB_LICENSCE", documentURL: "", documentName: "", reviewStatus: "" },
            { type: "APPROVAL_LETTER", documentURL: "", documentName: "", reviewStatus: "" },
        ],
    },
    policy: {
        documents: [
            { type: "CORRUPTION_POLICY", documentURL: "", documentName: "", reviewStatus: "" },
            { type: "BUSINESS_ETHICS", documentURL: "", documentName: "", reviewStatus: "" },
            { type: "DATA_PRIVACY", documentURL: "", documentName: "", reviewStatus: "" },
            { type: "AML_POLICY", documentURL: "", documentName: "", reviewStatus: "" },
            { type: "KYC_POLICY", documentURL: "", documentName: "", reviewStatus: "" },
        ],
    },
    devConsole: {
        clientId: "",
        clientSecret: "",
    },
};
