import React from "react";

import { Box, Stack, styled, Typography } from "@mui/material";
import logoSmall from "Assets/Images/logoSmall.png";

const FooterContainer = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    width: "80%",
    justifyContent: "space-around",
    paddingBottom: "90px",
    paddingTop: "30px",
    alignItems: "center",
    borderTop: "solid 1px",
    borderColor: theme.palette.warning.contrastText,
    [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        gap: "20px",
    },
    [theme.breakpoints.down("sm")]: {},
}));

export function Footer() {
    return (
        <Stack sx={{ alignItems: "center" }}>
            <FooterContainer data-testid="footer">
                <Box
                    sx={{
                        width: "140px",
                        height: "49px",
                        backgroundImage: `url(${logoSmall})`,
                        backgroundSize: "contain",
                    }}
                    data-testid="logo"
                />
                <Typography variant="body2" textAlign="center" flexGrow={2}>
                    © 2002 - {new Date().getFullYear()} Interswitch Group
                </Typography>
            </FooterContainer>
        </Stack>
    );
}
