import React from "react";

import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import MovingRoundedIcon from "@mui/icons-material/MovingRounded";
import VolumeUpRoundedIcon from "@mui/icons-material/VolumeUpRounded";
import { Box, Icon, Stack, Typography } from "@mui/material";

const servicesListConfig = [
    {
        id: 1,
        title: "Increased Customer Base",
        text: "Recruit more customers with best-in-class mobile banking service that allows them to transact on the go.",
        icon: <MovingRoundedIcon sx={{ fontSize: "64px" }} />,
    },
    {
        id: 2,
        title: "Marketing",
        text: "We spread the word about your bank to users and non-users of the MyBanq, who are not yet your customers.",
        icon: <VolumeUpRoundedIcon sx={{ fontSize: "64px" }} />,
    },
    {
        id: 3,
        title: "Dedicated Support",
        text: "Get 24/7 dedicated support for you and your customers’service needs on the app.",
        icon: <LocalPhoneRoundedIcon sx={{ fontSize: "64px" }} />,
    },
];

export function Services() {
    return (
        <>
            {servicesListConfig.map(({ id, title, text, icon }) => (
                <Stack
                    key={id}
                    maxWidth="295px"
                    sx={{ justifyContent: "space-between", alignItems: "center" }}
                >
                    <Box
                        width="118px"
                        height="118px"
                        sx={{
                            backgroundColor: ({ palette }) => palette.info.main,
                            borderRadius: "50%",
                        }}
                    >
                        <Icon
                            color="primary"
                            sx={{ width: "100%", height: "100%", fontSize: "64px", pt: "22px" }}
                        >
                            {icon}
                        </Icon>
                    </Box>
                    <Typography variant="subtitle1" pt="37px">
                        {title}
                    </Typography>
                    <Typography variant="body1" align="center">
                        {text}
                    </Typography>
                </Stack>
            ))}
        </>
    );
}
