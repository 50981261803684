import React from "react";

import { Box, List } from "@mui/material";
import { ReactComponent as Dashboard } from "Assets/Images/Dashboard.svg";
import { ReactComponent as Notifications } from "Assets/Images/Notifications.svg";
import { ReactComponent as Submissions } from "Assets/Images/Submissions.svg";
import { routesPaths } from "routesPaths";

import { DashboardNavItem } from "./DashboardNavItem";

const navConfig = [
    {
        id: 1,
        title: "Dashboard",
        path: routesPaths.dashboard,
        icon: <Dashboard />,
    },
    {
        id: 2,
        title: "Submissions",
        path: "/submissions",
        icon: <Submissions />,
    },
    {
        id: 3,
        title: "Notifications",
        path: "/notifications",
        icon: <Notifications />,
    },
];

export function DashboardNavBar() {
    return (
        <Box sx={{ width: "100%", maxWidth: 280, bgcolor: "primary.dark" }}>
            <List>
                {navConfig.map((item) => (
                    <DashboardNavItem key={item.id} item={item} />
                ))}
            </List>
        </Box>
    );
}
