import React from "react";

import { Typography } from "@mui/material";

export function NoInfoMessage() {
    return (
        <Typography variant="body1" color="warning.main" fontStyle="italic">
            No information to display. Please fill out the appropriate form
        </Typography>
    );
}
