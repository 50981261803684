import React, { useEffect } from "react";

import { Stack, styled } from "@mui/material";
import { Logo, logoColors } from "Components/Logo";
import { useLocation } from "react-router-dom";

import { VerificationRegModal } from "./Components/VerificationRegModal";

type FrameProps = {
    type: string;
};

const Header = styled(Stack)(({ theme }) => ({
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    padding: " 0 120px",
    boxSizing: "border-box",
    height: "98px",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
        padding: " 0 10px",
    },
}));

export function VerificationFrame({ type }: FrameProps) {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (
        <Stack sx={{ height: "100vh" }}>
            <Header data-testid="header">
                <Logo color={logoColors.white} />
            </Header>
            <Stack
                sx={{
                    flexGrow: "1",
                    alignSelf: "center",
                    justifyContent: "center",
                }}
            >
                <VerificationRegModal type={type} />
            </Stack>
        </Stack>
    );
}
