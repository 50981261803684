import React, { useEffect } from "react";

import { Location, useLocation, useNavigate } from "react-router-dom";
import { routesPaths } from "routesPaths";
import { addEncryptedToStorage } from "utils/encryption";

const getToken = (path: Location) => path.search.split("=")[1];

export function VerifyPasswordChange() {
    const pathname = useLocation();
    const navigate = useNavigate();
    const token = getToken(pathname);
    useEffect(() => {
        if (token) addEncryptedToStorage(localStorage, "resetToken", token);
        navigate(routesPaths.new_password_page);
    }, []);
    return <div>You will be redirected</div>;
}
