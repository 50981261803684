/* eslint-disable no-param-reassign */
import React, { useState } from "react";

import { Box, TextField, Typography, useTheme } from "@mui/material";
import { CustomInputLabel } from "Components/CustomInputLabel";
import { ErrorField } from "Components/ErrorField";
import { Loader } from "Components/Loader";
import { SnackBar } from "Components/SnackBar";
import { StyledButton } from "Components/StyledButton";
import { Form, Formik, FormikErrors } from "formik";
import { FormContainer } from "Pages/Authorization/Components/AuthorizationForm";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { routesPaths } from "routesPaths";
import { addEncryptedToStorage } from "utils/encryption";

import { IResetPasswordBody } from "../ResetPassword.models";
import { resetPassword } from "../ResetPassword.service";
import { resetPasswordValidationSchema } from "../ResetPassword.validation";

export function ResetPasswordForm() {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoaderOpen, setLoaderOpen] = useState(false);
    const [isSnackOpen, setSnackOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const onSubmitForm = (values: IResetPasswordBody, errors: FormikErrors<IResetPasswordBody>) => {
        setLoaderOpen(true);
        resetPassword({
            email: values.email,
        })
            .then(() => {
                addEncryptedToStorage(localStorage, "resetEmail", values.email);
                navigate(routesPaths.reset_verification, {
                    state: { from: location },
                });
            })
            .catch((err) => {
                setSnackMessage(err.message);
                setSnackOpen(true);
                if (err.response) {
                    switch (err.response.request.status) {
                        // 404 is sent for non-existing accounts and endpoints
                        case 404:
                            addEncryptedToStorage(localStorage, "resetEmail", values.email);
                            navigate(routesPaths.reset_verification, {
                                state: { from: location },
                            });
                            break;
                        default:
                            errors.email = `${err.message} error`;
                    }
                }
            })
            .finally(() => setLoaderOpen(false));
    };
    return (
        <>
            <Formik<IResetPasswordBody>
                initialValues={{
                    email: "",
                }}
                onSubmit={() => console.log("here")}
                validationSchema={resetPasswordValidationSchema}
            >
                {({ values, errors, handleBlur, touched, handleChange, setErrors }) => (
                    <Form id="contacts" data-testid="form" autoComplete="new-password">
                        <FormContainer>
                            <Box m="20px 0">
                                <Box>
                                    <CustomInputLabel label="Email Address" isRequired={false} />
                                    <input
                                        autoComplete="new-password"
                                        type="email"
                                        style={{ display: "none" }}
                                    />
                                    <TextField
                                        inputProps={{
                                            style: {
                                                fontSize: 16,
                                            },
                                        }}
                                        name="email"
                                        error={errors.email !== undefined && touched.email}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            e.target.value = e.target.value.trim();
                                            handleChange(e);
                                            setErrors({ ...errors, email: undefined });
                                        }}
                                        value={values.email}
                                        placeholder="Enter official email address"
                                        autoComplete="new-password"
                                    />
                                    <ErrorField errors={errors.email} touched={touched.email} />
                                </Box>
                            </Box>
                            <StyledButton
                                text="Send Reset Email"
                                width="100%"
                                isDisabled={!!errors.email || !values.email}
                                onHandleClick={() => onSubmitForm(values, errors)}
                            />
                            <Typography
                                variant="body2"
                                align="center"
                                sx={{
                                    color: ({ palette }) => palette.info.contrastText,
                                    mt: "30px",
                                }}
                            >
                                Take me back to{" "}
                                <Link
                                    to={routesPaths.authorization}
                                    style={{
                                        textDecoration: "none",
                                        color: `${theme.palette.primary.main}`,
                                        fontWeight: 600,
                                    }}
                                >
                                    Log in
                                </Link>
                            </Typography>
                        </FormContainer>
                    </Form>
                )}
            </Formik>
            <Loader isLoaderOpen={isLoaderOpen} />
            <SnackBar
                isModalOpen={isSnackOpen}
                setModalOpen={setSnackOpen}
                modalMessage={snackMessage}
                modalType="error"
            />
        </>
    );
}
