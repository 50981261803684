import React, { useEffect, useState } from "react";

import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import { Box, Stack, SvgIcon, Typography, useTheme } from "@mui/material";
import { ReactComponent as closeIcon } from "Assets/Images/closeIcon.svg";
import { ReactComponent as download } from "Assets/Images/download.svg";
import { ReactComponent as list } from "Assets/Images/list.svg";
import { ReactComponent as trash } from "Assets/Images/trash.svg";
import axios, { AxiosRequestConfig } from "axios";
import { trimName } from "utils/helpers";

import { FileUploaderTooltip } from "./FileUploaderTooltip";
import { SnackBar } from "./SnackBar";

type InputPropsType = {
    name: string;
    id: string;
    // eslint-disable-next-line react/require-default-props
    handleBlur?: (e: React.FocusEvent<HTMLElement>) => void;
    setFileURL: (url: string, name: string) => void;
    fileURL: string;
    fileName: string;
    // eslint-disable-next-line react/require-default-props
    reviewStatus?: string;
};

export function DragnDropInputFiles({
    name,
    id,
    handleBlur,
    setFileURL,
    fileURL,
    fileName,
    reviewStatus,
}: InputPropsType) {
    const theme = useTheme();
    const [status, setStatus] = useState("");
    const [errorMessage, setErrorMessage] = useState("Incorrect format");
    let inputContent;
    const [file, setFile] = useState<File>();
    const [fileSize, setFileSize] = useState(0);
    const [fileLoadedSize, setFileLoadedSize] = useState(0);
    const [isFormatCorrect, setFormat] = useState(true);
    const [isDeleteEnabled, setDeleteEnabled] = useState(true);
    const [isUploaded, setIsUploaded] = useState(false);
    const [isUploadFailed, setIsUploadedFailed] = useState(false);
    const [isSnackOpen, setSnackOpen] = useState(false);
    useEffect(() => {
        if (fileName && fileURL) {
            setStatus("loaded");
        }
    }, [fileName]);
    const checkFileConfig = (doc: File) => {
        if (
            doc.type !== "application/pdf" &&
            doc.type !==
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
            doc.type !== "application/msword"
        ) {
            setErrorMessage("File format must be PDF or DOC");
        } else if (doc.size > 10000000) setErrorMessage("Max file size - 10MB.");
        if (
            (doc.type === "application/pdf" ||
                doc.type ===
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                doc.type === "application/msword") &&
            doc.size <= 10000000
        ) {
            setFormat(true);
            return true;
        }
        setFormat(false);
        return false;
    };
    const uploadFile = (
        fileToSend:
            | string
            | Blob
            | Document
            | ArrayBufferView
            | ArrayBuffer
            | FormData
            | null
            | undefined,
    ) => {
        setStatus("loading");
        setDeleteEnabled(false);
        const config: AxiosRequestConfig = {
            onUploadProgress: (progressEvent: any) => {
                setFileSize(Math.round(progressEvent.total / 1000));
                const percentCompleted = Math.round(
                    (progressEvent.loaded / progressEvent.total) * 100,
                );
                setFileLoadedSize(percentCompleted);
                if (percentCompleted === 100) {
                    setStatus("loaded");
                    setDeleteEnabled(true);
                }
            },
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };
        axios
            .post(
                `${process.env.REACT_APP_FILE_SERVICE_URL}upload`,
                { file: fileToSend, service: "onboarding-service" },
                config,
            )
            .then((res) => {
                setFileURL(res.data.data.url, fileName);
                setIsUploaded(true);
                setIsUploadedFailed(false);
            })
            .catch(() => {
                setIsUploaded(false);
                setIsUploadedFailed(true);
                setStatus("loaded");
                setDeleteEnabled(true);
            });
    };
    useEffect(() => {
        if (file && checkFileConfig(file)) {
            uploadFile(file);
        }
    }, [file, fileName]);
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        const { files } = e.target;
        if (files && files.length) {
            setFile(files[0]);
            setFileURL(fileURL, trimName(files[0].name));
            e.target.value = "";
        }
    };
    const handleFileDelete = () => {
        if (isDeleteEnabled) {
            if (reviewStatus === "APPROVED") {
                setSnackOpen(true);
            } else {
                setStatus("");
                setFile(undefined);
                setFileSize(0);
                setFileURL("", "");
            }
        }
    };
    const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (reviewStatus === "APPROVED") {
            setSnackOpen(true);
        } else {
            const { files } = e.dataTransfer;
            if (files && files.length) {
                setFile(e.dataTransfer.files[0]);
                setFileURL(fileURL, trimName(files[0].name));
            }
        }
    };
    switch (status) {
        case "loading":
            inputContent = (
                <Stack direction="row" p="0 22px" alignItems="center" spacing={2.5}>
                    <Box
                        sx={{
                            width: "40px",
                            height: "40px",
                            backgroundColor: ({ palette }) => palette.primary.light,
                            borderRadius: "50%",
                        }}
                    >
                        <SvgIcon
                            component={list}
                            inheritViewBox
                            sx={{
                                pt: "11px",
                                color: ({ palette }) => palette.primary.light,
                            }}
                        />
                    </Box>
                    <Stack alignItems="flex-start" spacing={1}>
                        <Typography
                            variant="body1"
                            sx={{ color: ({ palette }) => palette.primary.dark }}
                        >
                            {fileName}
                        </Typography>
                        <Box
                            sx={{
                                height: "8px",
                                width: "288px",
                                borderRadius: "60px",
                                backgroundColor: ({ palette }) => palette.primary.light,
                            }}
                        >
                            <Box
                                sx={{
                                    height: "8px",
                                    width: `${fileLoadedSize}%`,
                                    borderRadius: "60px",
                                    backgroundColor: ({ palette }) => palette.success.dark,
                                }}
                            />
                        </Box>
                        <Typography
                            variant="body1"
                            sx={{ color: ({ palette }) => palette.primary.dark }}
                        >
                            {fileSize}KB • {fileLoadedSize}% uploaded
                        </Typography>
                    </Stack>
                    <Box
                        onClick={handleFileDelete}
                        sx={{
                            width: "40px",
                            height: "40px",
                            boxSizing: "border-box",
                            backgroundColor: ({ palette }) => palette.primary.light,
                            borderRadius: "50%",
                            border: "2px solid transparent",
                            transition: "border-color 0.3s",
                            "&:hover": {
                                cursor: "pointer",
                                borderColor: ({ palette }) => palette.info.dark,
                            },
                        }}
                    >
                        <SvgIcon
                            component={closeIcon}
                            inheritViewBox
                            sx={{ pt: "11px", pl: "3px" }}
                        />
                    </Box>
                </Stack>
            );
            break;
        case "loaded":
            if (fileName) {
                inputContent = (
                    <Stack
                        direction="row"
                        p="0 22px"
                        alignItems="center"
                        spacing={2}
                        justifyContent="center"
                    >
                        <Stack>
                            <Typography variant="body1" sx={{ color: "#4E5A6C" }}>
                                {fileName}
                            </Typography>
                            {isUploaded && (
                                <Typography variant="body1" sx={{ color: "#4E5A6C" }}>
                                    {fileSize}KB • {fileLoadedSize}% uploaded
                                </Typography>
                            )}
                            {isUploadFailed && (
                                <Typography sx={{ color: ({ palette }) => palette.warning.main }}>
                                    File has not been uploaded
                                </Typography>
                            )}
                        </Stack>
                        <SvgIcon
                            component={trash}
                            inheritViewBox
                            onClick={handleFileDelete}
                            sx={{
                                fontSize: "15px",
                                color: "transparent",
                                "&:hover": {
                                    cursor: "pointer",
                                    borderColor: ({ palette }) => palette.info.dark,
                                },
                            }}
                        />
                    </Stack>
                );
            } else {
                inputContent = (
                    <>
                        <SvgIcon
                            component={download}
                            inheritViewBox
                            sx={{ color: ({ palette }) => palette.info.main, height: "14px" }}
                        />
                        <label htmlFor={id}>
                            Drag file here to upload document or{" "}
                            <span style={{ color: `${theme.palette.info.contrastText}` }}>
                                choose file
                            </span>
                        </label>
                    </>
                );
            }
            break;
        default:
            inputContent = (
                <>
                    <SvgIcon
                        component={download}
                        inheritViewBox
                        sx={{ color: ({ palette }) => palette.info.main, height: "14px" }}
                    />
                    <label htmlFor={id}>
                        Drag file here to upload document or{" "}
                        <span style={{ color: `${theme.palette.info.contrastText}` }}>
                            choose file
                        </span>
                    </label>
                </>
            );
            break;
    }
    return (
        <>
            <Box
                onDrop={handleDrop}
                onDragEnter={handleDrag}
                onDragOver={handleDrag}
                sx={{
                    width: "100%",
                    maxWidth: "545px",
                    backgroundColor: ({ palette }) => palette.info.main,
                    padding: "13px 0",
                    borderRadius: "4px",
                    textAlign: "center",
                    fontWeight: 400,
                    fontSize: "14px",
                    color: ({ palette }) => palette.info.dark,
                    position: "relative",
                }}
            >
                <FileUploaderTooltip content="File format must be PDF or DOC. Max size - 10MB." />
                {inputContent}
                <input
                    type="file"
                    name={name}
                    id={id}
                    onBlur={handleBlur}
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                />
            </Box>
            {isFormatCorrect ? (
                <Typography style={{ width: "19px", height: "40px" }} />
            ) : (
                <Typography variant="body2" sx={{ color: "red", height: "40px" }}>
                    <ErrorRoundedIcon
                        fontSize="small"
                        color="warning"
                        sx={{ position: "relative", top: "6px", mr: "5px" }}
                    />
                    {errorMessage}
                </Typography>
            )}
            <SnackBar
                isModalOpen={isSnackOpen}
                setModalOpen={setSnackOpen}
                modalMessage="This document has been approved. You cannot make changes after approval"
                modalType="error"
            />
        </>
    );
}
