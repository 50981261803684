import React, { useMemo, useRef, useState } from "react";

import { Box, Pagination, Stack, styled, Typography } from "@mui/material";
import { GridApi } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { LinkMoveBack } from "Components/LinkMoveBack";
import { Modal } from "Pages/Dashboard/Components/Modal";
import { routesPaths } from "routesPaths";
import { INotification } from "types/Models";
import { formatDate } from "utils/helpers";
import * as yup from "yup";

import { ViewCell } from "./components/ViewCell";

interface ISubmProps {
    notifications: INotification[];
    notificationsCount: number;
}

const Container = styled(Stack)(() => ({
    padding: "25px",
    width: "100%",
    height: "calc(100vh - 110px)",
    gap: "25px",
    marginBottom: "10px",
}));

const Header = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "20px",
    borderBottom: `2px solid ${theme.palette.success.lighter}`,
    backgroundColor: theme.palette.common.white,
}));

function SubjectCell({ data }: any) {
    return (
        <Stack spacing={1}>
            <Typography variant="button" className="subject" color="text.disabled">
                {data.subject}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary" className="mainSelection">
                Bank ID: {data.institutionId}
            </Typography>
        </Stack>
    );
}

function LogIdCell({ data }: any) {
    return (
        <Stack spacing={1}>
            <Typography variant="button" color="text.disabled" className="secondarySelection">
                Log ID: {data.id}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary">
                {formatDate(data.sentTime)}
            </Typography>
        </Stack>
    );
}

export const validationSchema = yup.object({
    reviewStatus: yup.string(),
    submissionStatus: yup.string(),
});

export function Notifications({ notifications, notificationsCount }: ISubmProps) {
    const notifsForTable = [...notifications]; // added to update the pages number
    const gridRef = useRef<AgGridReact<INotification>>(null);
    const paginationRef = useRef<HTMLElement | null>(null);
    const PAGE_SIZE = 1;
    const [gridApi, setGridApi] = useState<GridApi<INotification> | undefined>();
    const [pagesNumber, setPagesNumber] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [notification, setNotification] = useState<INotification | undefined>(undefined);
    const [isModalOpen, setModalOpen] = useState(false);

    const handleClose = () => {
        setModalOpen(false);
    };

    const onPaginationChanged = () => {
        if (gridApi) {
            setCurrentPage(gridApi.paginationGetCurrentPage() + 1);
            setPagesNumber(gridApi.paginationGetTotalPages());
        }
    };

    const columnDefs = useMemo(
        () => [
            {
                field: "Subject",
                flex: 1,
                minWidth: 600,
                suppressMovable: true,
                cellRenderer: SubjectCell,
            },
            {
                field: "LogId",
                flex: 1,
                minWidth: 400,
                suppressMovable: true,
                cellRenderer: LogIdCell,
            },
            {
                field: "View",
                cellRenderer: ViewCell,
                cellRendererParams: {
                    setNotification,
                    setModalOpen,
                },
                flex: 1,
                minWidth: 100,
                suppressMovable: true,
            },
        ],
        [notifications, notificationsCount],
    );

    return (
        <Stack>
            <Container>
                <LinkMoveBack url={routesPaths.dashboard} text="Dashboard" />
                <Stack
                    height="100%"
                    sx={{
                        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05)",
                    }}
                >
                    <Header>
                        <Typography variant="button" color="common.black">
                            Recent Notifications
                        </Typography>
                    </Header>
                    <Box className="ag-theme-alpine without-header" style={{ height: "100%" }}>
                        <AgGridReact
                            ref={gridRef}
                            columnDefs={columnDefs}
                            rowData={notifsForTable}
                            suppressRowClickSelection
                            onGridReady={(params) => setGridApi(params.api)}
                            pagination
                            paginationPageSize={PAGE_SIZE}
                            onPaginationChanged={onPaginationChanged}
                            headerHeight={0}
                            paginationAutoPageSize
                        />
                    </Box>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        sx={{
                            background: ({ palette }) => palette.common.white,
                            padding: "30px",
                            borderTop: "2px solid",
                            borderColor: ({ palette }) => palette.success.lighter,
                        }}
                    >
                        <Pagination
                            count={pagesNumber}
                            ref={paginationRef}
                            onChange={(e, page) => {
                                setCurrentPage(page);
                                if (gridRef.current) {
                                    gridRef.current.api.paginationGoToPage(page - 1);
                                }
                            }}
                            shape="rounded"
                            page={currentPage}
                        />
                    </Stack>
                </Stack>
            </Container>
            <Modal isModalOpen={isModalOpen} handleClose={handleClose} element={notification} />
        </Stack>
    );
}
