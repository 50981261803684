import React from "react";

import { Box, Stack, styled, Typography } from "@mui/material";
import { INotification } from "types/Models";

import { IInsightStatuses } from "./Components/DashboardHeader";
import { NotificationsSection } from "./Components/NotificationSection";
import { StatusSection } from "./Components/StatusSection";

export interface IInfoProps {
    notifications: INotification[];
    statuses: IInsightStatuses | undefined;
}

const Content = styled(Stack)(() => ({
    padding: "25px",
    width: "100%",
    height: "100%",
    gap: "25px",
    marginBottom: "50px",
}));

const SectionContainer = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    gap: "30px",
    [theme.breakpoints.down("md")]: {
        flexDirection: "column",
    },
}));

const Statuses = styled(Box)(({ theme }) => ({
    height: "100%",
    width: "30%",
    [theme.breakpoints.down("md")]: {
        width: "100%",
    },
}));

const Notifications = styled(Box)(({ theme }) => ({
    height: "100%",
    width: "70%",
    [theme.breakpoints.down("md")]: {
        width: "100%",
    },
}));

export function Dashboard({ notifications, statuses }: IInfoProps) {
    return (
        <Stack>
            <Content>
                <Typography variant="subtitle1" color="action.focus" marginBottom="12px">
                    Dashboard
                </Typography>
                <SectionContainer>
                    <Statuses>
                        <StatusSection statuses={statuses} />
                    </Statuses>
                    <Notifications>
                        <NotificationsSection notifications={notifications} />
                    </Notifications>
                </SectionContainer>
            </Content>
        </Stack>
    );
}
