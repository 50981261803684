import { api } from "Assets/API/api";

import { KYCBody } from "./KYC.model";

export const getCertifications = () =>
    api.get("/onboarding/docs/certifications").then((res) => res);

export const submitCertifications = (body: KYCBody) =>
    api.post("/onboarding/docs/certifications", body).then((res) => res);

export const getIdentity = () => api.get("/onboarding/docs/identity").then((res) => res);

export const submitIdentity = (body: KYCBody) =>
    api.post("/onboarding/docs/identity", body).then((res) => res);

export const getLicense = () => api.get("/onboarding/docs/license").then((res) => res);

export const submitLicense = (body: KYCBody) =>
    api.post("/onboarding/docs/license", body).then((res) => res);

export const getOtherDocs = () => api.get("/onboarding/docs/other-documents").then((res) => res);

export const submitOtherDocs = (body: KYCBody) =>
    api.post("/onboarding/docs/other-documents", body).then((res) => res);

export const getPolicy = () => api.get("/onboarding/docs/policy").then((res) => res);

export const submitPolicy = (body: KYCBody) =>
    api.post("/onboarding/docs/policy", body).then((res) => res);
