export default function List() {
    return {
        MuiList: {
            styleOverrides: {
                root: {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    "& .MuiListItem-root": {
                        paddingLeft: "50px",
                    },
                    "& .MuiListItemIcon-root": {
                        textAlign: "center",
                    },
                    "& .MuiTypography-root": {
                        fontSize: "14px",
                        "&:hover": {
                            textDecoration: "none",
                        },
                    },
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    display: "flex",
                    marginTop: 0,
                    marginBottom: 0,
                    height: "39px",
                    paddingLeft: "30px",
                    alignItems: "center",
                },
            },
        },
    };
}
