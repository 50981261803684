import React from "react";

import { Grid, Typography } from "@mui/material";

import { SettlementsType } from "../ApprovalPage.model";
import { InfoItem } from "./InfoItem";

export function APITextPart({ data }: { data: SettlementsType | undefined }) {
    const hasData =
        data?.collectionBank ||
        data?.collectionAccountNumber ||
        data?.liveAccount1 ||
        data?.liveAccount2 ||
        data?.liveAccount3 ||
        data?.settlementLiveGLAcct ||
        data?.testAccount1 ||
        data?.testAccount2 ||
        data?.testAccount3 ||
        data?.settlementTestGLAcct;
    return hasData ? (
        <>
            <Grid container rowGap={1}>
                <Grid item xs={12} md={3}>
                    <InfoItem name="Collection Bank Name" value={data?.collectionBank} />
                </Grid>
                <Grid item xs={12} md={3}>
                    <InfoItem name="Account Number" value={data?.collectionAccountNumber} />
                </Grid>
            </Grid>
            <Typography
                variant="body2"
                sx={{
                    color: ({ palette }) => palette.info.dark,
                    pt: "15px",
                }}
            >
                Production
            </Typography>
            <Grid container rowGap={1}>
                <Grid item xs={12} md={3}>
                    <InfoItem name="Account Number 1" value={data?.liveAccount1} />
                </Grid>
                <Grid item xs={12} md={3}>
                    <InfoItem name="Account Number 2" value={data?.liveAccount2} />
                </Grid>
                <Grid item xs={12} md={3}>
                    <InfoItem name="Account Number 3" value={data?.liveAccount3} />
                </Grid>
                <Grid item xs={12} md={3}>
                    <InfoItem
                        name="GL Account for Production Settlement"
                        value={data?.settlementLiveGLAcct}
                    />
                </Grid>
            </Grid>
            <Typography
                variant="body2"
                sx={{
                    color: ({ palette }) => palette.info.dark,
                    pt: "15px",
                }}
            >
                Test Environment
            </Typography>
            <Grid container rowGap={1}>
                <Grid item xs={12} md={3}>
                    <InfoItem name="Account Number 1" value={data?.testAccount1} />
                </Grid>
                <Grid item xs={12} md={3}>
                    <InfoItem name="Account Number 2" value={data?.testAccount2} />
                </Grid>
                <Grid item xs={12} md={3}>
                    <InfoItem name="Account Number 3" value={data?.testAccount3} />
                </Grid>
                <Grid item xs={12} md={3}>
                    <InfoItem
                        name="GL Account for Production Settlement"
                        value={data?.settlementTestGLAcct}
                    />
                </Grid>
            </Grid>
        </>
    ) : null;
}
