import React, { useEffect, useState } from "react";

import { Box, Stack, styled, Typography } from "@mui/material";
import { LinkMoveBack } from "Components/LinkMoveBack";
import { Logo, logoColors } from "Components/Logo";
import { SmallNotification } from "Components/SmallNotification";
import { Slider } from "Pages/RegistrationPage/Components/Slider";
import { useLocation } from "react-router-dom";

import { AuthorizationForm } from "./Components/AuthorizationForm";

const PageContainer = styled(Stack)(({ theme }) => ({
    width: "100%",
    maxWidth: "1920px",
    padding: "20px 20px 0",
    margin: "auto",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    gap: "20px",
    [theme.breakpoints.down("lg")]: {
        padding: "70px 20px 0",
    },
    [theme.breakpoints.down("md")]: {
        flexDirection: "column",
    },
    [theme.breakpoints.down("sm")]: {
        padding: "30px 20px 0",
    },
}));

export const FormContainer = styled(Stack)(({ theme }) => ({
    width: "40%",
    [theme.breakpoints.down("md")]: {
        width: "90%",
    },
}));

export function AuthorizationPage() {
    const { pathname } = useLocation();
    const location = useLocation();
    const prevPage = location.state?.from?.pathname;
    const [isModalOpen, setModalOpen] = useState(
        prevPage === "/new-password-page" || prevPage === "/verify-page",
    );
    const modalMessage =
        prevPage === "/new-password-page"
            ? "Your password has been successfully changed"
            : "You have successfully registered!";
    const modalHeader = prevPage === "/new-password-page" ? "Password Changed" : "Congratulations!";
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (
        <Stack sx={{ justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <PageContainer>
                <FormContainer>
                    <Stack sx={{ gap: "35px" }}>
                        <Logo color={logoColors.green} />
                        <LinkMoveBack url="/" text="Back" />
                        <Box>
                            <Typography variant="h2">Login to your account</Typography>
                        </Box>
                    </Stack>
                    <AuthorizationForm />
                </FormContainer>

                <Slider issideSlider={false} />
                {isModalOpen && (
                    <SmallNotification
                        modalHeader={modalHeader}
                        modalMessage={modalMessage}
                        setModalOpen={setModalOpen}
                    />
                )}
            </PageContainer>
        </Stack>
    );
}
