import React from "react";

import { Box, Stack, styled, Typography, useTheme } from "@mui/material";
import apiImage from "Assets/Images/api.png";

import { APIs } from "./Modal";

const Image = styled(Box)(({ theme }) => ({
    width: "282px",
    height: "263px",
    marginRight: "50px",
    backgroundImage: `url(${apiImage})`,
    backgroundSize: "cover",
    [theme.breakpoints.down("sm")]: {
        display: "none",
    },
}));

const Heading = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
        fontSize: "24px",
    },
}));

export function InstructionsList() {
    const theme = useTheme();
    return (
        <>
            <Stack direction="row" alignItems="center">
                <Image />
                <Heading variant="h2">API Documentation</Heading>
            </Stack>
            <Box pt="64px" pb="27px">
                <Typography variant="subtitle1">Instructions</Typography>
                <Typography
                    variant="body1"
                    sx={{ color: theme.palette.secondary.light, pt: "10px" }}
                >
                    Please provide the following APIs:
                </Typography>
                <ul
                    style={{
                        color: theme.palette.secondary.light,
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                        paddingLeft: "25px",
                        maxWidth: "700px",
                    }}
                >
                    {APIs.map((elem) => (
                        <li key={elem.id}>
                            <Typography variant="body1">{elem.content}</Typography>
                        </li>
                    ))}
                </ul>
            </Box>
        </>
    );
}
