import React, { useMemo } from "react";

import { CssBaseline, ThemeOptions } from "@mui/material";
import { createTheme, StyledEngineProvider, ThemeProvider } from "@mui/material/styles";

import componentsOverride from "./overrides";
import palette from "./palette";
import typography from "./typography";

declare module "@mui/material/styles" {
    interface PaletteColor {
        darker?: string;
        lighter?: string;
    }
}

interface IThemeConfigProps {
    children: React.ReactNode;
}

export default function ThemeConfig({ children }: IThemeConfigProps) {
    const themeOptions = useMemo<ThemeOptions>(
        () => ({
            palette,
            typography,
        }),
        [],
    );

    const theme = createTheme(themeOptions);
    theme.components = componentsOverride(theme);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
