import React, { useState } from "react";

import { Stack, styled, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import "ag-grid-community/styles/ag-grid.css";
import { INotification } from "types/Models";

import { Modal } from "./Modal";
import { Notification } from "./Notification";

interface INotifProps {
    notifications: INotification[];
}

export const Container = styled(Stack)(({ theme }) => ({
    height: "100%",
    backgroundColor: theme.palette.common.white,
    border: "1px solid",
    borderColor: "#ebebeb",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05)",
    borderRadius: "6px",
}));

export const Header = styled(Stack)(() => ({
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "14px 20px",
    borderBottom: "2px solid #ebebeb",
}));

export function NotificationsSection({ notifications }: INotifProps) {
    const [notification, setNotification] = useState<INotification | undefined>(undefined);
    const [isModalOpen, setModalOpen] = useState(false);
    const handleClose = () => {
        setModalOpen(false);
    };
    return (
        <Container>
            <Header>
                <Typography variant="button" color="common.black">
                    Recent Notifications
                </Typography>
                <Typography
                    variant="button"
                    color="primary.main"
                    component={Link}
                    to="/notifications"
                >
                    View All
                </Typography>
            </Header>
            {notifications.length ? (
                <Stack spacing={0.5}>
                    {notifications.slice(0, 5).map((item) => (
                        <Notification
                            key={item.id}
                            item={item}
                            setNotification={setNotification}
                            setModalOpen={setModalOpen}
                        />
                    ))}
                </Stack>
            ) : (
                <Stack
                    sx={{
                        width: "100%",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Typography fontSize="13px" color="common.black">
                        No Notifications To Show
                    </Typography>
                </Stack>
            )}

            <Modal isModalOpen={isModalOpen} handleClose={handleClose} element={notification} />
        </Container>
    );
}
