import { Theme } from "@mui/material";
import { merge } from "lodash";

import Accordion from "./Accordion";
import Button from "./Button";
import Checkbox from "./Checkbox";
import Grid from "./Grid";
import List from "./List";
import Paper from "./Paper";
import Stepper from "./Stepper";
import Textfield from "./Textfield";
import Tooltip from "./Tooltip";

export default function ComponentsOverrides(theme: Theme) {
    return merge(
        Accordion(theme),
        Button(theme),
        Checkbox(),
        Grid(),
        List(),
        Paper(),
        Stepper(theme),
        Textfield(theme),
        Tooltip(theme),
    );
}
