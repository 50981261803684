import React, { useEffect, useState } from "react";

import { Box, Button, Stack, Typography } from "@mui/material";
import { CustomInputLabel } from "Components/CustomInputLabel";
import { DragnDropInputFiles } from "Components/DragnDropInputFiles";
import { Loader } from "Components/Loader";
import { SectionLabel } from "Components/SectionLabel";
import { SnackBar } from "Components/SnackBar";
import { StyledButton } from "Components/StyledButton";
import { useNavigate } from "react-router-dom";
import { routesPaths } from "routesPaths";
import { certificates } from "utils/constants";
import { initialState } from "utils/initialState";

import { getCertifications, submitCertifications } from "../KYC.service";
import { KYCInfoPage } from "../KYCInfoPage";

export function Certification() {
    const navigate = useNavigate();
    const [isLoaderOpen, setLoaderOpen] = useState(false);
    const [isSnackOpen, setSnackOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [filesValues, setFilesValues] = useState(initialState.certifications.documents);

    useEffect(() => {
        setLoaderOpen(true);
        getCertifications()
            .then((result) => {
                setFilesValues(
                    result.data.data.documents.length ? result.data.data.documents : filesValues,
                );
            })
            .catch((err) => {
                setSnackMessage(err.message);
                setSnackOpen(true);
            })
            .finally(() => setLoaderOpen(false));
    }, []);
    const onSubmitForm = () => {
        setLoaderOpen(true);
        submitCertifications({ documents: [...filesValues] })
            .then(() => {
                navigate(routesPaths.identity);
            })
            .catch((err) => {
                if (err.response && err.response.request.status === 403) {
                    setSnackMessage(
                        "This step has been approved. You cannot make changes after approval",
                    );
                } else setSnackMessage(err.message);
                setSnackOpen(true);
            })
            .finally(() => setLoaderOpen(false));
    };
    const onSkip = () => {
        navigate(routesPaths.identity);
    };
    return (
        <KYCInfoPage step={1}>
            <Stack flexGrow={1}>
                <Box
                    sx={{
                        bgcolor: ({ palette }) => palette.primary.light,
                        p: "30px 40px 30px 40px",
                        maxWidth: "780px",
                        overflow: "hidden",
                        border: "1px solid",
                        borderRadius: "8px",
                        borderColor: ({ palette }) => palette.warning.contrastText,
                    }}
                >
                    <Typography
                        sx={{
                            pb: "23px",
                            fontWeight: 700,
                            fontSize: "24px",
                        }}
                    >
                        KYC Information
                    </Typography>
                    <Box
                        sx={{
                            width: "95%",
                            height: "1.5px",
                            m: "37px 0",
                            bgcolor: ({ palette }) => palette.info.main,
                        }}
                    />
                    <SectionLabel label="Certification" padding={45} />
                    <Stack sx={{ gap: "40px" }}>
                        {certificates.map(({ id, heading, section, type }) => (
                            <Box key={id}>
                                <CustomInputLabel label={heading} isRequired />
                                <DragnDropInputFiles
                                    data-testid={section}
                                    id={section}
                                    name={section}
                                    setFileURL={(url, name) => {
                                        const newArr = filesValues.map((elem) => {
                                            if (elem.type === type) {
                                                // eslint-disable-next-line no-param-reassign
                                                elem.documentName = name;
                                                // eslint-disable-next-line no-param-reassign
                                                elem.documentURL = url;
                                            }
                                            return elem;
                                        });
                                        setFilesValues(newArr);
                                    }}
                                    fileURL={
                                        filesValues.find((elem) => elem.type === type)
                                            ?.documentURL || ""
                                    }
                                    fileName={
                                        filesValues.find((elem) => elem.type === type)
                                            ?.documentName || ""
                                    }
                                    reviewStatus={
                                        filesValues.find((elem) => elem.type === type)
                                            ?.reviewStatus || ""
                                    }
                                />
                            </Box>
                        ))}
                    </Stack>
                </Box>
                <Stack direction="row" sx={{ gap: "35px", mt: "88px", width: "90%" }}>
                    <Button variant="outlined" onClick={onSkip}>
                        Skip, I’ll fill later
                    </Button>
                    <StyledButton
                        text="Save and continue"
                        width="197px"
                        isDisabled={
                            !filesValues[0].documentURL ||
                            !filesValues[1].documentURL ||
                            !filesValues[2].documentURL ||
                            !filesValues[3].documentURL
                        }
                        onHandleClick={() => onSubmitForm()}
                    />
                </Stack>
                <Loader isLoaderOpen={isLoaderOpen} />
                <SnackBar
                    isModalOpen={isSnackOpen}
                    setModalOpen={setSnackOpen}
                    modalMessage={snackMessage}
                    modalType="error"
                />
            </Stack>
        </KYCInfoPage>
    );
}
