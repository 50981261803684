import React from "react";

import { Typography } from "@mui/material";

interface LabelPropsType {
    label: string;
    padding: number;
}

export function SectionLabel({ label, padding }: LabelPropsType) {
    return (
        <Typography
            sx={{
                pb: `${padding}px`,
                fontWeight: 600,
                fontSize: "14px",
                color: ({ palette }) => palette.info.dark,
            }}
        >
            {label}
        </Typography>
    );
}
