export const routesPaths = {
    index: "/home",
    contacts: "/contacts",
    business_info: "/business_info",
    personalization: "/personalization",
    certification: "/certification",
    identity: "/identity",
    license: "/license",
    policy: "/policy",
    other: "/other",
    api: "/api",
    dev_console: "/dev_console",
    approval: "/approval",
    registration: "/registration",
    authorization: "/authorization",
    reg_verification: "/reg_verification",
    reset_verification: "/reset_verification",
    verify_page: "/verify-page",
    reset_password_page: "/reset-password-page",
    new_password_page: "/new-password-page",
    reset_page: "/reset-page",
    dashboard: "/dashboard",
    submissions: "/submissions",
    notifications: "/notifications",
    personalization_review:"/submissions/personalization-review",
    kyc_review:"/submissions/kyc-review",
    api_review:"/submissions/api-review",
    dev_console_review:"/submissions/dev-console-review",
    approval_review:"/submissions/approval-review",
};
