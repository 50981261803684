import React from "react";

import { Box, TextField } from "@mui/material";

import { CustomInputLabel } from "./CustomInputLabel";
import { ErrorField } from "./ErrorField";

interface InputPropsType {
    errors: string | undefined;
    touched: boolean | undefined;
    handleBlur: any;
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any>
            ? void
            : (e: string | React.ChangeEvent<any>) => void;
    };
    value: string;
    label: string;
    name: string;
    placeholder: string;
    width: string;
    isRequired: boolean;
}

export function SimpleTextInput({
    errors,
    touched,
    handleBlur,
    handleChange,
    value,
    label,
    name,
    placeholder,
    width,
    isRequired,
}: InputPropsType) {
    return (
        <Box width={width} maxWidth="545px">
            <CustomInputLabel label={label} isRequired={isRequired} />
            <input autoComplete="new-password" type="email" style={{ display: "none" }} />
            <TextField
                inputProps={{
                    style: {
                        fontSize: 16,
                    },
                }}
                name={name}
                error={errors !== undefined && touched}
                onBlur={handleBlur}
                onChange={handleChange}
                value={value}
                placeholder={placeholder}
                autoComplete="new-password"
            />
            <ErrorField errors={errors} touched={touched} />
        </Box>
    );
}
