/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from "react";

import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Box, IconButton, TextField, Typography, useTheme } from "@mui/material";
import { CustomInputLabel } from "Components/CustomInputLabel";
import { ErrorField } from "Components/ErrorField";
import { Loader } from "Components/Loader";
import { SnackBar } from "Components/SnackBar";
import { StyledButton } from "Components/StyledButton";
import { Form, Formik } from "formik";
import { FormContainer } from "Pages/Authorization/Components/AuthorizationForm";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { routesPaths } from "routesPaths";
import { getDecryptedFromStorage } from "utils/encryption";

import { IAddNewPasswordBody } from "../ResetPassword.models";
import { addNewPassword } from "../ResetPassword.service";
import { addPasswordValidationSchema } from "../ResetPassword.validation";

export function AddNewPasswordForm() {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const resetToken = getDecryptedFromStorage(localStorage, "resetToken");
    const [isPassVisible, setPassVisibility] = useState(false);
    const [isPassConfVisible, setPassConfVisibility] = useState(false);
    const [isLoaderOpen, setLoaderOpen] = useState(false);
    const [isSnackOpen, setSnackOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const handlePassClick = () => {
        setPassVisibility(!isPassVisible);
    };
    const handlePassConfClick = () => {
        setPassConfVisibility(!isPassConfVisible);
    };
    const cleanUpLocalStorage = () => {
        localStorage.removeItem("resetToken");
    };
    useEffect(() => {
        if (!resetToken) navigate(routesPaths.reset_password_page);
        return () => {
            window.removeEventListener("beforeunload", cleanUpLocalStorage);
        };
    });
    window.addEventListener("beforeunload", cleanUpLocalStorage);
    const onSubmitForm = (values: IAddNewPasswordBody) => {
        setLoaderOpen(true);
        addNewPassword(resetToken, {
            password: values.password,
        })
            .then(() => {
                localStorage.removeItem("resetEmail");
                localStorage.removeItem("resetToken");
                navigate(routesPaths.authorization, { state: { from: location } });
            })
            .catch((err) => {
                setSnackMessage(err.message);
                setSnackOpen(true);
            })
            .finally(() => setLoaderOpen(false));
    };
    return (
        <>
            <Formik<IAddNewPasswordBody>
                initialValues={{
                    password: "",
                    passwordConfirmation: "",
                }}
                onSubmit={() => console.log("here")}
                validationSchema={addPasswordValidationSchema}
            >
                {({ values, errors, handleBlur, touched, handleChange, setErrors }) => (
                    <Form id="contacts" data-testid="form" autoComplete="new-password">
                        <FormContainer>
                            <Box m="20px 0">
                                <Box>
                                    <CustomInputLabel label="Password" isRequired />
                                    <input
                                        autoComplete="new-password"
                                        type="email"
                                        style={{ display: "none" }}
                                    />
                                    <TextField
                                        InputProps={{
                                            style: {
                                                fontSize: 16,
                                            },
                                            endAdornment: (
                                                <IconButton onClick={handlePassClick} edge="end">
                                                    {isPassVisible ? (
                                                        <VisibilityOutlinedIcon />
                                                    ) : (
                                                        <VisibilityOffOutlinedIcon />
                                                    )}
                                                </IconButton>
                                            ),
                                        }}
                                        name="password"
                                        error={errors.password !== undefined && touched.password}
                                        type={isPassVisible ? "text" : "password"}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            e.target.value = e.target.value.trim();
                                            handleChange(e);
                                            setErrors({ ...errors, password: undefined });
                                        }}
                                        value={values.password}
                                        placeholder="Enter password"
                                        autoComplete="new-password"
                                    />
                                    <ErrorField
                                        errors={errors.password}
                                        touched={touched.password}
                                    />
                                </Box>
                                <Box>
                                    <CustomInputLabel label="Confirm Password" isRequired />
                                    <TextField
                                        InputProps={{
                                            style: {
                                                fontSize: 16,
                                            },
                                            endAdornment: (
                                                <IconButton
                                                    onClick={handlePassConfClick}
                                                    edge="end"
                                                >
                                                    {isPassConfVisible ? (
                                                        <VisibilityOutlinedIcon />
                                                    ) : (
                                                        <VisibilityOffOutlinedIcon />
                                                    )}
                                                </IconButton>
                                            ),
                                        }}
                                        name="passwordConfirmation"
                                        error={
                                            errors.passwordConfirmation !== undefined &&
                                            touched.passwordConfirmation
                                        }
                                        type={isPassConfVisible ? "text" : "password"}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            e.target.value = e.target.value.trim();
                                            handleChange(e);
                                            setErrors({
                                                ...errors,
                                                passwordConfirmation: undefined,
                                            });
                                        }}
                                        value={values.passwordConfirmation}
                                        placeholder="Re enter password"
                                        autoComplete="new-password"
                                    />
                                    <ErrorField
                                        errors={errors.passwordConfirmation}
                                        touched={touched.passwordConfirmation}
                                    />
                                </Box>
                            </Box>

                            <StyledButton
                                text="Reset Password"
                                width="100%"
                                isDisabled={
                                    !!errors.password ||
                                    !!errors.passwordConfirmation ||
                                    !values.passwordConfirmation ||
                                    !values.password
                                }
                                onHandleClick={() => onSubmitForm(values)}
                            />
                            <Typography
                                variant="body2"
                                align="center"
                                sx={{
                                    color: ({ palette }) => palette.info.contrastText,
                                    mt: "30px",
                                }}
                            >
                                Take me back to{" "}
                                <Link
                                    to={routesPaths.authorization}
                                    style={{
                                        textDecoration: "none",
                                        color: `${theme.palette.primary.main}`,
                                        fontWeight: 600,
                                    }}
                                >
                                    Log in
                                </Link>
                            </Typography>
                        </FormContainer>
                    </Form>
                )}
            </Formik>
            <Loader isLoaderOpen={isLoaderOpen} />
            <SnackBar
                isModalOpen={isSnackOpen}
                setModalOpen={setSnackOpen}
                modalMessage={snackMessage}
                modalType="error"
            />
        </>
    );
}
