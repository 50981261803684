import React from "react";

import { Box, Typography } from "@mui/material";

import { Label } from "./Label";

type InfoItemPropsType = {
    name: string;
    value: string;
};

export function InfoItem({ name, value }: InfoItemPropsType) {
    return value ? (
        <Box>
            <Label label={name} />
            <Typography
                sx={{
                    fontWeight: 600,
                    fontSize: "16px",
                    color: ({ palette }) => palette.secondary.darker,
                }}
            >
                {value}
            </Typography>
        </Box>
    ) : null;
}
