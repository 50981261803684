import React, { useState } from "react";

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import logo from "Assets/Images/logo.png";
import white_logo from "Assets/Images/white_logo.png";
import useAuthService from "Pages/Authorization/useAuthService";
import { useNavigate } from "react-router-dom";
import { routesPaths } from "routesPaths";

import { StyledButton } from "./StyledButton";

type LogoPropTypes = {
    color: string;
};

export const logoColors = {
    white: `url(${white_logo})`,
    green: `url(${logo})`,
};

export function Logo({ color }: LogoPropTypes) {
    const navigate = useNavigate();
    const { logout, isAuthenticated } = useAuthService();
    const [isDialogOpen, setDialogOpen] = useState(false);
    const onLogoClick = () => {
        if (isAuthenticated) {
            setDialogOpen(true);
        } else navigate(routesPaths.index);
    };
    const handleClose = () => setDialogOpen(false);
    const agreeToLogout = () => {
        logout();
        navigate(routesPaths.index);
    };
    return (
        <>
            <Box
                data-testid="logo"
                onClick={onLogoClick}
                sx={{
                    "&:hover": {
                        cursor: "pointer",
                    },
                }}
            >
                <Box
                    sx={{
                        width: "202px",
                        height: "70px",
                        backgroundImage: color,
                        backgroundSize: "cover",
                    }}
                />
            </Box>
            <Dialog open={isDialogOpen} onClose={handleClose}>
                <DialogTitle>Are you sure you want to log out?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To visit the Home Page you need to log out.
                    </DialogContentText>
                    <DialogContentText>Your changes may not be saved</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={agreeToLogout}
                        variant="outlined"
                        sx={{
                            width: "197px",
                            color: ({ palette }) => palette.info.contrastText,
                        }}
                    >
                        Yes
                    </Button>
                    <StyledButton
                        onHandleClick={handleClose}
                        text="No"
                        isDisabled={false}
                        width="197px"
                    />
                </DialogActions>
            </Dialog>
        </>
    );
}
