import React from "react";

import { Box, Stack, Typography } from "@mui/material";
import { PersonalizationType } from "Pages/Personalization/PersonalizationPage.models";

import { File } from "./File";

export function PersonalizationFiles({ data }: { data: PersonalizationType }) {
    return data && (data.logoName || data.iconName || data.faqName || data.termsDocName) ? (
        <Box>
            <Typography
                sx={{
                    fontWeight: 400,
                    fontSize: "12px",
                    color: ({ palette }) => palette.secondary.light,
                    pb: "10px",
                }}
            >
                Uploads
            </Typography>
            <Stack spacing={2}>
                <File fileName={data?.logoName} fileURL={data?.logoURL} />
                <File fileName={data?.iconName} fileURL={data?.iconURL} />
                <File fileName={data?.faqName} fileURL={data?.faqURL} />
                <File fileName={data?.termsDocName} fileURL={data?.termsDocURL} />
            </Stack>
        </Box>
    ) : null;
}
