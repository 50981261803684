import React from "react";

import DoneIcon from "@mui/icons-material/Done";
import PriorityHighRoundedIcon from "@mui/icons-material/PriorityHighRounded";
import { Box, Stack, SvgIcon, Typography, useTheme } from "@mui/material";

interface IStatusStepProp {
    step: number;
    id: number;
    header: string;
    date: string;
}

export function StatusStep({ step, id, header, date }: IStatusStepProp) {
    const theme = useTheme();
    const rejectedStep = header === "Update requested";
    const lastStepBorderColor = rejectedStep
        ? theme.palette.warning.light
        : theme.palette.secondary.main;
    const lastStepBackColor = rejectedStep
        ? theme.palette.warning.light
        : theme.palette.secondary.main;
    return (
        <Box>
            <Stack direction="row" alignItems="center" spacing={2.5}>
                <Box
                    sx={{
                        width: "26px",
                        height: "26px",
                        borderRadius: "50%",
                        border: "1px solid",
                        flexShrink: 0,
                        borderColor:
                            id > step ? ({ palette }) => palette.info.lighter : lastStepBorderColor,
                        backgroundColor: id > step ? "transparent" : lastStepBackColor,
                    }}
                >
                    {id <= step && (
                        <SvgIcon
                            component={rejectedStep ? PriorityHighRoundedIcon : DoneIcon}
                            inheritViewBox
                            sx={{
                                mt: "2px",
                                ml: "2px",
                                fontSize: "20px",
                                color: ({ palette }) => palette.primary.light,
                            }}
                        />
                    )}
                </Box>
                <Stack>
                    <Typography
                        sx={{
                            fontWeight: 600,
                            fontSize: "14px",
                            color: ({ palette }) => palette.common.black,
                        }}
                    >
                        {header}
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: "12px",
                            color: ({ palette }) => palette.info.lighter,
                        }}
                    >
                        {date}
                    </Typography>
                </Stack>
            </Stack>

            {id !== 4 && (
                <Box
                    sx={{
                        height: "36px",
                        ml: "12px",
                        backgroundColor: ({ palette }) => palette.info.lighter,
                        width: "1px",
                    }}
                />
            )}
        </Box>
    );
}
