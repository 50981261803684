import React, { useEffect, useState } from "react";

import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Stack,
    styled,
    SvgIcon,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import { ReactComponent as Info } from "Assets/Images/info.svg";
import { CustomInputLabel } from "Components/CustomInputLabel";
import { DragnDropInput } from "Components/DragnDropInput";
import { DragnDropInputFiles } from "Components/DragnDropInputFiles";
import { ErrorField } from "Components/ErrorField";
import { Loader } from "Components/Loader";
import { SnackBar } from "Components/SnackBar";
import { StyledButton } from "Components/StyledButton";
import { Form, Formik } from "formik";
import { checkData } from "Pages/BusinessInfoPage/Components/BusinessInfoForm";
import { useNavigate } from "react-router-dom";
import { routesPaths } from "routesPaths";
import { socialMedia } from "utils/constants";
import { initialState } from "utils/initialState";

import { IDataType } from "../PersonalizationPage.models";
import { getPersonalization, submitPersonalization } from "../PersonalizationPage.service";
import { validationSchema } from "../PersonalizationPage.validation";
import { ColorPicker } from "./ColorPicker";
import { Preview } from "./Preview";
import { useFormValues } from "./setData";

const PreviewContainer = styled(Stack)(({ theme }) => ({
    gap: "33px",
    [theme.breakpoints.down("lg")]: {
        display: "none",
    },
}));

const ColorsContainer = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    gap: "24px",
    mb: "10px",
    [theme.breakpoints.down("md")]: {
        flexDirection: "column",
    },
}));

export function PersonalizationForm() {
    const theme = useTheme();
    const navigate = useNavigate();
    const { filesValues, updateValue } = useFormValues({
        primaryColor: "",
        secondaryColor: "",
        faqName: "",
        logoURL: "",
        iconURL: "",
        faqURL: "",
        termsDocURL: "",
        iconName: "",
        logoName: "",
        termsDocName: "",
    });
    const [receivedData, setReceivedData] = useState(initialState.personalization);
    const [preview, setPreview] = useState(0);
    const [primaryColorTouched, setPrimaryColorTouched] = useState(false);
    const [isLoaderOpen, setLoaderOpen] = useState(false);
    const [isSnackOpen, setSnackOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    useEffect(() => {
        setLoaderOpen(true);
        getPersonalization()
            .then((result) => {
                setReceivedData(checkData(result.data.data));
                updateValue("primaryColor", result.data.data.primaryColor);
                updateValue("secondaryColor", result.data.data.secondaryColor);
                updateValue("faqName", result.data.data.faqName);
                updateValue("faqURL", result.data.data.faqURL);
                updateValue("logoURL", result.data.data.logoURL);
                updateValue("logoName", result.data.data.logoName);
                updateValue("iconURL", result.data.data.iconURL);
                updateValue("iconName", result.data.data.iconName);
                updateValue("termsDocURL", result.data.data.termsDocURL);
                updateValue("termsDocName", result.data.data.termsDocName);
            })
            .catch((err) => {
                if (
                    err.response &&
                    err.response.request.status !== 500 &&
                    err.response.request.status !== 404
                ) {
                    setSnackMessage(err.message);
                    setSnackOpen(true);
                }
                console.error(err);
            })
            .finally(() => setLoaderOpen(false));
    }, []);
    const onSubmitForm = (values: IDataType) => {
        setLoaderOpen(true);
        submitPersonalization({ ...values, ...filesValues })
            .then(() => {
                navigate(routesPaths.certification);
            })
            .catch((err) => {
                if (err.response && err.response.request.status === 403) {
                    setSnackMessage(
                        "This step has been approved. You cannot make changes after approval",
                    );
                } else setSnackMessage(err.message);
                setSnackOpen(true);
            })
            .finally(() => setLoaderOpen(false));
    };
    const onSkip = () => {
        navigate(routesPaths.certification);
    };
    return (
        <>
            <Formik
                enableReinitialize
                initialValues={receivedData}
                onSubmit={() => console.log("here")}
                validateOnBlur
                validationSchema={validationSchema}
            >
                {({ values, errors, handleBlur, touched, handleChange, isValid }) => (
                    <Form id="personalization" data-testid="form">
                        <Stack direction="row" sx={{ gap: "60px" }}>
                            <Stack flexGrow={1} width="90%">
                                <Box
                                    sx={{
                                        bgcolor: ({ palette }) => palette.primary.light,
                                        p: "30px 40px 0 40px",
                                        maxWidth: "950px",
                                        overflow: "hidden",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            pb: "23px",
                                            fontWeight: 700,
                                            fontSize: "24px",
                                        }}
                                    >
                                        Personalization
                                    </Typography>
                                    <Box
                                        sx={{
                                            maxWidth: "755px",
                                            width: "95%",
                                            height: "1.5px",
                                            margin: "auto",
                                            m: "37px 0",
                                            bgcolor: ({ palette }) => palette.info.main,
                                        }}
                                    />
                                    <Box maxWidth="545px">
                                        <Typography variant="subtitle2" paddingBottom="5px">
                                            <>
                                                Short Bank Name
                                                <sup
                                                    style={{
                                                        color: `${theme.palette.primary.main}`,
                                                        fontSize: "12px",
                                                    }}
                                                >
                                                    {" "}
                                                    *{" "}
                                                </sup>
                                                <Tooltip
                                                    title="A name your bank is popularly known by. The name is going to be displayed against your bank logo"
                                                    placement="right-end"
                                                >
                                                    <SvgIcon
                                                        component={Info}
                                                        inheritViewBox
                                                        sx={{ fontSize: "16px" }}
                                                    />
                                                </Tooltip>
                                            </>
                                        </Typography>
                                        <TextField
                                            inputProps={{
                                                style: {
                                                    fontSize: 16,
                                                },
                                            }}
                                            name="shortName"
                                            error={
                                                errors.shortName !== undefined && touched.shortName
                                            }
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.shortName}
                                            placeholder="Enter the name to describe your bank"
                                        />
                                        <ErrorField
                                            errors={errors.shortName}
                                            touched={touched.shortName}
                                        />
                                    </Box>
                                    <ColorsContainer>
                                        <Box>
                                            <CustomInputLabel
                                                label="Choose Primary Colour"
                                                isRequired
                                            />
                                            <Accordion
                                                data-testid="primaryColor"
                                                onChange={(e, expanded) => {
                                                    if (expanded) {
                                                        setPreview(1);
                                                    } else setPrimaryColorTouched(true);
                                                }}
                                            >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreRoundedIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    {" "}
                                                    {filesValues.primaryColor ? (
                                                        <Box
                                                            sx={{
                                                                width: "36px",
                                                                height: "36px",
                                                                bgcolor: filesValues.primaryColor,
                                                                borderRadius: "2px",
                                                            }}
                                                        />
                                                    ) : (
                                                        <Typography
                                                            sx={{
                                                                fontWeight: 600,
                                                                fontSize: "12px",
                                                                color: `${theme.palette.secondary.contrastText}`,
                                                            }}
                                                        >
                                                            Select color
                                                        </Typography>
                                                    )}
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Stack
                                                        sx={{
                                                            bgcolor: ({ palette }) =>
                                                                palette.info.light,
                                                            p: "30px",
                                                        }}
                                                    >
                                                        <ColorPicker
                                                            hex={
                                                                filesValues.primaryColor ||
                                                                theme.palette.primary.contrastText
                                                            }
                                                            setHex={(value) =>
                                                                updateValue("primaryColor", value)
                                                            }
                                                        />
                                                    </Stack>
                                                </AccordionDetails>
                                            </Accordion>
                                            {!filesValues.primaryColor && primaryColorTouched ? (
                                                <Typography
                                                    variant="body2"
                                                    sx={{ color: "red", height: "40px" }}
                                                >
                                                    <ErrorRoundedIcon
                                                        fontSize="small"
                                                        color="warning"
                                                        sx={{
                                                            position: "relative",
                                                            top: "6px",
                                                            mr: "5px",
                                                        }}
                                                    />
                                                    This field is required
                                                </Typography>
                                            ) : (
                                                <Typography
                                                    sx={{
                                                        height: "40px",
                                                        fontWeight: 400,
                                                        width: "100%",
                                                    }}
                                                />
                                            )}
                                        </Box>
                                        <Box pt="4px">
                                            <CustomInputLabel
                                                label="Choose Secondary Colour (optional)"
                                                isRequired={false}
                                            />
                                            <Accordion
                                                data-testid="secondaryColor"
                                                disabled={!filesValues.primaryColor}
                                                onChange={(e, expanded) => {
                                                    if (expanded) setPreview(2);
                                                    else setPreview(1);
                                                }}
                                            >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreRoundedIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    {" "}
                                                    {filesValues.secondaryColor ? (
                                                        <Box
                                                            sx={{
                                                                width: "36px",
                                                                height: "36px",
                                                                bgcolor: filesValues.secondaryColor,
                                                                borderRadius: "2px",
                                                            }}
                                                        />
                                                    ) : (
                                                        <Typography
                                                            sx={{
                                                                fontWeight: 400,
                                                                fontSize: "12px",
                                                                color: `${theme.palette.secondary.contrastText}`,
                                                            }}
                                                        >
                                                            Select color
                                                        </Typography>
                                                    )}
                                                </AccordionSummary>
                                                <AccordionDetails onClick={() => setPreview(2)}>
                                                    <Stack
                                                        sx={{
                                                            bgcolor: ({ palette }) =>
                                                                palette.info.light,
                                                            p: "30px",
                                                        }}
                                                    >
                                                        <ColorPicker
                                                            hex={
                                                                filesValues.secondaryColor ||
                                                                theme.palette.primary.main
                                                            }
                                                            setHex={(value) =>
                                                                updateValue("secondaryColor", value)
                                                            }
                                                        />
                                                    </Stack>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Box>
                                    </ColorsContainer>
                                    <Box>
                                        <CustomInputLabel label="Upload Logo " isRequired />
                                        <DragnDropInput
                                            data-testid="logo"
                                            name="logo"
                                            id="logo"
                                            handleBlur={handleBlur}
                                            setImageURL={(value, name) => {
                                                updateValue("logoURL", value);
                                                updateValue("logoName", name);
                                            }}
                                            imageURL={filesValues.logoURL}
                                            imageName={filesValues.logoName}
                                            isLogoType
                                        />
                                    </Box>
                                    <Box>
                                        <CustomInputLabel
                                            label=" Upload Icon "
                                            isRequired={false}
                                        />
                                        <DragnDropInput
                                            data-testid="icon"
                                            name="icon"
                                            id="icon"
                                            handleBlur={handleBlur}
                                            setImageURL={(value, name) => {
                                                updateValue("iconURL", value);
                                                updateValue("iconName", name);
                                            }}
                                            imageURL={filesValues.iconURL}
                                            imageName={filesValues.iconName}
                                            isLogoType={false}
                                        />
                                    </Box>
                                    <Box>
                                        <CustomInputLabel label="Upload FAQs " isRequired />
                                        <DragnDropInputFiles
                                            data-testid="FAQ"
                                            name="FAQ"
                                            id="FAQ"
                                            handleBlur={handleBlur}
                                            setFileURL={(url, name) => {
                                                updateValue("faqURL", url);
                                                updateValue("faqName", name);
                                            }}
                                            fileURL={filesValues.faqURL}
                                            fileName={filesValues.faqName}
                                        />
                                    </Box>
                                    <Box>
                                        <CustomInputLabel
                                            label="Upload Terms and Conditions "
                                            isRequired
                                        />
                                        <DragnDropInputFiles
                                            data-testid="termsDocURL"
                                            name="termsDocURL"
                                            id="termsDocURL"
                                            handleBlur={handleBlur}
                                            setFileURL={(url, name) => {
                                                updateValue("termsDocURL", url);
                                                updateValue("termsDocName", name);
                                            }}
                                            fileURL={filesValues.termsDocURL}
                                            fileName={filesValues.termsDocName}
                                        />
                                    </Box>
                                    <Stack
                                        direction="row"
                                        sx={{
                                            flexWrap: "wrap",
                                            gap: "36px",
                                            pb: "50px",
                                            maxWidth: "570px",
                                        }}
                                    >
                                        {socialMedia.map(({ id, name, value }) => (
                                            <Box key={id} maxWidth="255px">
                                                <CustomInputLabel label={name} isRequired={false} />
                                                <TextField
                                                    inputProps={{
                                                        style: {
                                                            fontSize: 12,
                                                        },
                                                    }}
                                                    name={value}
                                                    error={
                                                        errors[value as keyof IDataType] !==
                                                            undefined &&
                                                        touched[value as keyof IDataType]
                                                    }
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                    }}
                                                    value={values[value as keyof IDataType]}
                                                    placeholder="Enter social media profile URL"
                                                />
                                            </Box>
                                        ))}
                                    </Stack>
                                </Box>
                                <Stack direction="row" sx={{ gap: "35px", mt: "88px" }}>
                                    <Button variant="outlined" onClick={onSkip}>
                                        Skip, I’ll fill later
                                    </Button>
                                    <StyledButton
                                        text="Save and continue"
                                        width="197px"
                                        isDisabled={
                                            !isValid ||
                                            !filesValues.logoURL ||
                                            !filesValues.faqURL ||
                                            !filesValues.primaryColor ||
                                            !filesValues.termsDocURL
                                        }
                                        onHandleClick={() => onSubmitForm(values)}
                                    />
                                </Stack>
                            </Stack>
                            {!!preview && (
                                <PreviewContainer>
                                    <Box
                                        alignSelf="center"
                                        sx={{
                                            p: "6px 19px",
                                            borderRadius: "35px",
                                            border: "1px solid",
                                            borderColor: ({ palette }) => palette.success.light,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: ({ palette }) => palette.success.light,
                                            }}
                                        >
                                            APP PREVIEW
                                        </Typography>
                                    </Box>
                                    <Preview
                                        preview={preview}
                                        primaryColor={filesValues.primaryColor}
                                        secondaryColor={filesValues.secondaryColor}
                                        logo={filesValues.logoURL}
                                        icon={filesValues.iconURL}
                                    />
                                </PreviewContainer>
                            )}
                        </Stack>
                    </Form>
                )}
            </Formik>
            <Loader isLoaderOpen={isLoaderOpen} />
            <SnackBar
                isModalOpen={isSnackOpen}
                setModalOpen={setSnackOpen}
                modalMessage={snackMessage}
                modalType="error"
            />
        </>
    );
}
