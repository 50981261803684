import * as yup from "yup";

export const validationSchema = yup.object({
    name: yup
        .string()
        .min(2, "Field must contain at least 2 characters")
        .max(100, "Field must not exceed 100 characters")
        .required("This field is required"),
    rcNo: yup
        .string()
        .min(2, "Field must contain at least 2 characters")
        .max(100, "Field must not exceed 100 characters")
        .required("This field is required"),
    addressLine1: yup
        .string()
        .min(2, "Field must contain at least 2 characters")
        .max(100, "Field must not exceed 100 characters")
        .required("This field is required"),
    addressLine2: yup
        .string()
        .min(2, "Field must contain at least 2 characters")
        .max(100, "Field must not exceed 100 characters"),
    state: yup.string().required("This field is required"),
    zipCode: yup.string(),
    email: yup
        .string()
        .trim()
        .matches(
            /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/i,
            "Invalid email",
        )
        .required("This field is required"),
    phoneNo: yup
        .string()
        .matches(
            /^\+234\d{10}$/,
            "Your phone number must match the specified format: +234xxxxxxxxxx",
        )
        .required("This field is required"),
    contactName: yup
        .string()
        .min(2, "Field must contain at least 2 characters")
        .max(100, "Field must not exceed 100 characters")
        .required("This field is required"),
    contactEmail: yup
        .string()
        .trim()
        .matches(
            /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/i,
            "Invalid email",
        )
        .required("This field is required"),
    contactPhoneNo: yup
        .string()
        .matches(
            /^\+234\d{10}$/,
            "Your phone number must match the specified format: +234xxxxxxxxxx",
        )
        .required("This field is required"),
});
