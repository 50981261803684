import React from "react";

import { Box, Button, Stack, styled, Typography } from "@mui/material";
import { StyledButton } from "Components/StyledButton";
import { BusinessInfoSection } from "Pages/ApprovalPage/Components/BusinessInfoSection";
import { DevConsoleSection } from "Pages/ApprovalPage/Components/DevConsoleSection";
import { PersonalizationTextSection } from "Pages/ApprovalPage/Components/PersonalizationTextPart";
import { useNavigate } from "react-router-dom";
import { routesPaths } from "routesPaths";

import { SectionHeader } from "../SectionHeader";
import { SimpleVerticalStepper } from "../SimpleVerticalStepper";
import { useData } from "../SubmissionPage";
import { APIContent } from "./APIContent";
import { KYCContent } from "./KYCContent";
import { PersonalizationFiles } from "./PersonalizationFiles";

const Container = styled(Stack)(({ theme }) => ({
    width: "75%",
    [theme.breakpoints.down("lg")]: {
        width: "100%",
        margin: "auto",
    },
}));

const Inner = styled(Stack)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    padding: "40px",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05)",
    borderRadius: "7px",
    border: "1px solid",
    borderColor: theme.palette.warning.contrastText,
}));

export function Approval() {
    const data = useData()?.data;
    const navigate = useNavigate();
    return (
        <>
            <SimpleVerticalStepper step={6} />
            <Container>
                <Inner>
                    {data ? (
                        <Stack spacing={7}>
                            <Box>
                                <SectionHeader
                                    name="Business Information"
                                    needPercentage={false}
                                    needStatus
                                    status={data?.profile?.reviewStatus}
                                />
                                <BusinessInfoSection data={data?.profile} />
                            </Box>
                            <Box>
                                <SectionHeader
                                    name="Personalisation"
                                    needPercentage={false}
                                    needStatus
                                    status={data?.personalization?.reviewStatus}
                                />
                                <Stack spacing={3}>
                                    <PersonalizationTextSection data={data?.personalization} />
                                    <PersonalizationFiles data={data?.personalization} />
                                </Stack>
                            </Box>
                            <Box>
                                <SectionHeader
                                    name="KYC Information"
                                    needPercentage={false}
                                    needStatus={false}
                                />
                                <KYCContent />
                            </Box>
                            <Box>
                                <SectionHeader
                                    name="API Documentation"
                                    needPercentage={false}
                                    needStatus
                                    status={data?.settlements?.reviewStatus}
                                />
                                <APIContent data={data?.settlements} />
                            </Box>
                            <Box>
                                <SectionHeader
                                    name="Interswitch Developer Console"
                                    needPercentage={false}
                                    needStatus
                                    status={data?.iswPortal?.iswPortalReviewStatus}
                                />
                                <DevConsoleSection data={data?.iswPortal} />
                            </Box>
                        </Stack>
                    ) : (
                        <Typography variant="body1" color="warning.main" fontStyle="italic">
                            No information to display
                        </Typography>
                    )}
                </Inner>
                <Stack direction="row" sx={{ gap: "35px", mt: "88px" }}>
                    <Button variant="outlined" onClick={() => navigate(routesPaths.business_info)}>
                        Edit
                    </Button>
                    <StyledButton
                        text="Back to Dashboard"
                        width="197px"
                        isDisabled={false}
                        onHandleClick={() => navigate(routesPaths.dashboard)}
                    />
                </Stack>
            </Container>
        </>
    );
}
