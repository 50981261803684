import React from "react";

import { Box, Grid, Stack, SvgIcon, Typography } from "@mui/material";
import { ReactComponent as file } from "Assets/Images/file.svg";
import { ReactComponent as image } from "Assets/Images/image.svg";
import { PersonalizationType } from "Pages/Personalization/PersonalizationPage.models";

import { Label } from "./Label";
import { NoInfoMessage } from "./NoInfoMessage";
import { PersonalizationTextSection } from "./PersonalizationTextPart";

export function PersonalizationSection({ data }: { data: PersonalizationType | undefined }) {
    return data ? (
        <Stack spacing={5}>
            <PersonalizationTextSection data={data} />
            {data && (data.logoName || data.iconName || data.faqName || data.termsDocName) && (
                <Box>
                    <Label label="Uploads" />
                    <Stack spacing={2}>
                        <Grid container rowGap={3}>
                            {data.logoName ? (
                                <Grid item xs={12} md={6}>
                                    <Stack direction="row" alignItems="center" spacing={1.5}>
                                        <SvgIcon
                                            component={image}
                                            inheritViewBox
                                            sx={{
                                                color: ({ palette }) => palette.info.main,
                                                fontSize: "18px",
                                                fill: "transparent",
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                fontWeight: 600,
                                                fontSize: "16px",
                                                color: ({ palette }) => palette.secondary.darker,
                                            }}
                                        >
                                            {data?.logoName || ""}
                                        </Typography>
                                    </Stack>
                                </Grid>
                            ) : null}
                            {data.iconName ? (
                                <Grid item xs={12} md={6}>
                                    <Stack direction="row" alignItems="center" spacing={1.5}>
                                        <SvgIcon
                                            component={image}
                                            inheritViewBox
                                            sx={{
                                                color: ({ palette }) => palette.info.main,
                                                fontSize: "18px",
                                                fill: "transparent",
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                fontWeight: 600,
                                                fontSize: "16px",
                                                color: ({ palette }) => palette.secondary.darker,
                                            }}
                                        >
                                            {data?.iconName || ""}
                                        </Typography>
                                    </Stack>
                                </Grid>
                            ) : null}
                            {data.faqName ? (
                                <Grid item xs={12} md={6}>
                                    <Stack direction="row" alignItems="center" spacing={1.5}>
                                        <SvgIcon
                                            component={file}
                                            inheritViewBox
                                            sx={{
                                                color: ({ palette }) => palette.info.main,
                                                fontSize: "18px",
                                                fill: "transparent",
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                fontWeight: 600,
                                                fontSize: "16px",
                                                color: ({ palette }) => palette.secondary.darker,
                                            }}
                                        >
                                            {data?.faqName || ""}
                                        </Typography>
                                    </Stack>
                                </Grid>
                            ) : null}
                            {data.termsDocName ? (
                                <Grid item xs={12} md={6}>
                                    <Stack direction="row" alignItems="center" spacing={1.5}>
                                        <SvgIcon
                                            component={file}
                                            inheritViewBox
                                            sx={{
                                                color: ({ palette }) => palette.info.main,
                                                fontSize: "18px",
                                                fill: "transparent",
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                fontWeight: 600,
                                                fontSize: "16px",
                                                color: ({ palette }) => palette.secondary.darker,
                                            }}
                                        >
                                            {data?.termsDocName || ""}
                                        </Typography>
                                    </Stack>
                                </Grid>
                            ) : null}
                        </Grid>
                    </Stack>
                </Box>
            )}
        </Stack>
    ) : (
        <NoInfoMessage />
    );
}
