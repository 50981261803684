import { routesPaths } from "routesPaths";

export const socialMedia = [
    {
        id: 1,
        name: "Facebook",
        value: "facebook",
    },
    {
        id: 2,
        name: "Instagram",
        value: "instagram",
    },
    {
        id: 3,
        name: "Twitter",
        value: "twitter",
    },
    {
        id: 4,
        name: "Linkedin",
        value: "linkedin",
    },
    {
        id: 5,
        name: "Whatsapp",
        value: "whatsapp",
    },
    {
        id: 6,
        name: "Website",
        value: "website",
    },
];

export const identity = [
    {
        id: 1,
        heading: "Unveiling/Identification of all Ultimate Beneficial Owner (UBOs)",
        section: "UBO",
        type: "ULTIMATE_BENEFICAL_OWNER_ID",
    },
    {
        id: 2,
        heading: "Copy of Valid means of Identification of Ultimate Beneficial Owners",
        section: "owners",
        type: "ULTIMATE_BENEFICAL_OWNER_ID_ID_COPY",
    },
    {
        id: 3,
        heading: "Proof of Business Operating Address",
        section: "address",
        type: "OPERATING_ADDRESS_PROOF",
    },
];

export const otherDocs = [
    {
        id: 1,
        heading: "Memorandum and Articles of Association",
        section: "association",
        type: "MEMORANDUM_OF_ASSOCIATION",
    },
    {
        id: 2,
        heading: "Tax Clearance Document",
        section: "tax",
        type: "TAX_CLEARANCE",
    },
    {
        id: 3,
        heading: "Documentary Processes to Transactions Monitoring and Sanctions Screening",
        section: "screening",
        type: "SANCTIONS_SCREENING",
    },
    {
        id: 4,
        heading: "Evidence of AML/CFT Trainings in the Company",
        section: "evidence",
        type: "AML_TRAINING_CERTIFICATE",
    },
];

export const certificates = [
    {
        id: 1,
        heading: "Certificate of Incorporation",
        section: "incorporation",
        type: "INCORPORATION",
    },
    {
        id: 2,
        heading: "Certified Particulars/Register of Directors",
        section: "particulars",
        type: "DIRECTORS_PARTICULARS",
    },
    {
        id: 3,
        heading: "Certified Shareholding Structure",
        section: "structure",
        type: "SHAREHOLDER_STRUCTURE",
    },
    {
        id: 4,
        heading: "Letter of Authorization from Settlement Bank",
        section: "letter",
        type: "AUTHORIZATION_LETTER",
    },
];

export const license = [
    {
        id: 1,
        heading: "Valid MFB License from CBN",
        section: "MFB",
        type: "MFB_LICENSCE",
    },
    {
        id: 2,
        heading: "Approval / No Objection Letter from CBN for MyBanq",
        section: "approval",
        type: "APPROVAL_LETTER",
    },
];

export const policy = [
    {
        id: 1,
        heading: "Anti-Bribery and Corruption Policy",
        section: "corruption",
        type: "CORRUPTION_POLICY",
    },
    {
        id: 2,
        heading: "Code of Ethics/ Business Conduct",
        section: "ethics",
        type: "BUSINESS_ETHICS",
    },
    {
        id: 3,
        heading: "Data Privacy Policy",
        section: "dataPrivacy",
        type: "DATA_PRIVACY",
    },
    {
        id: 4,
        heading: "AML/CFT Policy",
        section: "AML",
        type: "AML_POLICY",
    },
    {
        id: 5,
        heading: "KYC Policy",
        section: "KYC",
        type: "KYC_POLICY",
    },
];

export const steps = [
    {
        id: 1,
        header: "Register Bank",
        content: "Create an account for your bank",
        url: routesPaths.business_info,
    },
    {
        id: 2,
        header: "Personalization",
        content: "Upload documents, input bank details  and indicate your color(s)",
        url: routesPaths.personalization,
    },
    {
        id: 3,
        header: "KYC Information",
        content: "Provide the required details for identification and verification",
        url: routesPaths.certification,
    },
    {
        id: 4,
        header: "API Documentation",
        content: "Provide the required API documentations",
        url: routesPaths.api,
    },
    {
        id: 5,
        header: "Developer Console",
        content: "Register on Interswitch Developer Console",
        url: routesPaths.dev_console,
    },
    {
        id: 6,
        header: "Approval",
        content: "Review and approval of your KYC documents ",
        url: routesPaths.approval,
    },
];

export const additionalSteps = [
    {
        id: 7,
        header: "Dashboard",
        content: "",
        url: routesPaths.dashboard,
    },
    {
        id: 8,
        header: "Submissions",
        content: "",
        url: routesPaths.submissions,
    },
    {
        id: 9,
        header: "Notifications",
        content: "",
        url: routesPaths.notifications,
    },
];

export const vertSteps = [
    {
        id: 1,
        header: "Certification",
        content: "Uploade necessary certificates",
        url: routesPaths.certification,
    },
    {
        id: 2,
        header: "Identity Verification",
        content: "Upload means of identity verification",
        url: routesPaths.identity,
    },
    {
        id: 3,
        header: "License Verification",
        content: "Upload operational license documents",
        url: routesPaths.license,
    },
    {
        id: 4,
        header: "Policy Documents",
        content: "Upload your company policy documents",
        url: routesPaths.policy,
    },
    {
        id: 5,
        header: "Other Documents",
        content: "Upload other necessary policy documents",
        url: routesPaths.other,
    },
];

export const colors = [
    {
        id: 1,
        color: "#6091FF",
    },
    {
        id: 2,
        color: "#69FEFF",
    },
    {
        id: 3,
        color: "#7FFF6F",
    },
    {
        id: 4,
        color: "#BF4B26",
    },
    {
        id: 5,
        color: "#F43135",
    },
    {
        id: 6,
        color: "#C73760",
    },
    {
        id: 7,
        color: "#9629D4",
    },
];
