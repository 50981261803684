import React from "react";

import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import { Typography } from "@mui/material";

interface ErrorFieldPropsType {
    errors: string | undefined;
    touched: boolean | undefined;
}

export function ErrorField({ touched, errors }: ErrorFieldPropsType) {
    return touched && errors ? (
        <Typography variant="body2" sx={{ color: "red", height: "30px" }}>
            <ErrorRoundedIcon
                fontSize="small"
                color="warning"
                sx={{ position: "relative", top: "6px", mr: "5px" }}
            />
            {errors}
        </Typography>
    ) : (
        <Typography
            sx={{
                height: "30px",
            }}
        />
    );
}
