import * as yup from "yup";

export const validationSchema = yup.object({
    collectionBank: yup
        .string()
        .min(2, "Field must contain at least 2 characters")
        .max(100, "Field must not exceed 100 characters")
        .required("This field is required"),
    collectionAccountNumber: yup
        .string()
        .min(2, "Field must contain at least 2 characters")
        .max(100, "Field must not exceed 100 characters")
        .required("This field is required"),
    liveAccount1: yup
        .string()
        .min(2, "Field must contain at least 2 characters")
        .max(100, "Field must not exceed 100 characters")
        .required("This field is required"),
    liveAccount2: yup
        .string()
        .min(2, "Field must contain at least 2 characters")
        .max(100, "Field must not exceed 100 characters")
        .required("This field is required"),
    liveAccount3: yup
        .string()
        .min(2, "Field must contain at least 2 characters")
        .max(100, "Field must not exceed 100 characters")
        .required("This field is required"),
    testAccount1: yup
        .string()
        .min(2, "Field must contain at least 2 characters")
        .max(100, "Field must not exceed 100 characters")
        .required("This field is required"),
    testAccount2: yup
        .string()
        .min(2, "Field must contain at least 2 characters")
        .max(100, "Field must not exceed 100 characters")
        .required("This field is required"),
    testAccount3: yup
        .string()
        .min(2, "Field must contain at least 2 characters")
        .max(100, "Field must not exceed 100 characters")
        .required("This field is required"),
    settlementLiveGLAcct: yup
        .string()
        .min(2, "Field must contain at least 2 characters")
        .max(100, "Field must not exceed 100 characters")
        .required("This field is required"),
    settlementTestGLAcct: yup
        .string()
        .min(2, "Field must contain at least 2 characters")
        .max(100, "Field must not exceed 100 characters")
        .required("This field is required"),
});
