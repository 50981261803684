import React, { useEffect, useState } from "react";

import { Frame } from "Components/Frame";
import { Loader } from "Components/Loader";
import Page from "Components/Page";
import { SnackBar } from "Components/SnackBar";
import { ISubmissionData } from "Pages/ApprovalPage/ApprovalPage.model";
import { IInsightStatuses } from "Pages/Dashboard/Components/DashboardHeader";
import { Dashboard } from "Pages/Dashboard/Dashboard";
import { Notifications } from "Pages/Notifications/Notifications";
import { SubmissionPage } from "Pages/Submissions/SubmissionPage";
import { INotification } from "types/Models";
import { addEncryptedToStorage, getDecryptedFromStorage } from "utils/encryption";

import { getNotifications, getOverviewInfo, getStatuses } from "./CommonPage.service";

export function CommonPage({ type }: { type: string }) {
    const [notifications, setNotifications] = useState<INotification[]>([]);
    const [notificationsCount, setNotificationsCount] = useState(0);
    const instId = getDecryptedFromStorage(localStorage, "institutionId");
    const [statuses, setStatuses] = useState<IInsightStatuses | undefined>(undefined);
    const [submissionData, setSubmissionData] = useState<ISubmissionData | null>(null);
    const [isLoaderOpen, setLoaderOpen] = useState(false);
    const [isSnackOpen, setSnackOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");

    useEffect(() => {
        setLoaderOpen(true);
        getStatuses(instId)
            .then((result) => {
                setStatuses(result.data.data);
            })
            .catch((err) => {
                setSnackMessage(err.message);
                setSnackOpen(true);
            })
            .finally(() => setLoaderOpen(false));
    }, []);

    useEffect(() => {
        setLoaderOpen(true);
        getNotifications(instId)
            .then((result) => {
                setNotifications(result.data.data);
                setNotificationsCount(result.data.totalCount);
            })
            .catch((err) => {
                setSnackMessage(err.message);
                setSnackOpen(true);
            })
            .finally(() => setLoaderOpen(false));
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setLoaderOpen(true);
            getNotifications(instId)
                .then((result) => {
                    setNotifications(result.data.data);
                    setNotificationsCount(result.data.totalCount);
                })
                .catch((err) => {
                    setSnackMessage(err.message);
                    setSnackOpen(true);
                })
                .finally(() => setLoaderOpen(false));
        }, 600000);
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        setLoaderOpen(true);
        getOverviewInfo()
            .then((result) => {
                setSubmissionData(result.data.data);
                addEncryptedToStorage(
                    localStorage,
                    "isSubmitted",
                    (
                        result.data?.data?.overallSubmissionStatus === "SUBMITTED" ||
                        (result.data?.data?.overallSubmissionStatus === "INCOMPLETE" &&
                            result.data?.data?.overallReviewStatus !== null)
                    ).toString(),
                );
            })
            .catch((err) => {
                setSnackMessage(err.message);
                setSnackOpen(true);
            })
            .finally(() => setLoaderOpen(false));
    }, []);
    const content = {
        submissions: <SubmissionPage data={submissionData} />,
        dashboard: <Dashboard notifications={notifications} statuses={statuses} />,
        notifications: (
            <Notifications notifications={notifications} notificationsCount={notificationsCount} />
        ),
    };
    const title = {
        submissions: "Submissions | Onboarding Portal",
        dashboard: "Dashboard | Onboarding Portal",
        notifications: "Notifications | Onboarding Portal",
    };
    return (
        <Page title={title[type as keyof typeof title]}>
            <Frame notificationsCount={notificationsCount} statuses={statuses}>
                {content[type as keyof typeof content]}
            </Frame>
            <Loader isLoaderOpen={isLoaderOpen} />
            <SnackBar
                isModalOpen={isSnackOpen}
                setModalOpen={setSnackOpen}
                modalMessage={snackMessage}
                modalType="error"
            />
        </Page>
    );
}
