/* eslint-disable no-nested-ternary */
import React, { useState } from "react";

import { Box, Stack, SvgIcon, Typography } from "@mui/material";
import { ReactComponent as Approve } from "Assets/Images/approve.svg";
import { ReactComponent as Rejected } from "Assets/Images/rejected.svg";
import { ReactComponent as Wait } from "Assets/Images/wait2.svg";
import { StyledButton } from "Components/StyledButton";
import { useNavigate } from "react-router-dom";

type SectionFooterPropsType = {
    status: string | undefined;
    message: string | undefined;
    editURL: string;
};

const statuses = {
    APPROVED: "Approved",
    UPDATE_REQUESTED: "Update requested",
};

const logoMappings = {
    APPROVED: Approve,
    UPDATE_REQUESTED: Rejected,
};

export function SectionFooter({ status, message, editURL }: SectionFooterPropsType) {
    const isRejected = status === "UPDATE_REQUESTED";
    const reviewStatus = statuses[status as keyof typeof statuses] || "Pending review";
    const logo = logoMappings[status as keyof typeof logoMappings] || Wait;
    const navigate = useNavigate();
    const [isMessageShown, setIsMessageShown] = useState(false);
    return (
        <Stack direction="row" justifyContent="space-between">
            <Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                    <SvgIcon
                        component={logo}
                        inheritViewBox
                        sx={{
                            m: "5px",
                            fontSize: "22px",
                            color: ({ palette }) => palette.grey[300],
                        }}
                    />
                    <Typography variant="button">{reviewStatus}</Typography>
                </Stack>
                {isRejected && (
                    <Box>
                        <Typography
                            sx={{
                                fontWeight: 400,
                                width: "max-content",
                                fontSize: "12px",
                                color: ({ palette }) => palette.action.active,
                                textTransform: "uppercase",
                                borderBottom: "1px dashed",
                                cursor: "pointer",
                            }}
                            onClick={() => setIsMessageShown(!isMessageShown)}
                        >
                            {isMessageShown ? "Hide Reasons " : "See Reasons below"}
                        </Typography>
                        {isMessageShown && (
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    pt: "10px",
                                    color: ({ palette }) => palette.action.active,
                                }}
                            >
                                &#x2022; {message}
                            </Typography>
                        )}
                    </Box>
                )}
            </Stack>
            {isRejected && (
                <StyledButton
                    width="197px"
                    text="Edit Record"
                    isDisabled={false}
                    onHandleClick={() => navigate(editURL)}
                />
            )}
        </Stack>
    );
}
