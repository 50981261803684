import React from "react";

import { Box, Stack, styled, Typography } from "@mui/material";
import background from "Assets/Images/background.png";
import narrative from "Assets/Images/narrative.png";
import { StyledButton } from "Components/StyledButton";
import { useNavigate } from "react-router-dom";
import { routesPaths } from "routesPaths";

import { Services } from "./Services";

const MainTextContainer = styled(Stack)(({ theme }) => ({
    width: "510px",
    marginLeft: "140px",
    marginTop: "230px",
    gap: "34px",
    [theme.breakpoints.down("md")]: {
        margin: "20px",
        marginTop: "300px",
    },
    [theme.breakpoints.down("sm")]: {
        width: "auto",
        margin: "20px",
        marginTop: "340px",
        alignItems: "center",
    },
}));

const MainText = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
        fontSize: "48px",
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: "32px",
        textAlign: "center",
    },
}));

const Heading = styled(Typography)(({ theme }) => ({
    textAlign: "center",
    width: "80%",
    padding: "70px 0",
    [theme.breakpoints.down("md")]: {
        fontSize: "36px",
        padding: "35px 0",
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: "28px",
    },
    [theme.breakpoints.down("xs")]: {
        fontSize: "24px",
    },
}));

const ServicesContainer = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    width: "90%",
    paddingBottom: "85px",
    gap: "10px",
    [theme.breakpoints.down("md")]: {
        flexDirection: "column",
    },
}));

const Narrative = styled(Stack)(({ theme }) => ({
    width: "60%",
    gap: "26px",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
        width: "80%",
    },
    [theme.breakpoints.down("sm")]: {
        width: "90%",
    },
}));

export function Main() {
    const navigate = useNavigate();
    const onGetStartedClick = () => {
        navigate(routesPaths.registration);
    };
    return (
        <Stack data-testid="main" mb="120px">
            <Stack
                sx={{
                    height: "699px",
                    backgroundImage: `url(${background})`,
                    backgroundSize: "cover",
                }}
                data-testid="welcome"
            >
                <MainTextContainer>
                    <MainText variant="h1">Expand your Customer-base in 10 minutes!</MainText>
                    <StyledButton
                        text="Get started"
                        width="197px"
                        isDisabled={false}
                        onHandleClick={onGetStartedClick}
                    />
                </MainTextContainer>
            </Stack>
            <Stack
                width="100%"
                sx={{
                    margin: "auto",
                    alignItems: "center",
                }}
                data-testid="goals"
            >
                <Heading variant="h2">How we Help you Achieve your Goals</Heading>
                <ServicesContainer>
                    <Services />
                </ServicesContainer>
                <StyledButton
                    text="Get started"
                    width="197px"
                    isDisabled={false}
                    onHandleClick={onGetStartedClick}
                />
            </Stack>
            <Stack
                sx={{
                    gap: "40px",
                    p: "115px 20px 0",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                }}
                data-testid="narrative"
            >
                <Box
                    component="img"
                    alt="narrative"
                    src={narrative}
                    sx={{
                        width: { xs: "90%", lg: "50%" },
                    }}
                />
                <Narrative>
                    <Heading variant="h2" pt="95px">
                        Changing the Narrative with MyBanq
                    </Heading>
                    <Typography variant="body1" sx={{ textAlign: "center" }}>
                        Before now, mobile banking services were limited to financial institutions
                        that have the resources to build a mobile app. With MyBanq, we want to
                        change the narrative by helping you push the boundaries of your services
                        while we do the heavy lifting of building and maintain the app. With MyBanq,
                        you can do more for your customers in the easiest and most affordable way.
                    </Typography>
                    <StyledButton
                        text="Get started"
                        width="197px"
                        isDisabled={false}
                        onHandleClick={onGetStartedClick}
                    />
                </Narrative>
            </Stack>
        </Stack>
    );
}
