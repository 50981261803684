import React from "react";

import { Typography } from "@mui/material";

interface LabelPropsType {
    label: string;
}

export function Label({ label }: LabelPropsType) {
    return (
        <Typography
            sx={{
                fontWeight: 400,
                fontSize: "12px",
                color: ({ palette }) => palette.secondary.light,
                pb: "10px",
            }}
        >
            {label}
        </Typography>
    );
}
