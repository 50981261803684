import React, { useEffect } from "react";

import { Button, Stack, styled } from "@mui/material";
import { Logo, logoColors } from "Components/Logo";
import { StyledButton } from "Components/StyledButton";
import useAuthService from "Pages/Authorization/useAuthService";
import { useNavigate } from "react-router-dom";
import { routesPaths } from "routesPaths";

const HeaderContainer = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    width: "100%",
    maxWidth: "1920px",
    backgroundColor: theme.palette.primary.dark,
    position: "absolute",
    padding: " 0 50px",
    boxSizing: "border-box",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "15px",
    [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
    },
}));

export function HeaderWithNavigation() {
    const { isAuthenticated } = useAuthService();
    const navigate = useNavigate();
    useEffect(() => {
        if (isAuthenticated) navigate(routesPaths.business_info);
    }, []);
    const onContactClick = () => {
        navigate(routesPaths.contacts);
    };
    const onGetStartedClick = () => {
        navigate(routesPaths.registration);
    };
    const onLoginClick = () => {
        navigate(routesPaths.authorization);
    };
    return (
        <HeaderContainer data-testid="header">
            <Logo color={logoColors.green} />
            <Stack
                direction="row"
                sx={{
                    color: "white",
                    gap: "15px",
                    p: "34px 0",
                    flexDirection: {
                        xs: "column",
                        sm: "column",
                        md: "row",
                    },
                }}
            >
                <Button onClick={onContactClick}>Contact us</Button>
                <StyledButton
                    text="Get started"
                    width="197px"
                    isDisabled={false}
                    onHandleClick={onGetStartedClick}
                />
                <Button variant="contained" onClick={onLoginClick}>
                    Login
                </Button>
            </Stack>
        </HeaderContainer>
    );
}
