import React from "react";

import { Box, Stack, styled, Typography } from "@mui/material";

type InfoPropsType = {
    isSubmitted: boolean;
    percentage: number | undefined;
};

const InfoContainer = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    maxWidth: "1130px",
    width: "100%",
    padding: "40px",
    boxSizing: "border-box",
    justifyContent: "space-between",
    border: "1px solid",
    borderRadius: "8px",
    borderColor: theme.palette.warning.contrastText,
    backgroundColor: "#F8FEFA",
    [theme.breakpoints.down("md")]: {
        flexDirection: "column",
    },
}));

const PercentContainer = styled(Stack)(({ theme }) => ({
    width: "100%",
    flexDirection: "row",
    alignItems: "baseline",
    gap: "70px",
    [theme.breakpoints.down("md")]: {
        width: "250px",
    },
}));

const ProgressSection = styled(Box)(({ theme }) => ({
    width: "100%",
    height: "4px",
    backgroundColor: theme.palette.info.main,
    [theme.breakpoints.down("md")]: {
        width: "250px",
    },
}));

export function Info({ isSubmitted, percentage }: InfoPropsType) {
    const procent = percentage;
    return (
        <InfoContainer>
            <Stack maxWidth="660px">
                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: "24px",
                        color: ({ palette }) => palette.primary.main,
                    }}
                >
                    {isSubmitted ? "Congratulations!" : "Review Details!"}
                </Typography>
                <Typography variant="body1">
                    {isSubmitted
                        ? "Your profile is complete and is currently under review for approval. We’ll get in touch with you once approvals are complete."
                        : "Please review all details inputed and confirm before submitting."}
                </Typography>
            </Stack>
            <Stack justifyContent="center">
                <PercentContainer>
                    <Typography
                        sx={{
                            fontWeight: 700,
                            fontSize: "16px",
                        }}
                    >
                        Business Profile
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: 600,
                            fontSize: "24px",
                            color: ({ palette }) => palette.primary.main,
                        }}
                    >
                        {procent}%
                    </Typography>
                </PercentContainer>
                <ProgressSection>
                    <Box
                        sx={{
                            width: `${procent}%`,
                            height: "100%",
                            backgroundColor: ({ palette }) => palette.primary.main,
                        }}
                    />
                </ProgressSection>
            </Stack>
        </InfoContainer>
    );
}
