import React from "react";

import { Stack, styled } from "@mui/material";
import { FormFrame } from "Components/FormFrame";
import { Heading } from "Components/Heading";
import { StepperFrame } from "Components/StepperFrame";

import { APIDocumentationForm } from "./Components/APIDocumentationForm";

export const PageContainer = styled(Stack)(({ theme }) => ({
    padding: "40px 20px 185px 160px",
    backgroundColor: theme.palette.info.main,
    gap: "36px",
    [theme.breakpoints.down("lg")]: {
        padding: "40px 20px 185px 100px",
    },
    [theme.breakpoints.down("md")]: {
        padding: "40px 20px 185px 20px",
    },
}));

export function APIDocumentationPage() {
    return (
        <FormFrame>
            <StepperFrame step={4}>
                <PageContainer>
                    <Heading />
                    <APIDocumentationForm />
                </PageContainer>
            </StepperFrame>
        </FormFrame>
    );
}
