import React from "react";

import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import MarkAsUnreadRoundedIcon from "@mui/icons-material/MarkAsUnreadRounded";
import {
    Box,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    styled,
    Typography,
    useTheme,
} from "@mui/material";
import contacts from "Assets/Images/contacts2.png";

import { ContactForm } from "./ContactForm";

const contactsListConfig = [
    {
        id: 1,
        icon: <LocalPhoneIcon />,
        text: "+234 1 234 567, +",
        addText: "234 812 345 6789",
        path: "tel:2341234567",
        addPath: "tel:2348123456789",
    },
    {
        id: 2,
        icon: <MarkAsUnreadRoundedIcon />,
        text: "Mfsbusiness@interswitchgroup.com",
        path: "mailto:Mfsbusiness@interswitchgroup.com",
    },
    {
        id: 3,
        icon: <LocationOnRoundedIcon />,
        text: "1, Oko Awo StorageEvent, Victoria Island LinearProgress, Nigeria",
        path: "https://www.google.com/maps/place/1+Oko+Awo+St,+Lagos+Island+106104,+Lagos,+%D0%9D%D0%B8%D0%B3%D0%B5%D1%80%D0%B8%D1%8F/@6.4285212,3.4237426,17z/data=!3m1!4b1!4m5!3m4!1s0x103bf52e26f4b70d:0x5678425ae7a625ab!8m2!3d6.4285212!4d3.4263175?hl=ru",
    },
];

const MainContainer = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    marginTop: "100px",
    marginBottom: "150px",
    [theme.breakpoints.down("md")]: {
        marginTop: "270px",
    },
    [theme.breakpoints.down("sm")]: {
        marginTop: "350px",
    },
}));

const Image = styled(Stack)(({ theme }) => ({
    width: "50%",
    height: "830px",
    backgroundImage: `url(${contacts})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    alignItems: "flex-end",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
        display: "none",
    },
}));

const ContactsContainer = styled(Box)(({ theme }) => ({
    position: "relative",
    left: "50px",
    width: "400px",
    height: "300px",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "12px",
}));

const FormContainer = styled(Stack)(({ theme }) => ({
    margin: "0 auto",
    width: "50%",
    [theme.breakpoints.down("md")]: {
        width: "100%",
    },
}));

const OuterForm = styled(Stack)(({ theme }) => ({
    width: "65%",
    margin: "auto",
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
        width: "70%",
    },
    [theme.breakpoints.down("sm")]: {
        width: "90%",
        padding: "10px",
    },
}));

const FormHeading = styled(Typography)(({ theme }) => ({
    paddingBottom: "42px",
    [theme.breakpoints.down("md")]: {
        fontSize: "36px",
    },
    [theme.breakpoints.up("md")]: {
        minWidth: "333px",
    },
}));

export function Main() {
    const theme = useTheme();
    return (
        <MainContainer data-testid="main">
            <Image data-testid="image">
                <ContactsContainer data-testid="contacts">
                    <List sx={{ height: "300px" }}>
                        {contactsListConfig.map(({ id, icon, text, addText, path, addPath }) => (
                            <ListItem key={id}>
                                <ListItemIcon
                                    sx={{ color: ({ palette }) => palette.primary.light }}
                                >
                                    {icon}
                                </ListItemIcon>
                                <ListItemText
                                    sx={{
                                        borderLeft: "solid 2px",
                                        borderColor: ({ palette }) => palette.primary.light,
                                    }}
                                >
                                    <Link
                                        style={{
                                            color: `${theme.palette.primary.light}`,
                                        }}
                                        target={id === 3 ? "_blank" : "_self"}
                                        href={path}
                                    >
                                        {text}
                                    </Link>
                                    {addPath ? (
                                        <Link
                                            href={addPath}
                                            style={{
                                                color: `${theme.palette.primary.light}`,
                                            }}
                                        >
                                            {addText}
                                        </Link>
                                    ) : null}
                                </ListItemText>
                            </ListItem>
                        ))}
                    </List>
                </ContactsContainer>
            </Image>
            <FormContainer>
                <OuterForm>
                    <FormHeading variant="h1">Contact Us</FormHeading>
                    <ContactForm />
                </OuterForm>
            </FormContainer>
        </MainContainer>
    );
}
