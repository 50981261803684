import React from "react";

import { Box, Stack, styled, Typography } from "@mui/material";
import { Logo, logoColors } from "Components/Logo";

import { DashboardNavBar } from "./DashboardNavBar";

const SidebarContaiber = styled(Stack)(({ theme }) => ({
    backgroundColor: theme.palette.primary.dark,
    justifyContent: "space-between",
    height: "100%",
    position: "fixed",
    [theme.breakpoints.down("md")]: {
        position: "initial",
    },
}));

export function DashboardSideBar() {
    return (
        <SidebarContaiber data-testid="sidebar">
            <Box width="270px">
                <Box padding="30px 0 30px 30px">
                    <Logo color={logoColors.green} />
                </Box>
                <DashboardNavBar />
            </Box>
            <Typography fontSize="14px" padding="0 30px 40px" color="secondary.darker">
                © 2002 - {new Date().getFullYear()} Interswitch Group
            </Typography>
        </SidebarContaiber>
    );
}
