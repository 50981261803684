import React, { useState } from "react";

import { Grid, Stack, styled, Typography } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import { INotification } from "types/Models";
import { formatDate } from "utils/helpers";

interface INotificationProps {
    item: INotification;
    setNotification: (value: INotification | undefined) => void;
    setModalOpen: (value: boolean) => void;
}

const Container = styled(Grid)(({ theme }) => ({
    boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.07)",
    background: theme.palette.common.white,
    "&:hover": {
        background: theme.palette.primary.lighter,
    },
}));

const SecondColumn = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
        display: "none",
    },
}));

export function Notification({ item, setNotification, setModalOpen }: INotificationProps) {
    const onNotificationView = (notif: INotification | undefined) => {
        setNotification(notif);
        setModalOpen(true);
    };
    const [isHovered, setIsHovered] = useState(false);
    return (
        <Container
            container
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <Grid item xs={8} sm={5} md={6}>
                <Stack spacing={1} padding="10px 22px">
                    <Typography
                        sx={{
                            fontSize: "14px",
                            fontWeight: isHovered ? "800" : "600",
                            color: ({ palette }) => palette.grey[500],
                        }}
                    >
                        {item.subject}
                    </Typography>
                    <Typography
                        variant="button"
                        color={isHovered ? "error.darker" : "info.lighter"}
                    >
                        {item.message.substring(0, 30)}...
                    </Typography>
                </Stack>
            </Grid>
            <SecondColumn item sm={5} md={4}>
                <Stack spacing={1} padding="10px 22px">
                    <Typography
                        variant="button"
                        sx={{
                            color: isHovered ? "primary.main" : ({ palette }) => palette.grey[500],
                        }}
                    >
                        Message Ref: {item.id}
                    </Typography>
                    <Typography variant="button" color="info.lighter">
                        {formatDate(item.sentTime)}
                    </Typography>
                </Stack>
            </SecondColumn>
            <Grid item xs={4} sm={2} md={2}>
                <Stack height="70px">
                    <Typography
                        variant="button"
                        margin="auto"
                        padding="10px 20px"
                        onClick={() => onNotificationView(item)}
                        sx={{ cursor: "pointer", color: ({ palette }) => palette.grey[500] }}
                    >
                        View
                    </Typography>
                </Stack>
            </Grid>
        </Container>
    );
}
