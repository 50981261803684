import React from "react";

import { Stack } from "@mui/material";
import { FormFrame } from "Components/FormFrame";
import { Heading } from "Components/Heading";
import { StepperFrame } from "Components/StepperFrame";
import { PageContainer } from "Pages/APIDocumentationPage/APIDocumentationPage";

import { VerticalStepper } from "./Components/VerticalStepper";

type PagePropTypes = {
    children: JSX.Element;
    step: number;
};

export function KYCInfoPage({ children, step }: PagePropTypes) {
    return (
        <FormFrame>
            <Stack sx={{ justifyContent: "center", alignItems: "center" }}>
                <Stack sx={{ width: "100%", maxWidth: "1920px" }}>
                    <StepperFrame step={3}>
                        <PageContainer>
                            <Heading />
                            <Stack direction="row" alignItems="flex-start">
                                <VerticalStepper step={step} />
                                {children}
                            </Stack>
                        </PageContainer>
                    </StepperFrame>
                </Stack>
            </Stack>
        </FormFrame>
    );
}
