import React from "react";

import { alpha, ListItemIcon, ListItemText, Stack, Typography, useTheme } from "@mui/material";
import { NavLink } from "react-router-dom";

export interface ISidebarItem {
    id: string | number;
    title: string;
    path: string;
    icon: React.ReactNode;
}

interface INavItemProps {
    item: ISidebarItem;
}

export function DashboardNavItem({ item }: INavItemProps) {
    const { title, path, icon } = item;
    const theme = useTheme();

    return (
        <NavLink
            to={path}
            style={({ isActive }) => ({
                width: "100%",
                backgroundColor: isActive
                    ? alpha(theme.palette.secondary.main, 0.2)
                    : "transparent",
                color: isActive ? theme.palette.secondary.main : theme.palette.grey[500],
            })}
        >
            {({ isActive }) => (
                <Stack direction="row" alignItems="center" p="0 30px">
                    <ListItemIcon
                        sx={{
                            stroke: isActive
                                ? ({ palette }) => palette.secondary.main
                                : ({ palette }) => palette.grey[500],
                            fill: isActive
                                ? theme.palette.secondary.main
                                : theme.palette.text.disabled,
                        }}
                    >
                        {icon}
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="h4">{title}</Typography>} />
                </Stack>
            )}
        </NavLink>
    );
}
