import { TypographyOptions } from "@mui/material/styles/createTypography";

function pxToRem(value: number) {
    return `${value / 16}rem`;
}

const FONT_PRIMARY = "Averta, sans-serif";

const typography: TypographyOptions = {
    fontFamily: FONT_PRIMARY,
    fontSize: 16,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h1: {
        fontWeight: 700,
        fontSize: pxToRem(64),
    },
    h2: {
        fontWeight: 700,
        fontSize: pxToRem(40),
    },
    h3: {
        fontWeight: 700,
        fontSize: pxToRem(32),
    },
    h4: {
        fontWeight: 600,
        fontSize: pxToRem(26),
    },
    h5: {
        fontWeight: 600,
        fontSize: pxToRem(18),
        color: "#9CA3AF",
    },
    h6: {
        fontWeight: 400,
        fontSize: pxToRem(20),
        color: "#5F738C",
    },
    subtitle1: {
        fontWeight: 700,
        fontSize: pxToRem(20),
    },
    subtitle2: {
        fontWeight: 600,
        fontSize: pxToRem(12),
    },
    body1: {
        fontWeight: 400,
        fontSize: pxToRem(16),
    },
    body2: {
        fontWeight: 400,
        fontSize: pxToRem(14),
        color: "#353F50",
    },
    caption: {
        fontWeight: 400,
        lineHeight: 1.5,
        fontSize: pxToRem(10),
    },
    overline: {
        fontWeight: 400,
        lineHeight: 1.5,
        fontSize: pxToRem(14),
        letterSpacing: 1.1,
        textTransform: "uppercase",
    },
    button: {
        fontWeight: 600,
        lineHeight: 1.5,
        fontSize: pxToRem(14),
        textTransform: "none",
    },
};

export default typography;