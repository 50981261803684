import React from "react";

import { Avatar, Badge, Stack, styled, Typography } from "@mui/material";

import { BurgerMenu } from "../../../Components/BurgerMenu";

interface IHeaderProps {
    onOpenSidebar: () => void;
    notificationsCount: number;
    statuses: IInsightStatuses | undefined;
}

export interface IInsightStatuses {
    name: string;
    id: number;
    reviewStatus: string;
    applicationStatus: {
        dateCreated: string;
        dateOverallApproved: string;
        dateReviewStarted: string;
        dateSubmitted: string;
    };
}

const trimName = (name: string) => {
    if (name) {
        const arr = name.split(" ");
        if (arr.length > 1) {
            return `${arr[0][0]}${arr[1][0]}`;
        }
        return `${arr[0][0]}${arr[0][1]}`;
    }
    return name;
};

const HeaderContainer = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    width: "100%",
    height: "100px",
    justifyContent: "space-between",
    padding: "0 30px",
    borderBottom: `solid ${theme.palette.grey[100]} 1px`,
    backgroundColor: theme.palette.background.default,
}));

const UserInfo = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    alignItems: "center",
    gap: "45px",
    [theme.breakpoints.down("sm")]: {
        gap: 0,
    },
}));
const User = styled(Stack)(({ theme }) => ({
    alignItems: "flex-end",
    [theme.breakpoints.down("sm")]: {
        display: "none",
    },
}));
const UserName = styled(Typography)(({ theme }) => ({
    fontWeight: "700",
    fontSize: "16px",
    letterSpacing: "0.03em",
    color: theme.palette.common.black,
}));
const StyledAvatar = styled(Avatar)(({ theme }) => ({
    width: "46px",
    height: "46px",
    backgroundColor: theme.palette.primary.lighter,
    color: theme.palette.primary.main,
    fontWeight: 400,
    fontSize: "20px",
}));

export function DashboardHeader({ onOpenSidebar, notificationsCount, statuses }: IHeaderProps) {
    return (
        <HeaderContainer data-testid="header">
            <Stack direction="row" alignItems="center">
                <BurgerMenu onOpenSidebar={onOpenSidebar} />
            </Stack>
            <UserInfo>
                <User>
                    <Typography
                        variant="button"
                        sx={{
                            color: ({ palette }) => palette.grey[400],
                            letterSpacing: "0.03em",
                        }}
                    >
                        Welcome
                    </Typography>
                    <UserName>{statuses?.name || "User"}</UserName>
                </User>
                <Badge badgeContent={notificationsCount} color="error" sx={{ fontSize: "12px" }}>
                    <StyledAvatar data-testid="avatar">
                        {statuses?.name ? trimName(statuses?.name) : trimName("User")}
                    </StyledAvatar>
                </Badge>
            </UserInfo>
        </HeaderContainer>
    );
}
