import { Theme } from "@mui/material";

export default function Tooltip(baseTheme: Theme) {
    return {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    width: "195px",
                    color: baseTheme.palette.info.contrastText,
                    backgroundColor: baseTheme.palette.info.main,
                    fontWeight: 400,
                    fontSize: "10px",
                    lineHeight: "16px",
                },
                popper: {
                    borderRadius: "11px",
                },
            },
        },
    };
}