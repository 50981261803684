import React from "react";

import { ArrowBackIosNew } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

type LinkPropsType = {
    url: string;
    text: string;
};

export function LinkMoveBack({ url, text }: LinkPropsType) {
    return (
        <Link
            to={url}
            style={{
                display: "flex",
                alignItems: "center",
                height: "24px",
                textDecoration: "none",
                fontWeight: "400",
                fontSize: "16px",
            }}
            data-testid="back"
        >
            <ArrowBackIosNew
                sx={{
                    width: "24px",
                    height: "24px",
                    color: ({ palette }) => palette.primary.dark,
                    mr: "8px",
                }}
            />
            <Typography variant="h5">{text}</Typography>
        </Link>
    );
}
