import { Theme } from "@mui/material";

export default function Accordion(baseTheme: Theme) {
    return {
        MuiAccordion: {
            styleOverrides: {
                root: {
                    boxShadow: "none",
                    backgroundColor: baseTheme.palette.info.main,
                    width: "255px",
                    "& .MuiAccordionDetails-root": {
                        padding: 0,
                    },
                    "& .MuiAccordionSummary-content.Mui-expanded": {
                        margin: 0,
                    },
                    "& .MuiAccordionSummary-root": {
                        height: "64px",
                    },
                },
            },
        },
    };
}
