import React, { useEffect } from "react";

import { Box, Stack, styled, Typography } from "@mui/material";
import { LinkMoveBack } from "Components/LinkMoveBack";
import { Logo, logoColors } from "Components/Logo";
import { useLocation } from "react-router-dom";

import { RegistrationForm } from "./Components/RegistrationForm";
import { Slider } from "./Components/Slider";

const Container = styled(Stack)(({ theme }) => ({
    width: "100%",
    maxWidth: "1920px",
    padding: "20px 0 20px 40px",
    boxSizing: "border-box",
    justifyContent: "space-between",

    flexDirection: "row",
    gap: "50px",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        padding: "50px 0",
        gap: 0,
    },
    [theme.breakpoints.down("sm")]: {},
}));

const FormContainer = styled(Stack)(({ theme }) => ({
    width: "50%",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
        width: "90%",
    },
}));

const Header = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
        fontSize: "32px",
    },
}));

export function RegistrationPage() {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (
        <Stack sx={{ justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <Container>
                <FormContainer>
                    <Stack>
                        <Stack sx={{ gap: "30px" }}>
                            <Logo color={logoColors.green} />
                            <LinkMoveBack url="/" text="Back" />
                            <Box>
                                <Header variant="h3">Register on the MyBanq App</Header>
                                <Typography variant="h6">Provide account details below</Typography>
                            </Box>
                        </Stack>
                        <RegistrationForm />
                    </Stack>
                </FormContainer>
                <Slider issideSlider />
            </Container>
        </Stack>
    );
}
