/* eslint-disable no-param-reassign */
import React, { useState } from "react";

import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
    Box,
    Checkbox,
    IconButton,
    Stack,
    styled,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import { ErrorField } from "Components/ErrorField";
import { Loader } from "Components/Loader";
import { StyledButton } from "Components/StyledButton";
import { Form, Formik, FormikErrors } from "formik";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { routesPaths } from "routesPaths";
import { addEncryptedToStorage, getDecryptedFromStorage } from "utils/encryption";
import * as yup from "yup";

import useAuthService, { LoginBodyType } from "../useAuthService";

export const validationSchema = yup.object({
    email: yup
        .string()
        .trim()
        .matches(
            /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/i,
            "Invalid email",
        )
        .required("Please enter email address"),
    password: yup.string().trim().required("Please enter a password"),
});

export const FormContainer = styled(Stack)(({ theme }) => ({
    maxWidth: "430px",
    [theme.breakpoints.down("md")]: {
        margin: "auto",
        paddingTop: "30px",
    },
}));

const TyposContainer = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "27px",
    [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        gap: "20px",
    },
}));

export function AuthorizationForm() {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const { login } = useAuthService();
    const prevPage = location.state?.from?.pathname;
    const [isFirstLogin] = useState(prevPage === "/verify-page");
    const [isPassVisible, setPassVisibility] = useState(false);
    const [isLoaderOpen, setLoaderOpen] = useState(false);
    const handlePassClick = () => {
        setPassVisibility(!isPassVisible);
    };
    const currentOnbUserEmail = getDecryptedFromStorage(localStorage, "currentOnbUserEmail");
    const currentOnbUserPassword = getDecryptedFromStorage(localStorage, "currentOnbUserPassword");
    const onSubmitForm = (values: LoginBodyType, errors: FormikErrors<LoginBodyType>) => {
        setLoaderOpen(true);
        login({
            email: values.email,
            password: values.password,
            isRemembered: values.isRemembered,
        })
            .then((response) => {
                setLoaderOpen(false);
                localStorage.removeItem("newUserEmail");
                localStorage.removeItem("newUserPassword");
                if (values.isRemembered) {
                    addEncryptedToStorage(localStorage, "currentOnbUserEmail", values.email);
                    addEncryptedToStorage(localStorage, "currentOnbUserPassword", values.password);
                }
                if (
                    response.data?.data?.overallSubmissionStatus === "SUBMITTED" ||
                    (response.data?.data?.overallSubmissionStatus === "INCOMPLETE" &&
                        response.data?.data?.overallReviewStatus !== null)
                ) {
                    navigate(routesPaths.dashboard);
                } else
                    navigate(routesPaths.business_info, {
                        state: { from: location, isFirstLogin },
                    });
            })
            .catch((err) => {
                if (err.response) {
                    switch (err?.response?.request?.status) {
                        case 401:
                            errors.email = "Email and password combination is incorrect";
                            break;
                        case 403:
                            errors.email = "Email and password combination is incorrect";
                            break;
                        case 404:
                            errors.email = "Email and password combination is incorrect";
                            break;
                        default:
                            errors.email = `${err.message} error`;
                    }
                }
                setLoaderOpen(false);
            });
    };
    return (
        <>
            <Formik<LoginBodyType>
                initialValues={{
                    email: currentOnbUserEmail || "",
                    password: currentOnbUserPassword || "",
                    isRemembered: false,
                }}
                onSubmit={() => console.log("here")}
                validationSchema={validationSchema}
            >
                {({ values, errors, handleBlur, handleChange, touched, setErrors }) => (
                    <Form id="login" data-testid="form" autoComplete="off">
                        <FormContainer>
                            <Box mt="20px">
                                <Box>
                                    <Typography variant="subtitle2">Email Address</Typography>
                                    <TextField
                                        autoComplete="new-password"
                                        inputProps={{
                                            style: {
                                                fontSize: 16,
                                            },
                                        }}
                                        type="email"
                                        name="email"
                                        error={errors.email !== undefined && touched.email}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            e.target.value = e.target.value.trim();
                                            handleChange(e);
                                            setErrors({ ...errors, email: undefined });
                                        }}
                                        value={values.email}
                                        placeholder="Enter email address"
                                    />
                                    <ErrorField touched={touched.email} errors={errors.email} />
                                </Box>
                                <Box>
                                    <Typography variant="subtitle2">Password</Typography>
                                    <TextField
                                        autoComplete="new-password"
                                        InputProps={{
                                            style: {
                                                fontSize: 16,
                                            },
                                            endAdornment: (
                                                <IconButton onClick={handlePassClick} edge="end">
                                                    {isPassVisible ? (
                                                        <VisibilityOutlinedIcon />
                                                    ) : (
                                                        <VisibilityOffOutlinedIcon />
                                                    )}
                                                </IconButton>
                                            ),
                                        }}
                                        name="password"
                                        error={errors.password !== undefined && touched.password}
                                        type={isPassVisible ? "text" : "password"}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            e.target.value = e.target.value.trim();
                                            handleChange(e);
                                            setErrors({ ...errors, password: undefined });
                                        }}
                                        value={values.password}
                                        placeholder="Enter password"
                                    />
                                    <ErrorField
                                        touched={touched.password}
                                        errors={errors.password}
                                    />
                                </Box>
                            </Box>
                            <TyposContainer>
                                <Stack direction="row" spacing="8px" sx={{ alignItems: "center" }}>
                                    <Checkbox onChange={handleChange} name="isRemembered" />
                                    <Typography
                                        variant="body2"
                                        sx={{ color: ({ palette }) => palette.info.contrastText }}
                                    >
                                        Remember me
                                    </Typography>
                                </Stack>
                                <Link
                                    to={routesPaths.reset_password_page}
                                    style={{
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        textDecoration: "none",
                                        color: `${theme.palette.info.contrastText}`,
                                    }}
                                >
                                    Forgotten your password?
                                </Link>
                            </TyposContainer>
                            <StyledButton
                                text="Login"
                                width="100%"
                                isDisabled={
                                    !!errors.email ||
                                    !!errors.password ||
                                    !values.email ||
                                    !values.password
                                }
                                onHandleClick={() => onSubmitForm(values, errors)}
                            />
                            <Typography
                                variant="body2"
                                align="center"
                                sx={{
                                    color: ({ palette }) => palette.info.contrastText,
                                    mt: "30px",
                                }}
                            >
                                Don&apos;t have an account?{" "}
                                <Link
                                    to={routesPaths.registration}
                                    style={{
                                        textDecoration: "none",
                                        color: `${theme.palette.primary.main}`,
                                        fontWeight: 600,
                                    }}
                                >
                                    Sign up
                                </Link>
                            </Typography>
                        </FormContainer>
                    </Form>
                )}
            </Formik>
            <Loader isLoaderOpen={isLoaderOpen} />
        </>
    );
}
