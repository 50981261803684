import React from "react";

import { App } from "App";
import ThemeConfig from "Assets/Styles";
import ReactDOM from "react-dom/client";
import "Assets/Styles/index.css";

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(rootElement);

root.render(
    <React.StrictMode>
        <ThemeConfig>
            <App />
        </ThemeConfig>
    </React.StrictMode>,
);
