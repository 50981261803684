import React from "react";

import { FormFrame } from "Components/FormFrame";
import { Heading } from "Components/Heading";
import { StepperFrame } from "Components/StepperFrame";
import { PageContainer } from "Pages/APIDocumentationPage/APIDocumentationPage";

import { PersonalizationForm } from "./Components/PersonalizationForm";

export function PersonalizationPage() {
    return (
        <FormFrame>
            <StepperFrame step={2}>
                <PageContainer>
                    <Heading />
                    <PersonalizationForm />
                </PageContainer>
            </StepperFrame>
        </FormFrame>
    );
}
