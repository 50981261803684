import React from "react";

import { Box, List } from "@mui/material";
import { additionalSteps, steps } from "utils/constants";
import { getDecryptedFromStorage } from "utils/encryption";

import { NavItem } from "./NavItem";

export function NavBar() {
    const isSubmitted = getDecryptedFromStorage(localStorage, "isSubmitted");
    const institution = getDecryptedFromStorage(localStorage, "institutionId");
    const displayedSteps = institution ? steps : [];
    const menuItems =
        isSubmitted === "true" ? [...displayedSteps, ...additionalSteps] : displayedSteps;
    return (
        <Box sx={{ width: "100%", maxWidth: 270, bgcolor: "primary.dark" }}>
            <List>
                {menuItems.map((item) => (
                    <NavItem key={item.id} item={item} />
                ))}
            </List>
        </Box>
    );
}
