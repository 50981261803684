import React from "react";

import { Box, Stack } from "@mui/material";

import { SettlementsType } from "../ApprovalPage.model";
import { APITextPart } from "./APITextPart";
import { FileItem } from "./FileItem";
import { Label } from "./Label";
import { NoInfoMessage } from "./NoInfoMessage";

export function APISection({ data }: { data: SettlementsType | undefined }) {
    return data ? (
        <>
            {data.apiDocName && (
                <Box mb="30px">
                    <Label label="API Document" />
                    <FileItem name={data.apiDocName} status="" />
                </Box>
            )}
            <Stack gap="20px">
                <Stack spacing={2}>
                    <APITextPart data={data} />
                    {data.settlementLetterName && (
                        <Box mb="30px">
                            <Label label="Letter of Authorization" />
                            <FileItem name={data.settlementLetterName} status="" />
                        </Box>
                    )}
                </Stack>
            </Stack>
        </>
    ) : (
        <NoInfoMessage />
    );
}
