import React from "react";

import { FormFrame } from "Components/FormFrame";
import { Heading } from "Components/Heading";
import { StepperFrame } from "Components/StepperFrame";
import { PageContainer } from "Pages/APIDocumentationPage/APIDocumentationPage";

import { BusinessInfoForm } from "./Components/BusinessInfoForm";

export function BusinessInfoPage() {
    return (
        <FormFrame>
            <StepperFrame step={1}>
                <PageContainer>
                    <Heading />
                    <BusinessInfoForm />
                </PageContainer>
            </StepperFrame>
        </FormFrame>
    );
}
