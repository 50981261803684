import { Theme } from "@mui/material";

export default function Button(baseTheme: Theme) {
    return {
        MuiButton: {
            styleOverrides: {
                contained: {
                    color: baseTheme.palette.primary.dark,
                    backgroundColor: baseTheme.palette.primary.light,
                    textAlign: "center",
                    border: "none",
                    boxShadow: "none",
                    "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.8)",
                    },
                },
                outlined: {
                    boxSizing: "content-box",
                    color: baseTheme.palette.info.dark,
                    backgroundColor: baseTheme.palette.primary.light,
                    textAlign: "center",
                    border: "1px solid",
                    borderColor: baseTheme.palette.info.dark,
                    boxShadow: "none",
                    "&:hover": {
                        color: baseTheme.palette.primary.main,
                    },
                },
                root: {
                    width: "197px",
                    height: "44px",
                    padding: 0,
                    color: baseTheme.palette.primary.light,
                    borderRadius: "6px",
                    textTransform: "none",
                    fontWeight: 500,
                    fontSize: "14px",
                    fontFamily: "Inter-Medium",
                    "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.2)",
                    },
                    "&.Mui-disabled": {
                        backgroundColor: "#C4E9E7",
                        color: "#EDF4F5",
                    },
                },
            },
        },
    };
}