import React from "react";

import { Avatar, Stack, styled, Typography } from "@mui/material";
import { getDecryptedFromStorage } from "utils/encryption";

const BankName = styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.darker,
    fontWeight: 600,
    fontSize: "32px",
    [theme.breakpoints.down("sm")]: {
        display: "none",
    },
}));

export function Heading() {
    const storedBankName = getDecryptedFromStorage(localStorage, "bankName");
    const bankName = storedBankName || "";
    const trimName = (name: string) => {
        if (name) {
            const arr = name.split(" ");
            if (arr.length > 1) {
                return `${arr[0][0]}${arr[1][0]}`;
            }
            return `${arr[0][0]}${arr[0][1]}`;
        }
        return name;
    };
    return bankName ? (
        <Stack sx={{ width: "80%" }}>
            <Stack direction="row" sx={{ alignItems: "center", gap: "40px" }}>
                <Avatar
                    data-testid="avatar"
                    sx={{
                        width: "80px",
                        height: "80px",
                        bgcolor: ({ palette }) => palette.primary.light,
                        color: ({ palette }) => palette.primary.main,
                        fontWeight: 400,
                        fontSize: "32px",
                    }}
                >
                    {trimName(bankName)}
                </Avatar>
                <BankName>{bankName}</BankName>
            </Stack>
        </Stack>
    ) : null;
}
