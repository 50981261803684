import React from "react";

import { Stack, Typography } from "@mui/material";
import { INotification } from "types/Models";

interface IViewNotifPropsType {
    data: INotification;
    setNotification: (value: INotification | undefined) => void;
    setModalOpen: (value: boolean) => void;
}

export function ViewCell({ data, setNotification, setModalOpen }: IViewNotifPropsType) {
    const onNotificationView = (notif: INotification | undefined) => {
        setNotification(notif);
        setModalOpen(true);
    };

    return (
        <Stack height="70px">
            <Typography
                variant="button"
                color="text.disabled"
                margin="auto"
                onClick={() => onNotificationView(data)}
                sx={{ cursor: "pointer" }}
                className="mainSelection"
            >
                View
            </Typography>
        </Stack>
    );
}
