import React, { useEffect, useLayoutEffect, useState } from "react";

import { FormFrame } from "Components/FormFrame";
import { Heading } from "Components/Heading";
import { Loader } from "Components/Loader";
import { SnackBar } from "Components/SnackBar";
import { StepperFrame } from "Components/StepperFrame";
import { PageContainer } from "Pages/APIDocumentationPage/APIDocumentationPage";
import { getOverviewInfo } from "Pages/CommonPage/CommonPage.service";

import { ISubmissionData } from "./ApprovalPage.model";
import { getStatuses } from "./ApprovalPage.service";
import { Details } from "./Components/Details";
import { Info } from "./Components/Info";

export function ApprovalPage() {
    const [statuses, setStatuses] = useState(undefined);
    const [submissionData, setSubmissionData] = useState<ISubmissionData | undefined>(undefined);
    const [isSubmitted, setIsSubmitted] = useState(
        !!submissionData && submissionData.overallSubmissionStatus === "SUBMITTED",
    );
    const [isLoaderOpen, setLoaderOpen] = useState(false);
    const [isSnackOpen, setSnackOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");

    useLayoutEffect(
        () =>
            setIsSubmitted(
                !!submissionData && submissionData.overallSubmissionStatus === "SUBMITTED",
            ),
        [submissionData],
    );

    useEffect(() => {
        setLoaderOpen(true);
        getStatuses()
            .then((result) => {
                setStatuses(result.data.data);
            })
            .catch((err) => {
                if (
                    err.response &&
                    err.response.request.status !== 500 &&
                    err.response.request.status !== 404
                ) {
                    setSnackMessage(err.message);
                    setSnackOpen(true);
                }
            })
            .finally(() => setLoaderOpen(false));
    }, []);

    useEffect(() => {
        setLoaderOpen(true);
        getOverviewInfo()
            .then((result) => {
                setSubmissionData(result.data.data);
            })
            .catch((err) => {
                if (err.response && err.response.request.status === 403) {
                    setSnackMessage(
                        "This step has been approved. You cannot make changes after approval",
                    );
                    setSnackOpen(true);
                } else if (
                    err.response &&
                    err.response.request.status !== 500 &&
                    err.response.request.status !== 404
                ) {
                    setSnackMessage(err.message);
                    setSnackOpen(true);
                }
            })
            .finally(() => setLoaderOpen(false));
    }, []);

    return (
        <FormFrame>
            <StepperFrame step={6}>
                <PageContainer>
                    <Info
                        isSubmitted={isSubmitted}
                        percentage={submissionData && submissionData.submissionPercentage}
                    />
                    <Heading />
                    <Details
                        setIsSubmitted={setIsSubmitted}
                        statuses={statuses}
                        submissionData={submissionData}
                        percentage={submissionData && submissionData.submissionPercentage}
                    />
                    <Loader isLoaderOpen={isLoaderOpen} />
                    <SnackBar
                        isModalOpen={isSnackOpen}
                        setModalOpen={setSnackOpen}
                        modalMessage={snackMessage}
                        modalType="error"
                    />
                </PageContainer>
            </StepperFrame>
        </FormFrame>
    );
}
