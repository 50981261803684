import React from "react";

import { Box, Grid, Stack, Typography } from "@mui/material";
import { PersonalizationType } from "Pages/Personalization/PersonalizationPage.models";

import { InfoItem } from "./InfoItem";
import { Label } from "./Label";

export function PersonalizationTextSection({ data }: { data: PersonalizationType | undefined }) {
    const isAnyLink =
        data &&
        (data.facebook ||
            data.instagram ||
            data.whatsapp ||
            data.twitter ||
            data.linkedin ||
            data.website);
    return (
        <>
            {data && (data.shortName || data.primaryColor || data.secondaryColor) && (
                <Grid container>
                    {data.shortName && (
                        <Grid item xs={12} md={4}>
                            <InfoItem name="Short Bank Name" value={data.shortName || ""} />
                        </Grid>
                    )}
                    {data.primaryColor && (
                        <Grid item xs={12} md={4}>
                            <Box>
                                <Label label="Primary Colour" />
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <Box
                                        sx={{
                                            width: "18px",
                                            height: "18px",
                                            borderRadius: "2px",
                                            backgroundColor: data?.primaryColor || "",
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontWeight: 600,
                                            fontSize: "16px",
                                            color: ({ palette }) => palette.secondary.darker,
                                        }}
                                    >
                                        {data?.primaryColor || ""}
                                    </Typography>
                                </Stack>
                            </Box>
                        </Grid>
                    )}
                    {data.secondaryColor && (
                        <Grid item xs={12} md={4}>
                            <Box>
                                <Label label="Secondary Colour" />
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <Box
                                        sx={{
                                            width: "18px",
                                            height: "18px",
                                            borderRadius: "2px",
                                            backgroundColor: data?.secondaryColor || "",
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontWeight: 600,
                                            fontSize: "16px",
                                            color: ({ palette }) => palette.secondary.darker,
                                        }}
                                    >
                                        {data?.secondaryColor || ""}
                                    </Typography>
                                </Stack>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            )}
            {isAnyLink && (
                <Grid container rowGap={3}>
                    {data.facebook && (
                        <Grid item xs={12} md={6}>
                            <InfoItem name="Facebook URL" value={data.facebook} />
                        </Grid>
                    )}
                    {data?.instagram && (
                        <Grid item xs={12} md={6}>
                            <InfoItem name="Instagram URL" value={data.instagram} />
                        </Grid>
                    )}
                    {data.whatsapp && (
                        <Grid item xs={12} md={6}>
                            <InfoItem name="Whatsapp" value={data.whatsapp} />
                        </Grid>
                    )}
                    {data.twitter && (
                        <Grid item xs={12} md={6}>
                            <InfoItem name="Twitter URL" value={data.twitter} />
                        </Grid>
                    )}
                    {data.linkedin && (
                        <Grid item xs={12} md={6}>
                            <InfoItem name="LinkedIn URL" value={data.linkedin} />
                        </Grid>
                    )}
                    {data.website && (
                        <Grid item xs={12} md={6}>
                            <InfoItem name="Website URL" value={data.website} />
                        </Grid>
                    )}
                </Grid>
            )}
        </>
    );
}
