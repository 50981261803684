import React, { useEffect, useState } from "react";

import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
    Box,
    Button,
    Checkbox,
    IconButton,
    Stack,
    styled,
    TextField,
    Typography,
} from "@mui/material";
import { CustomInputLabel } from "Components/CustomInputLabel";
import { ErrorField } from "Components/ErrorField";
import { Heading } from "Components/Heading";
import { Loader } from "Components/Loader";
import { SimpleTextInput } from "Components/SimpeTextInput";
import { SnackBar } from "Components/SnackBar";
import { StyledButton } from "Components/StyledButton";
import { Form, Formik } from "formik";
import { PageContainer } from "Pages/APIDocumentationPage/APIDocumentationPage";
import { useNavigate } from "react-router-dom";
import { routesPaths } from "routesPaths";
import { initialState } from "utils/initialState";

import { DataType } from "../DevConsole.model";
import { getDevConsoleInfo, submitDevConsoleInfo } from "../DevConsole.service";
import { validationSchema } from "../DevConsole.validation";
import { ConsoleSection } from "./ConsoleSection";

const FormContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.light,
    marginBottom: "70px",
    padding: "60px 40px 50px 40px",
    maxWidth: "794px",
    overflow: "hidden",
    border: "1px solid",
    borderRadius: "8px",
    borderColor: theme.palette.warning.contrastText,
    [theme.breakpoints.down("sm")]: {
        padding: "20px 10px 0",
    },
}));

export function DevConsoleForm() {
    const [isRegistred, setIsRegistred] = useState(false);
    const [receivedData, setReceivedData] = useState(initialState.devConsole);
    const [isPassVisible, setPassVisibility] = useState(false);
    const navigate = useNavigate();
    const handlePassClick = () => {
        setPassVisibility(!isPassVisible);
    };
    const [isLoaderOpen, setLoaderOpen] = useState(false);
    const [isSnackOpen, setSnackOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    useEffect(() => {
        setLoaderOpen(true);
        getDevConsoleInfo()
            .then((result) => {
                setReceivedData(result.data.data);
                setIsRegistred(!!result.data.data.clientSecret || !!result.data.data.clientId);
            })
            .catch((err) => {
                if (
                    err.response &&
                    err.response.request.status !== 500 &&
                    err.response.request.status !== 404
                ) {
                    setSnackMessage(err.message);
                    setSnackOpen(true);
                }
                console.error(err);
            })
            .finally(() => setLoaderOpen(false));
    }, []);
    const onSubmitForm = (values: DataType) => {
        setLoaderOpen(true);
        submitDevConsoleInfo({ ...values })
            .then(() => {
                navigate(routesPaths.approval);
            })
            .catch((err) => {
                if (err.response && err.response.request.status === 403) {
                    setSnackMessage(
                        "This step has been approved. You cannot make changes after approval",
                    );
                    setSnackOpen(true);
                } else if (
                    err.response &&
                    err.response.request.status !== 500 &&
                    err.response.request.status !== 404
                ) {
                    setSnackMessage(err.message);
                    setSnackOpen(true);
                }
            })
            .finally(() => setLoaderOpen(false));
    };
    const onSkip = () => {
        navigate(routesPaths.approval);
    };
    return (
        <PageContainer>
            <Heading />
            <Typography
                sx={{
                    pb: "23px",
                    fontWeight: 700,
                    fontSize: "24px",
                }}
            >
                Interswitch Developer Console
            </Typography>
            <ConsoleSection />
            <Stack direction="row" spacing="8px" sx={{ alignItems: "center", m: "27px 0" }}>
                <Checkbox
                    value={isRegistred}
                    checked={isRegistred}
                    onChange={() => {
                        setIsRegistred(!isRegistred);
                    }}
                    name="isRegistred"
                />
                <Typography variant="body2">
                    Have you registered your bank on Interswitch Developer Console?
                </Typography>
            </Stack>
            <Stack>
                <Formik<DataType>
                    enableReinitialize
                    initialValues={receivedData}
                    onSubmit={() => console.log("here")}
                    validateOnMount
                    validationSchema={validationSchema}
                >
                    {({ values, errors, touched, handleChange, handleBlur, isValid }) => (
                        <Form id="console" data-testid="form" autoComplete="new-password">
                            <Stack>
                                {isRegistred && (
                                    <FormContainer>
                                        <SimpleTextInput
                                            errors={errors.clientId}
                                            touched={touched.clientId}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            value={values.clientId}
                                            label="Client ID"
                                            name="clientId"
                                            placeholder="Enter client ID"
                                            width="100%"
                                            isRequired
                                        />
                                        <CustomInputLabel label="Secret Key" isRequired />
                                        <input
                                            autoComplete="new-password"
                                            type="email"
                                            style={{ display: "none" }}
                                        />
                                        <TextField
                                            sx={{ maxWidth: "545px" }}
                                            InputProps={{
                                                style: {
                                                    fontSize: 16,
                                                },
                                                endAdornment: (
                                                    <IconButton
                                                        onClick={handlePassClick}
                                                        edge="end"
                                                    >
                                                        {isPassVisible ? (
                                                            <VisibilityOutlinedIcon />
                                                        ) : (
                                                            <VisibilityOffOutlinedIcon />
                                                        )}
                                                    </IconButton>
                                                ),
                                            }}
                                            name="clientSecret"
                                            error={
                                                errors.clientSecret !== undefined &&
                                                touched.clientSecret
                                            }
                                            type={isPassVisible ? "text" : "password"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.clientSecret}
                                            placeholder="Enter secret Key"
                                            autoComplete="new-password"
                                        />
                                        <ErrorField
                                            errors={errors.clientSecret}
                                            touched={touched.clientSecret}
                                        />
                                    </FormContainer>
                                )}
                                <Stack direction="row" sx={{ gap: "35px" }}>
                                    <Button variant="outlined" onClick={onSkip}>
                                        Skip, I’ll fill later
                                    </Button>
                                    <StyledButton
                                        text="Save and continue"
                                        width="197px"
                                        isDisabled={!isValid || !isRegistred}
                                        onHandleClick={() => onSubmitForm(values)}
                                    />
                                </Stack>
                            </Stack>
                        </Form>
                    )}
                </Formik>
            </Stack>
            <Loader isLoaderOpen={isLoaderOpen} />
            <SnackBar
                isModalOpen={isSnackOpen}
                setModalOpen={setSnackOpen}
                modalMessage={snackMessage}
                modalType="error"
            />
        </PageContainer>
    );
}
