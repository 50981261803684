import { alpha } from "@mui/material/styles";

const GREY = {
    0: "#FFFFFF",
    50: "#F8F9FC",
    100: "#E8E8E8",
    200: "#9CA3AF",
    300: "#6B7280",
    400: "#7F91A8",
    500: "#6D7E95",
    600: "#6E84AC",
    700: "#4B4B4B",
    800: "#353F50",
    900: "#021921",
    400_58: alpha("#7F91A8", 0.58),
    400_16: alpha("#7F91A8", 0.16),
};

const PRIMARY = {
    lighter: "#F4FFFE",
    light: "#FFFFFF",
    main: "#02B8A6",
    dark: "#021921",
    contrastText: "#F7931E",
};
const SECONDARY = {
    light: "#5F738C",
    main: "#00DDC7",
    dark: "#4E5A6C",
    darker: "#353F50",
    contrastText: "#AAB7C6",
};
const INFO = {
    lighter: "#B5BFCD",
    light: "#F9FAFB",
    main: "#F3F5F6",
    dark: "#9CA3AF",
    darker: "#354052",
    contrastText: "#6B7280",
};
const SUCCESS = {
    lighter: "#ebebeb",
    light: "#C4C4C4",
    main: "#5FC068",
    dark: "#16A34A",
    contrastText: "#0275D8",
};
const WARNING = {
    lighter: "#E7F8F6",
    light: "#FCD34D",
    main: "#DC4437",
    dark: "#00425F",
    contrastText: "#E1E6ED",
};
const ERROR = {
    lighter: "#FFF1F1",
    light: "#EF4444",
    main: "#DC2626",
    dark: "#ED342B",
    darker: "#B8592B",
    contrastText: "#fff",
};

const palette = {
    common: { black: "#000", white: "#fff" },
    primary: { ...PRIMARY },
    secondary: { ...SECONDARY },
    info: { ...INFO },
    success: { ...SUCCESS },
    warning: { ...WARNING },
    error: { ...ERROR },
    grey: GREY,
    text: { primary: GREY[900], secondary: GREY[200], disabled: GREY[500] },
    action: {
        active: GREY[300],
        hover: GREY[400_58],
        disabled: GREY[400_58],
        focus: GREY[700],
        hoverOpacity: 0.08,
        disabledOpacity: 0.48,
        selected: "#DCFCE7",
    },
};

export default palette;
