import React from "react";

import { Stack } from "@mui/material";
import { DevConsoleType } from "Pages/DevConsolePage/DevConsole.model";

import { InfoItem } from "./InfoItem";
import { NoInfoMessage } from "./NoInfoMessage";

export function DevConsoleSection({ data }: { data: DevConsoleType | undefined }) {
    return data?.clientId && data.clientSecret ? (
        <Stack spacing={5}>
            <InfoItem
                name="Confirmation"
                value="XYZ Microfinance Bank has registered on Interswitch Developer Console"
            />
            <Stack direction="row" spacing={3}>
                <InfoItem name="Client ID" value={data.clientId} />
                <InfoItem name="Secret Key" value={data.clientSecret} />
            </Stack>
        </Stack>
    ) : (
        <NoInfoMessage />
    );
}
