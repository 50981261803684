import * as yup from "yup";

export const validationSchema = yup.object({
    email: yup
        .string()
        .trim()
        .matches(
            /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/i,
            "Invalid email",
        )
        .required("This field is required"),
    password: yup
        .string()
        .trim()
        .min(10, "Password must contain at least 10 characters")
        .max(50, "Password must not exceed 50 characters")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/\d/, "Password must contain at least one digit")
        .matches(
            /[!@#&()\-[\]{}:;',?/*~$^+=<>]/,
            "Password must contain 1 or more special symbol: !@#&()-[]{}:;',?/*~$^+=<>",
        )
        .matches(
            /^[a-zA-Z0-9!@#&()\-[\]{}:;',?/*~$^+=<>]+$/,
            "Password contains invalid characters",
        )
        .required("This field is required"),
    passwordConfirmation: yup
        .string()
        .trim()
        .oneOf([yup.ref("password")], "Passwords do not match")
        .required("This field is required"),
    isAgreed: yup.boolean().oneOf([true], "You must accept the terms and conditions"),
});
