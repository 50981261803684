import React, { useState } from "react";

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack,
    styled,
} from "@mui/material";
import { Logo, logoColors } from "Components/Logo";
import useAuthService from "Pages/Authorization/useAuthService";
import { useNavigate } from "react-router-dom";
import { routesPaths } from "routesPaths";

import { BurgerMenu } from "./BurgerMenu";
import { StyledButton } from "./StyledButton";

interface IHeaderProps {
    onOpenSidebar: () => void;
}

const HeaderContainer = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    width: "100%",
    backgroundColor: theme.palette.primary.light,
    boxSizing: "border-box",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px 70px",
    gap: "20px",
    [theme.breakpoints.down("md")]: {
        padding: "15px",
    },
    [theme.breakpoints.down("sm")]: {
        alignItems: "flex-start",
    },
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
        display: "none",
    },
}));

export function Header({ onOpenSidebar }: IHeaderProps) {
    const navigate = useNavigate();
    const { logout } = useAuthService();
    const [isDialogOpen, setDialogOpen] = useState(false);
    const handleClose = () => setDialogOpen(false);
    const agreeToLogout = () => {
        logout();
        navigate(routesPaths.index);
    };
    return (
        <HeaderContainer data-testid="header">
            <Stack direction="row" alignItems="center">
                <BurgerMenu onOpenSidebar={onOpenSidebar} />
                <Logo color={logoColors.green} />
            </Stack>
            <ButtonContainer>
                <StyledButton
                    text="Log Out"
                    width="197px"
                    isDisabled={false}
                    onHandleClick={() => {
                        setDialogOpen(true);
                    }}
                />
            </ButtonContainer>
            <Dialog open={isDialogOpen} onClose={handleClose}>
                <DialogTitle>Are you sure you want to log out?</DialogTitle>
                <DialogContent>
                    <DialogContentText>Your changes may not be saved</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={agreeToLogout}
                        variant="outlined"
                        sx={{
                            width: "197px",
                            color: ({ palette }) => palette.info.contrastText,
                        }}
                    >
                        Yes
                    </Button>
                    <StyledButton
                        onHandleClick={handleClose}
                        text="No"
                        isDisabled={false}
                        width="197px"
                    />
                </DialogActions>
            </Dialog>
        </HeaderContainer>
    );
}
