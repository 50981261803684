import React, { useState } from "react";

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack,
} from "@mui/material";
import useAuthService from "Pages/Authorization/useAuthService";
import { useNavigate } from "react-router-dom";
import { routesPaths } from "routesPaths";

import { NavBar } from "./NavBar";
import { StyledButton } from "./StyledButton";

export function SideBar() {
    const navigate = useNavigate();
    const { logout } = useAuthService();
    const [isDialogOpen, setDialogOpen] = useState(false);
    const handleClose = () => setDialogOpen(false);
    const agreeToLogout = () => {
        logout();
        navigate(routesPaths.index);
    };
    return (
        <Stack
            sx={{
                bgcolor: "primary.dark",
                justifyContent: "space-between",
                height: "fit-content",
            }}
        >
            <Box width="270px" height="100%">
                <NavBar />
            </Box>
            <Box p="35px">
                <StyledButton
                    text="Log Out"
                    width="197px"
                    isDisabled={false}
                    onHandleClick={() => {
                        setDialogOpen(true);
                    }}
                />
            </Box>
            <Dialog open={isDialogOpen} onClose={handleClose}>
                <DialogTitle>Are you sure you want to log out?</DialogTitle>
                <DialogContent>
                    <DialogContentText>Your changes may not be saved</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={agreeToLogout}
                        variant="outlined"
                        sx={{
                            width: "197px",
                            color: ({ palette }) => palette.info.contrastText,
                        }}
                    >
                        Yes
                    </Button>
                    <StyledButton
                        onHandleClick={handleClose}
                        text="No"
                        isDisabled={false}
                        width="197px"
                    />
                </DialogActions>
            </Dialog>
        </Stack>
    );
}
