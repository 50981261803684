export default function Paper() {
    return {
        MuiPaper: {
            styleOverrides: {
                root: {
                    maxWidth: "none !important",
                },
            },
        },
    };
}
