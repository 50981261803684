import { Theme } from "@mui/material";

export default function Textfield(baseTheme: Theme) {
    return {
        MuiTextField: {
            styleOverrides: {
                root: {
                    borderRadius: "4px",
                    backgroundColor: baseTheme.palette.info.main,
                    width: "100%",
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent",
                    },

                    "& .MuiInputBase-input": {
                        color: baseTheme.palette.secondary.light,
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: "4px",
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: baseTheme.palette.info.main,
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: baseTheme.palette.info.main,
                    },
                    "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                        borderColor: baseTheme.palette.warning.main,
                    },
                    "&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderWidth: "1px",
                    },
                },
                notchedOutline: {
                    borderColor: baseTheme.palette.primary.light,
                    top: 0,
                    legend: {
                        display: "none",
                    },
                },
                input: {
                    color: baseTheme.palette.secondary.light,
                    fontFamily: baseTheme.typography.fontFamily,
                    fontStyle: "normal",
                    fontWeight: "400",
                    // lineHeight: "24px",
                    fontSize: "16px",
                },
            },
        },
    };
}