import { ChangeEvent, useCallback, useState } from "react";

export type UpdateValue = (event: ChangeEvent<HTMLInputElement>, forceName?: string) => void;
export type UpdateValueByName = (name: string, value: string) => void;

export const useFormValues = (initialValues: any) => {
    const [filesValues, setValues] = useState(initialValues);

    const updateValue: UpdateValueByName = useCallback((name: string, value: string) => {
        setValues((prevValues: any) => ({ ...prevValues, [name]: value }));
    }, []);

    return {
        filesValues,
        updateValue,
    };
};
