import React from "react";

import DoneIcon from "@mui/icons-material/Done";
import { Box, Stack, styled, SvgIcon, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { routesPaths } from "routesPaths";

export const vertSteps = [
    {
        id: 1,
        header: "Business Information",
        url: routesPaths.submissions,
    },
    {
        id: 2,
        header: "Personalisation",
        url: routesPaths.personalization_review,
    },
    {
        id: 3,
        header: "KYC Information",
        url: routesPaths.kyc_review,
    },
    {
        id: 4,
        header: "API Documentation",
        url: routesPaths.api_review,
    },
    {
        id: 5,
        header: "Developer Console",
        url: routesPaths.dev_console_review,
    },
    {
        id: 6,
        header: "Approval",
        url: routesPaths.approval_review,
    },
];

type StepperPropTypes = {
    step: number;
};

const Container = styled(Stack)(({ theme }) => ({
    width: "250px",
    height: "auto",
    border: "1px solid",
    borderColor: theme.palette.warning.contrastText,
    borderRadius: "7px",
    padding: "25px",
    backgroundColor: theme.palette.primary.light,
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05)",
    marginRight: "46px",
    gap: "40px",
    [theme.breakpoints.down("lg")]: {
        width: "100%",
        margin: "auto",
        flexDirection: "row",
        gap: "10px",
        padding: "25px 10px ",
        justifyContent: "space-around",
    },
}));

const Inner = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    alignItems: "flex-start",
    gap: "20px",
    [theme.breakpoints.down("lg")]: {
        flexDirection: "column",
        alignItems: "center",
        gap: "10px",
    },
}));

const Header = styled(Stack)(({ theme }) => ({
    [theme.breakpoints.down("lg")]: {
        marginLeft: "0 !important",
        alignItems: "center",
        textAlign: "center",
    },
    [theme.breakpoints.down("md")]: {
        display: "none",
    },
}));

export function SimpleVerticalStepper({ step }: StepperPropTypes) {
    return (
        <Container>
            {vertSteps.map(({ id, header, url }) => (
                <Link
                    key={id}
                    to={url}
                    style={{
                        textDecoration: "none",
                    }}
                >
                    <Inner>
                        <Box
                            sx={{
                                width: "26px",
                                height: "26px",
                                borderRadius: "50%",
                                border: "1px solid",
                                flexShrink: 0,
                                borderColor:
                                    step < id
                                        ? ({ palette }) => palette.info.dark
                                        : ({ palette }) => palette.secondary.main,
                                backgroundColor:
                                    step <= id
                                        ? "transparent"
                                        : ({ palette }) => palette.secondary.main,
                            }}
                        >
                            {id < step && (
                                <SvgIcon
                                    component={DoneIcon}
                                    inheritViewBox
                                    sx={{
                                        mt: "2px",
                                        ml: "2px",
                                        fontSize: "20px",
                                        color: ({ palette }) => palette.primary.light,
                                    }}
                                />
                            )}
                        </Box>
                        <Header>
                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    fontSize: "14px",
                                    color:
                                        step < id
                                            ? ({ palette }) => palette.info.dark
                                            : ({ palette }) => palette.secondary.main,
                                }}
                            >
                                {header}
                            </Typography>
                        </Header>
                    </Inner>
                </Link>
            ))}
        </Container>
    );
}
