import React from "react";

import MenuIcon from "@mui/icons-material/Menu";
import { Box, IconButton, styled } from "@mui/material";

interface IDashboardNavbarProps {
    onOpenSidebar: () => void;
}

const Burger = styled(Box)(({ theme }) => ({
    display: "flex",
    marginRight: "20px",
    [theme.breakpoints.up("md")]: {
        display: "none",
    },
}));

export function BurgerMenu({ onOpenSidebar }: IDashboardNavbarProps) {
    return (
        <Burger>
            <IconButton
                onClick={onOpenSidebar}
                sx={{
                    "&:hover": {
                        backgroundColor: "transparent",
                    },
                }}
            >
                <MenuIcon sx={{ mr: 1 }} />
            </IconButton>
        </Burger>
    );
}
