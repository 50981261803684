import CryptoJS from "crypto-js";

const key = process.env.REACT_APP_AES_KEY ? process.env.REACT_APP_AES_KEY : "ReseRvedKeY$57";

export const doEncrypt = (data: string) => CryptoJS.AES.encrypt(data, key).toString();
export const doDecrypt = (data: string) => {
    let res;
    try {
        res = CryptoJS.AES.decrypt(data, key).toString(CryptoJS.enc.Utf8);
    } catch (err) {
        console.error(err);
        return undefined;
    }
    return res;
};

export const addEncryptedToStorage = (storage: Storage, item: string, data: string) => {
    const encryptedData = doEncrypt(data);
    storage.setItem(item, encryptedData);
};

export const getDecryptedFromStorage = (storage: Storage, item: string) => {
    const encryptedData = storage.getItem(item);
    return encryptedData ? doDecrypt(encryptedData) : "";
};
