/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";

import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    styled,
    Typography,
} from "@mui/material";
import { CustomInputLabel } from "Components/CustomInputLabel";
import { DragnDropInputFiles } from "Components/DragnDropInputFiles";
import { ErrorField } from "Components/ErrorField";
import { Loader } from "Components/Loader";
import { SectionLabel } from "Components/SectionLabel";
import { SimpleTextInput } from "Components/SimpeTextInput";
import { SnackBar } from "Components/SnackBar";
import { StyledButton } from "Components/StyledButton";
import { Form, Formik } from "formik";
import { checkData } from "Pages/BusinessInfoPage/Components/BusinessInfoForm";
import { useFormValues } from "Pages/Personalization/Components/setData";
import { useNavigate } from "react-router-dom";
import { routesPaths } from "routesPaths";
import { banks } from "utils/helpers";
import { initialState } from "utils/initialState";

import { validationSchema } from "./APIDocumantation.validation";
import { IDataType } from "./APIDocumentation.model";
import { getAPIDocData, postAPIDocData } from "./APIDocumentation.service";
import { InstructionsList } from "./InstructionsList";
import { Modal } from "./Modal";

const AccountsContainer = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    justifyContent: "space-between",
    marginRight: "40px",
    gap: "15px",
    [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        gap: 0,
    },
}));

export function APIDocumentationForm() {
    const navigate = useNavigate();
    const { filesValues, updateValue } = useFormValues({
        settlementLetterURL: "",
        settlementLetterName: "",
        apiDocURL: "",
        apiDocName: "",
    });
    const [receivedData, setReceivedData] = useState(initialState.settlements);
    const [isModalOpen, setModalOpen] = useState(true);
    const [isLoaderOpen, setLoaderOpen] = useState(false);
    const [isSnackOpen, setSnackOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    useEffect(() => {
        setLoaderOpen(true);
        getAPIDocData()
            .then((result) => {
                setReceivedData(checkData(result.data.data));
                updateValue("settlementLetterURL", result.data.data.settlementLetterURL);
                updateValue("settlementLetterName", result.data.data.settlementLetterName);
                updateValue("apiDocURL", result.data.data.apiDocURL);
                updateValue("apiDocName", result.data.data.apiDocName);
            })
            .catch((err) => {
                if (
                    err.response &&
                    err.response.request.status !== 500 &&
                    err.response.request.status !== 404
                ) {
                    setSnackMessage(err.message);
                    setSnackOpen(true);
                }
            })
            .finally(() => setLoaderOpen(false));
    }, []);

    const onSubmitForm = (values: IDataType) => {
        setLoaderOpen(true);
        postAPIDocData({ ...values, ...filesValues })
            .then(() => {
                navigate(routesPaths.dev_console);
            })
            .catch((err) => {
                if (err.response && err.response.request.status === 403) {
                    setSnackMessage(
                        "This step has been approved. You cannot make changes after approval",
                    );
                    setSnackOpen(true);
                } else if (
                    err.response &&
                    err.response.request.status !== 500 &&
                    err.response.request.status !== 404
                ) {
                    setSnackMessage(err.message);
                    setSnackOpen(true);
                }
            })
            .finally(() => setLoaderOpen(false));
    };
    const onSkip = () => {
        navigate(routesPaths.dev_console);
    };
    const handleClose = () => {
        setModalOpen(false);
    };
    return (
        <Stack>
            <Box
                sx={{
                    bgcolor: ({ palette }) => palette.primary.light,
                    p: "70px 40px 70px 40px",
                    maxWidth: "950px",
                    overflow: "hidden",
                    border: "1px solid",
                    borderRadius: "8px",
                    borderColor: ({ palette }) => palette.warning.contrastText,
                }}
            >
                {isModalOpen ? (
                    <Typography
                        sx={{
                            pb: "23px",
                            fontWeight: 700,
                            fontSize: "24px",
                        }}
                    >
                        API Documentation
                    </Typography>
                ) : (
                    <InstructionsList />
                )}
                <Stack spacing={5}>
                    <Formik<IDataType>
                        enableReinitialize
                        initialValues={receivedData}
                        onSubmit={() => console.log("here")}
                        validateOnBlur
                        validationSchema={validationSchema}
                    >
                        {({ values, errors, handleBlur, touched, handleChange, isValid }) => (
                            <Form id="settlements" data-testid="form">
                                <Box>
                                    <CustomInputLabel label="Upload API document" isRequired />
                                    <DragnDropInputFiles
                                        data-testid="apiDocument"
                                        name="apiDocument"
                                        id="apiDocument"
                                        handleBlur={handleBlur}
                                        setFileURL={(url, name) => {
                                            updateValue("apiDocURL", url);
                                            updateValue("apiDocName", name);
                                        }}
                                        fileURL={filesValues.apiDocURL}
                                        fileName={filesValues.apiDocName}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        maxWidth: "755px",
                                        height: "1.5px",
                                        margin: "auto",
                                        m: "0 0 37px",
                                        bgcolor: ({ palette }) => palette.info.main,
                                    }}
                                />
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        pb: "30px",
                                    }}
                                >
                                    Settlement and Transfers Setup
                                </Typography>

                                <Stack>
                                    <Box>
                                        <Box maxWidth="545px">
                                            <CustomInputLabel
                                                label="Collection Bank Name"
                                                isRequired
                                            />
                                            <FormControl
                                                fullWidth
                                                sx={{
                                                    backgroundColor: ({ palette }) =>
                                                        palette.info.main,
                                                    borderRadius: "4px",
                                                }}
                                            >
                                                <InputLabel
                                                    sx={{
                                                        color: ({ palette }) =>
                                                            palette.secondary.contrastText,
                                                        "&.Mui-focused": {
                                                            display: "none",
                                                        },
                                                        "&.MuiFormLabel-filled": {
                                                            display: "none",
                                                        },
                                                    }}
                                                >
                                                    Select Bank
                                                </InputLabel>
                                                <Select
                                                    IconComponent={ExpandMoreRoundedIcon}
                                                    name="collectionBank"
                                                    value={values.collectionBank}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.collectionBank !== undefined &&
                                                        touched.collectionBank
                                                    }
                                                >
                                                    {banks.map((item, index) => (
                                                        <MenuItem key={index} value={item}>
                                                            {item}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <ErrorField
                                                touched={touched.collectionBank}
                                                errors={errors.collectionBank}
                                            />
                                        </Box>
                                        <SimpleTextInput
                                            errors={errors.collectionAccountNumber}
                                            touched={touched.collectionAccountNumber}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            value={values.collectionAccountNumber}
                                            label="Account Number"
                                            name="collectionAccountNumber"
                                            placeholder="Enter account number"
                                            width="100%"
                                            isRequired
                                        />
                                        <Box>
                                            <CustomInputLabel
                                                label="Letter of Authorization from Settlement Bank"
                                                isRequired
                                            />
                                            <DragnDropInputFiles
                                                data-testid="authLetter"
                                                name="authLetter"
                                                id="authLetter"
                                                handleBlur={handleBlur}
                                                setFileURL={(url, name) => {
                                                    updateValue("settlementLetterURL", url);
                                                    updateValue("settlementLetterName", name);
                                                }}
                                                fileURL={filesValues.settlementLetterURL}
                                                fileName={filesValues.settlementLetterName}
                                            />
                                        </Box>
                                    </Box>
                                    <Box>
                                        <SectionLabel
                                            padding={45}
                                            label="Live Account Number for Validations on Production"
                                        />
                                        <AccountsContainer>
                                            <SimpleTextInput
                                                errors={errors.liveAccount1}
                                                touched={touched.liveAccount1}
                                                handleBlur={handleBlur}
                                                handleChange={handleChange}
                                                value={values.liveAccount1}
                                                label="Account Number 1"
                                                name="liveAccount1"
                                                placeholder="Enter account number"
                                                width="auto"
                                                isRequired
                                            />
                                            <SimpleTextInput
                                                errors={errors.liveAccount2}
                                                touched={touched.liveAccount2}
                                                handleBlur={handleBlur}
                                                handleChange={handleChange}
                                                value={values.liveAccount2}
                                                label="Account Number 2"
                                                name="liveAccount2"
                                                placeholder="Enter account number"
                                                width="auto"
                                                isRequired
                                            />
                                            <SimpleTextInput
                                                errors={errors.liveAccount3}
                                                touched={touched.liveAccount3}
                                                handleBlur={handleBlur}
                                                handleChange={handleChange}
                                                value={values.liveAccount3}
                                                label="Account Number 3"
                                                name="liveAccount3"
                                                placeholder="Enter account number"
                                                width="auto"
                                                isRequired
                                            />
                                        </AccountsContainer>
                                    </Box>
                                    <Box>
                                        <SectionLabel
                                            padding={45}
                                            label="Test Account Number for Test Environment"
                                        />
                                        <AccountsContainer>
                                            <SimpleTextInput
                                                errors={errors.testAccount1}
                                                touched={touched.testAccount1}
                                                handleBlur={handleBlur}
                                                handleChange={handleChange}
                                                value={values.testAccount1}
                                                label="Account Number 1"
                                                name="testAccount1"
                                                placeholder="Enter account number"
                                                width="auto"
                                                isRequired
                                            />
                                            <SimpleTextInput
                                                errors={errors.testAccount2}
                                                touched={touched.testAccount2}
                                                handleBlur={handleBlur}
                                                handleChange={handleChange}
                                                value={values.testAccount2}
                                                label="Account Number 2"
                                                name="testAccount2"
                                                placeholder="Enter account number"
                                                width="auto"
                                                isRequired
                                            />
                                            <SimpleTextInput
                                                errors={errors.testAccount3}
                                                touched={touched.testAccount3}
                                                handleBlur={handleBlur}
                                                handleChange={handleChange}
                                                value={values.testAccount3}
                                                label="Account Number 3"
                                                name="testAccount3"
                                                placeholder="Enter account number"
                                                width="auto"
                                                isRequired
                                            />
                                        </AccountsContainer>
                                    </Box>
                                    <SimpleTextInput
                                        errors={errors.settlementLiveGLAcct}
                                        touched={touched.settlementLiveGLAcct}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        value={values.settlementLiveGLAcct}
                                        label="GL Account for Production"
                                        name="settlementLiveGLAcct"
                                        placeholder="Enter account number"
                                        width="100%"
                                        isRequired
                                    />
                                    <SimpleTextInput
                                        errors={errors.settlementTestGLAcct}
                                        touched={touched.settlementTestGLAcct}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        value={values.settlementTestGLAcct}
                                        label="GL Account for Test"
                                        name="settlementTestGLAcct"
                                        placeholder="Enter account number"
                                        width="100%"
                                        isRequired
                                    />

                                    <Stack direction="row" sx={{ gap: "35px", mt: "88px" }}>
                                        <Button variant="outlined" onClick={onSkip}>
                                            Skip, I’ll fill later
                                        </Button>
                                        <StyledButton
                                            text="Save and continue"
                                            width="197px"
                                            isDisabled={
                                                !isValid ||
                                                !filesValues.settlementLetterURL ||
                                                !filesValues.apiDocURL
                                            }
                                            onHandleClick={() => onSubmitForm(values)}
                                        />
                                    </Stack>
                                </Stack>
                            </Form>
                        )}
                    </Formik>
                    <Modal isModalOpen={isModalOpen} handleClose={handleClose} />
                    <Loader isLoaderOpen={isLoaderOpen} />
                    <SnackBar
                        isModalOpen={isSnackOpen}
                        setModalOpen={setSnackOpen}
                        modalMessage={snackMessage}
                        modalType="error"
                    />
                </Stack>
            </Box>
        </Stack>
    );
}
