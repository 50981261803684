import React, { useState } from "react";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import { Box, Stack, TextField, Typography, useTheme } from "@mui/material";
import { ErrorField } from "Components/ErrorField";
import { Loader } from "Components/Loader";
import { Modal } from "Components/Modal";
import { StyledButton } from "Components/StyledButton";
import { Form, Formik } from "formik";

import { ContactUsDataType } from "../ContactUs.model";
import { submitContactUs } from "../ContactUs.service";
import { validationSchema } from "../ContactUs.validation";

export function ContactForm() {
    const theme = useTheme();
    const [isSucceded, setResult] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [isModalOpen, setModalOpen] = useState(false);
    const [isLoaderOpen, setLoaderOpen] = useState(false);
    const successModalData = {
        header: "Success",
        icon: DoneRoundedIcon,
        content: modalMessage,
        buttonText: "Got it!",
        color: theme.palette.primary.main,
    };
    const failModalData = {
        header: "Fail",
        icon: CloseRoundedIcon,
        content: modalMessage,
        buttonText: "Got it!",
        color: theme.palette.primary.main,
    };
    const modalConfig = isSucceded ? successModalData : failModalData;
    const handleClose = () => {
        setModalOpen(false);
    };
    const onSubmitForm = (values: ContactUsDataType) => {
        setLoaderOpen(true);
        submitContactUs({ ...values })
            .then((result) => {
                setModalMessage(result.data.description);
                setResult(true);
            })
            .catch(() => {
                setModalMessage("Unfortunately, your message has NOT been sent. Try once again!");
                setResult(false);
            })
            .finally(() => {
                setModalOpen(true);
                setLoaderOpen(false);
            });
    };
    return (
        <>
            <Formik<ContactUsDataType>
                initialValues={{
                    fullName: "",
                    email: "",
                    phoneNumber: "",
                    message: "",
                }}
                onSubmit={() => console.log("here")}
                validateOnBlur
                validationSchema={validationSchema}
            >
                {({ values, errors, handleBlur, isValid, touched, handleChange }) => (
                    <Form id="contacts" data-testid="form">
                        <Stack>
                            <Box>
                                <Typography variant="subtitle2">Full Name</Typography>
                                <TextField
                                    inputProps={{
                                        style: {
                                            fontSize: 16,
                                        },
                                    }}
                                    name="fullName"
                                    error={errors.fullName !== undefined && touched.fullName}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.fullName}
                                    placeholder="Enter your full name"
                                />
                                <ErrorField errors={errors.fullName} touched={touched.fullName} />
                            </Box>
                            <Box>
                                <Typography variant="subtitle2">Email Address</Typography>
                                <TextField
                                    inputProps={{
                                        style: {
                                            fontSize: 16,
                                        },
                                    }}
                                    name="email"
                                    error={errors.email !== undefined && touched.email}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.email}
                                    placeholder="Enter a valid email address"
                                />
                                <ErrorField errors={errors.email} touched={touched.email} />
                            </Box>
                            <Box>
                                <Typography variant="subtitle2">Phone number</Typography>
                                <TextField
                                    inputProps={{
                                        style: {
                                            fontSize: 16,
                                        },
                                    }}
                                    name="phoneNumber"
                                    error={errors.phoneNumber !== undefined && touched.phoneNumber}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        e.target.value = e.target.value.trim();
                                        if (
                                            e.target.value.length >= 1 &&
                                            e.target.value[0] !== "+"
                                        ) {
                                            e.target.value = `+234${e.target.value}`;
                                        }
                                        handleChange(e);
                                    }}
                                    value={values.phoneNumber}
                                    placeholder="Enter your phone number"
                                />
                                <ErrorField
                                    errors={errors.phoneNumber}
                                    touched={touched.phoneNumber}
                                />
                            </Box>
                            <Box>
                                <Typography variant="subtitle2">Message</Typography>
                                <TextField
                                    inputProps={{
                                        style: {
                                            fontSize: 16,
                                        },
                                    }}
                                    name="message"
                                    error={errors.message !== undefined && touched.message}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.message}
                                    placeholder="Type your message"
                                    multiline
                                    rows={4}
                                />
                                {touched.message && errors.message ? (
                                    <Typography
                                        variant="body2"
                                        sx={{ color: "red", height: "40px" }}
                                    >
                                        <ErrorRoundedIcon
                                            fontSize="small"
                                            color="warning"
                                            sx={{ position: "relative", top: "6px", mr: "5px" }}
                                        />
                                        {errors.message}
                                    </Typography>
                                ) : (
                                    <Typography
                                        variant="subtitle2"
                                        color="secondary.contrastText"
                                        align="right"
                                        height="40px"
                                    >
                                        {values.message.length}/1000
                                    </Typography>
                                )}
                            </Box>
                            <StyledButton
                                text="Submit"
                                width="100%"
                                isDisabled={!isValid || Boolean(!Object.keys(touched).length)}
                                onHandleClick={() => onSubmitForm(values)}
                            />
                        </Stack>
                    </Form>
                )}
            </Formik>
            <Modal isModalOpen={isModalOpen} handleClose={handleClose} modalConfig={modalConfig} />
            <Loader isLoaderOpen={isLoaderOpen} />
        </>
    );
}
