import React, { useState } from "react";

import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { Box, Stack, SvgIcon, Typography } from "@mui/material";
import { ReactComponent as download } from "Assets/Images/download2.svg";
import { KYCType } from "Pages/KYCInfoPage/KYC.model";

import { SectionFooter } from "../SectionFooter";

export function AccordionFile({ item, url }: { item: KYCType["documents"][0]; url: string }) {
    const [isExpanded, setIsExpanded] = useState(false);
    const reviewColor = item.reviewStatus === "APPROVED" ? "green" : "warning.main";
    return (
        <Stack>
            <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" onClick={() => setIsExpanded(!isExpanded)} spacing={1}>
                    <SvgIcon
                        component={isExpanded ? ExpandLessRoundedIcon : ExpandMoreRoundedIcon}
                        inheritViewBox
                        sx={{
                            color: ({ palette }) => palette.info.darker,
                            fontSize: "30px",
                        }}
                    />
                    <Typography
                        sx={{
                            fontWeight: 600,
                            fontSize: "16px",
                            color:
                                item.reviewStatus === "APPROVED" ||
                                item.reviewStatus === "UPDATE_REQUESTED"
                                    ? reviewColor
                                    : ({ palette }) => palette.secondary.dark,
                        }}
                    >
                        {item.documentName}
                    </Typography>
                </Stack>
                <a href={item.documentURL} download>
                    <SvgIcon
                        component={download}
                        inheritViewBox
                        sx={{
                            color: ({ palette }) => palette.info.main,
                            fontSize: "16px",
                            fill: "transparent",
                            stroke: ({ palette }) => palette.info.dark,
                        }}
                    />
                </a>
            </Stack>
            {isExpanded && (
                <Box p="10px 0px 20px 35px">
                    <SectionFooter
                        status={item.reviewStatus}
                        message={item?.message}
                        editURL={url}
                    />
                </Box>
            )}
        </Stack>
    );
}
