import React from "react";

import { Box, Stack, styled } from "@mui/material";
import { DashboardSideBar } from "Pages/Dashboard/Components/DashboardSideBar";
import { getDecryptedFromStorage } from "utils/encryption";

interface IFrameProps {
    children: JSX.Element;
}

export const FrameContainer = styled(Stack)(({ theme }) => ({
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.info.main,
    minHeight: "100vh",
}));

export const SideMenuContainer = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
        display: "none",
    },
}));

export const ContentContainer = styled(Stack)(({ theme }) => ({
    width: "100%",
    [theme.breakpoints.down("md")]: {
        paddingLeft: 0,
    },
}));

export function FormFrame({ children }: IFrameProps) {
    const isSubmitted = getDecryptedFromStorage(localStorage, "isSubmitted");
    return (
        <FrameContainer>
            <Stack width="100%" minHeight="100vh" direction="row">
                {isSubmitted === "true" && (
                    <SideMenuContainer>
                        <DashboardSideBar />
                    </SideMenuContainer>
                )}
                <ContentContainer sx={{ paddingLeft: isSubmitted ? "270px" : 0 }}>
                    {children}
                </ContentContainer>
            </Stack>
        </FrameContainer>
    );
}
