import React from "react";

import { Box, Stack, Typography } from "@mui/material";
import { PersonalizationTextSection } from "Pages/ApprovalPage/Components/PersonalizationTextPart";
import { routesPaths } from "routesPaths";

import { SectionFooter } from "../SectionFooter";
import { SectionHeader } from "../SectionHeader";
import { SimpleVerticalStepper } from "../SimpleVerticalStepper";
import { useData } from "../SubmissionPage";
import { Container } from "./API";
import { PersonalizationFiles } from "./PersonalizationFiles";

export function Personalization() {
    const data = useData()?.data?.personalization;
    return (
        <>
            <SimpleVerticalStepper step={2} />
            <Container>
                <SectionHeader name="Personalization" needPercentage={false} needStatus={false} />
                {data ? (
                    <Stack spacing={5}>
                        <PersonalizationTextSection data={data} />
                        <PersonalizationFiles data={data} />
                    </Stack>
                ) : (
                    <Typography variant="body1" color="warning.main" fontStyle="italic">
                        No information to display. Please fill out the appropriate form
                    </Typography>
                )}
                <Box
                    sx={{
                        width: "100%",
                        height: "1px",
                        margin: "auto",
                        m: "37px 0",
                        bgcolor: ({ palette }) => palette.success.light,
                    }}
                />
                <SectionFooter
                    status={data?.reviewStatus}
                    message={data?.message}
                    editURL={routesPaths.personalization}
                />
            </Container>
        </>
    );
}
