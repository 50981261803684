/* eslint-disable no-param-reassign */
export const trimName = (nameToTrim: string) => {
    if (nameToTrim.length > 30) {
        let newTrimmedName;
        if (nameToTrim.lastIndexOf(".") !== -1) {
            const namePart = nameToTrim.substring(0, nameToTrim.lastIndexOf("."));
            const extension = nameToTrim.substring(nameToTrim.lastIndexOf("."));
            newTrimmedName = `${namePart.substring(0, 30)}...  ${extension}`;
        } else {
            newTrimmedName = `${nameToTrim.substring(0, 30)}... `;
        }
        return newTrimmedName;
    }
    return nameToTrim;
};

export const formatDate = (date: string | number) => {
    try {
        if (typeof date === "number") {
            date = new Date(date).toISOString();
        }
        const dateISO = new Date(date);
        const day = dateISO.getUTCDate().toString().padStart(2, "0");
        const monthName = new Intl.DateTimeFormat("en-US", { month: "short" }).format(dateISO);
        const year = dateISO.getUTCFullYear();
        const localTime = dateISO.toLocaleTimeString("en-GB");
        // eslint-disable-next-line prefer-const
        let [hour, minutes] = localTime.split(":");
        const interval = +hour > 12 ? "PM" : "AM";
        if (+hour > 12) {
            hour = (+hour - 12).toString();
        }
        return `${day}-${monthName}-${year} | ${hour}:${minutes}${interval}`;
    } catch {
        return "";
    }
};

export const states = [
    "Abia",
    "Adamawa",
    "Akwa Ibom",
    "Anambra",
    "Bauchi",
    "Bayelsa",
    "Benue",
    "Borno",
    "Cross River",
    "Delta",
    "Ebonyi",
    "Edo",
    "Ekiti",
    "Enugu",
    "FCT",
    "Gombe",
    "Imo",
    "Jigawa",
    "Kaduna",
    "Kano",
    "Katsina",
    "Kebbi",
    "Kogi",
    "Kwara",
    "Lagos",
    "Nasarawa",
    "Niger",
    "Ogun",
    "Ondo",
    "Osun",
    "Oyo",
    "Plateau",
    "Rivers",
    "Sokoto",
    "Taraba",
    "Yobe",
    "Zamfara",
];

export const banks = [
    "Access Bank Plc",
    "Citibank Nigeria Limited",
    "Ecobank Nigeria Plc",
    "Fidelity Bank Plc",
    "First Bank Nigeria Limited",
    "First City Monument Bank Plc",
    "Globus Bank Limited",
    "Guaranty Trust Bank Plc",
    "Heritage Banking Company Ltd",
    "Keystone Bank Limited",
    "Parallex Bank Ltd",
    "Polaris Bank Plc",
    "Premium Trust Bank",
    "Providus Bank",
    "Stanbic IBTC Bank Plc",
    "Standard Chartered Bank Nigeria Ltd.",
    "Sterling Bank Plc",
    "SunTrust Bank Nigeria Limited",
    "Titan Trust Bank Ltd",
    "Union Bank of Nigeria Plc",
    "United Bank For Africa Plc",
    "Unity Bank Plc",
    "Wema Bank Plc",
    "Zenith Bank Plc",
];
