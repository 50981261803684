
export default function Grid() {
    return {
        MuiGrid: {
            styleOverrides: {
                root: {
                    "& .MuiGrid-item": {
                        padding: 0,
                    },
                },
            },
        },
    };
}