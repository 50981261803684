import React from "react";

import { SvgIcon, Tooltip } from "@mui/material";
import { ReactComponent as Info } from "Assets/Images/info.svg";

type TooltipPropsType = {
    content: string;
};

export function FileUploaderTooltip({ content }: TooltipPropsType) {
    return (
        <Tooltip title={content} placement="right-start">
            <SvgIcon
                component={Info}
                inheritViewBox
                sx={{
                    fontSize: "16px",
                    position: "absolute",
                    left: "101%",
                    top: 0,
                }}
            />
        </Tooltip>
    );
}
