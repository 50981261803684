/* eslint-disable react/require-default-props */
import React from "react";

import { Box, Stack, styled, SvgIcon, Typography, useTheme } from "@mui/material";
import { ReactComponent as Approve } from "Assets/Images/approve.svg";
import { ReactComponent as Rejected } from "Assets/Images/rejected.svg";
import { ReactComponent as Wait } from "Assets/Images/wait2.svg";

type SectionHeaderPropsType = {
    name: string;
    needPercentage: boolean;
    needStatus: boolean;
    status?: string | undefined;
    percentage?: number | string;
};

const Progress = styled(Box)(({ theme }) => ({
    width: "160px",
    height: "4px",
    backgroundColor: theme.palette.info.main,
    borderRadius: "5px",
    [theme.breakpoints.down("md")]: {
        width: "250px",
    },
}));

const statuses = {
    APPROVED: "Approved",
    UPDATE_REQUESTED: "Update requested",
};

const logoMappings = {
    APPROVED: Approve,
    UPDATE_REQUESTED: Rejected,
};

export function SectionHeader({
    name,
    needPercentage,
    needStatus,
    status,
    percentage,
}: SectionHeaderPropsType) {
    const theme = useTheme();
    const reviewStatus = statuses[status as keyof typeof statuses] || "Pending review";
    const logo = logoMappings[status as keyof typeof logoMappings] || Wait;
    const textColorMappings = {
        APPROVED: theme.palette.secondary.main,
        UPDATE_REQUESTED: theme.palette.error.light,
    };
    const textColor =
        textColorMappings[status as keyof typeof textColorMappings] || theme.palette.warning.light;
    return (
        <>
            <Stack direction="row" justifyContent="space-between">
                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: "18px",
                        color: ({ palette }) => palette.info.dark,
                    }}
                >
                    {name}
                </Typography>
                {needPercentage && (
                    <Stack>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography variant="caption" color="info.contrastText">
                                Completion
                            </Typography>
                            <Typography variant="caption" color="info.contrastText">
                                {percentage}%
                            </Typography>
                        </Stack>
                        <Progress>
                            <Box
                                sx={{
                                    width: `${percentage}%`,
                                    height: "100%",
                                    borderRadius: "5px",
                                    backgroundColor: ({ palette }) => palette.success.main,
                                }}
                            />
                        </Progress>
                    </Stack>
                )}
                {needStatus && (
                    <Stack direction="row" spacing={1} alignItems="center">
                        <SvgIcon
                            component={logo}
                            inheritViewBox
                            sx={{
                                m: "5px",
                                fontSize: "22px",
                                color: ({ palette }) => palette.grey[300],
                            }}
                        />
                        <Typography variant="caption" color={textColor}>
                            {reviewStatus}
                        </Typography>
                    </Stack>
                )}
            </Stack>
            <Box
                sx={{
                    width: "100%",
                    height: "1px",
                    margin: "auto",
                    m: "37px 0",
                    bgcolor: ({ palette }) => palette.success.light,
                }}
            />
        </>
    );
}
