/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";

import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    styled,
    Typography,
    useTheme,
} from "@mui/material";
import { ReactComponent as thumb } from "Assets/Images/thumb.svg";
import { CustomInputLabel } from "Components/CustomInputLabel";
import { ErrorField } from "Components/ErrorField";
import { Loader } from "Components/Loader";
import { Modal } from "Components/Modal";
import { SectionLabel } from "Components/SectionLabel";
import { SimpleTextInput } from "Components/SimpeTextInput";
import { SnackBar } from "Components/SnackBar";
import { StyledButton } from "Components/StyledButton";
import { Form, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { routesPaths } from "routesPaths";
import { addEncryptedToStorage, getDecryptedFromStorage } from "utils/encryption";
import { states } from "utils/helpers";
import { initialState } from "utils/initialState";

import { BusinessType } from "../BusinessInfo.model";
import { getBusinessProfile, submitBusinessProfile } from "../BusinessInfo.service";
import { validationSchema } from "../BusinessInfo.validation";

// remove checkData?
export function checkData(data: any) {
    const newData = { ...data };
    // eslint-disable-next-line no-restricted-syntax
    for (const key in newData) {
        if (newData[key] === null) {
            newData[key] = "";
        }
    }
    return newData;
}

const FormContainer = styled(Box)(({ theme }) => ({
    width: "63%",
    [theme.breakpoints.down("sm")]: {
        width: "50%",
    },
}));

export function BusinessInfoForm() {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const institution = getDecryptedFromStorage(localStorage, "institutionId");
    const { isFirstLogin } = location.state || false;
    const [receivedData, setReceivedData] = useState(initialState.profile);
    const [isModalOpen, setModalOpen] = useState(isFirstLogin || false);
    const [isLoaderOpen, setLoaderOpen] = useState(false);
    const [isSnackOpen, setSnackOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const modalConfig = {
        header: "Great Job!",
        icon: thumb,
        content: "Your email has been verified successfully",
        buttonText: "Get started",
        color: theme.palette.info.main,
    };
    useEffect(() => {
        setLoaderOpen(true);
        getBusinessProfile()
            .then((result) => {
                setReceivedData(checkData(result.data.data));
            })
            .catch((err) => {
                if (
                    err.response &&
                    err.response.request.status !== 500 &&
                    err.response.request.status !== 404
                ) {
                    setSnackMessage(err.message);
                    setSnackOpen(true);
                } else console.error(err);
            })
            .finally(() => setLoaderOpen(false));
    }, []);
    const onSubmitForm = (values: BusinessType) => {
        setLoaderOpen(true);
        submitBusinessProfile({ ...values })
            .then((response) => {
                addEncryptedToStorage(localStorage, "bankName", values.name);
                navigate(routesPaths.personalization);
                if (response?.data?.data?.id)
                    addEncryptedToStorage(
                        localStorage,
                        "institutionId",
                        response.data.data.id.toString(),
                    );
            })
            .catch((err) => {
                if (err.response && err.response.request.status === 403) {
                    setSnackMessage(
                        "This step has been approved. You cannot make changes after approval",
                    );
                    setSnackOpen(true);
                } else if (
                    err.response &&
                    err.response.request.status !== 500 &&
                    err.response.request.status !== 404
                ) {
                    setSnackMessage(err.message);
                    setSnackOpen(true);
                }
            })
            .finally(() => setLoaderOpen(false));
    };
    const onSkip = () => {
        navigate(routesPaths.personalization);
    };
    const handleClose = () => {
        setModalOpen(false);
    };
    return (
        <>
            <Formik<BusinessType>
                enableReinitialize
                initialValues={receivedData}
                onSubmit={() => console.log("here")}
                validateOnMount
                validationSchema={validationSchema}
            >
                {({ values, errors, handleBlur, touched, handleChange, isValid }) => (
                    <Form key="business-info-form" id="business_info" data-testid="form">
                        <Stack flexGrow={1} width="90%">
                            <Box
                                sx={{
                                    bgcolor: ({ palette }) => palette.primary.light,
                                    p: "30px 40px 0 40px",
                                    maxWidth: "950px",
                                    overflow: "hidden",
                                    border: "1px solid",
                                    borderRadius: "8px",
                                    borderColor: ({ palette }) => palette.warning.contrastText,
                                }}
                            >
                                <Typography
                                    sx={{
                                        pb: "23px",
                                        fontWeight: 700,
                                        fontSize: "24px",
                                    }}
                                >
                                    Business Information
                                </Typography>
                                <Box
                                    sx={{
                                        maxWidth: "755px",
                                        width: "95%",
                                        height: "1.5px",
                                        margin: "auto",
                                        m: "37px 0",
                                        bgcolor: ({ palette }) => palette.info.main,
                                    }}
                                />
                                <Stack
                                    sx={{
                                        maxHeight: "1000px",
                                        overflowY: "scroll",
                                        overflowX: "hidden",
                                        marginRight: "-270px",
                                        mb: "32px",
                                        gap: "40px",
                                    }}
                                >
                                    <FormContainer>
                                        <SectionLabel padding={25} label="Business Information" />
                                        <SimpleTextInput
                                            errors={errors.name}
                                            touched={touched.name}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            value={values.name}
                                            label="Registered Bank Name"
                                            name="name"
                                            placeholder="Enter your full bank name"
                                            width="100%"
                                            isRequired
                                        />
                                        <SimpleTextInput
                                            errors={errors.rcNo}
                                            touched={touched.rcNo}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            value={values.rcNo}
                                            label="Business Registration Number"
                                            name="rcNo"
                                            placeholder="RC Number"
                                            width="100%"
                                            isRequired
                                        />
                                        <SimpleTextInput
                                            errors={errors.addressLine1}
                                            touched={touched.addressLine1}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            value={values.addressLine1}
                                            label="Head Office Address Line 1"
                                            name="addressLine1"
                                            placeholder="Enter registered head office address"
                                            width="100%"
                                            isRequired
                                        />
                                        <SimpleTextInput
                                            errors={errors.addressLine2}
                                            touched={touched.addressLine2}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            value={values.addressLine2}
                                            label="Head Office Address Line 2"
                                            name="addressLine2"
                                            placeholder="Enter registered head office address"
                                            width="100%"
                                            isRequired={false}
                                        />
                                        <Stack
                                            direction="row"
                                            maxWidth="545px"
                                            justifyContent="space-between"
                                            alignItems="flex-end"
                                        >
                                            <Box width="45%">
                                                <CustomInputLabel label="State" isRequired />
                                                <FormControl
                                                    fullWidth
                                                    sx={{
                                                        backgroundColor: ({ palette }) =>
                                                            palette.info.main,
                                                        borderRadius: "4px",
                                                    }}
                                                >
                                                    <InputLabel
                                                        sx={{
                                                            color: ({ palette }) =>
                                                                palette.secondary.contrastText,
                                                            "&.Mui-focused": {
                                                                display: "none",
                                                            },
                                                            "&.MuiFormLabel-filled": {
                                                                display: "none",
                                                            },
                                                        }}
                                                    >
                                                        Select State
                                                    </InputLabel>
                                                    <Select
                                                        IconComponent={ExpandMoreRoundedIcon}
                                                        name="state"
                                                        value={values.state}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={
                                                            errors.state !== undefined &&
                                                            touched.state
                                                        }
                                                    >
                                                        {states.map((item, index) => (
                                                            <MenuItem key={index} value={item}>
                                                                {item}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                <ErrorField
                                                    touched={touched.state}
                                                    errors={errors.state}
                                                />
                                            </Box>
                                            <SimpleTextInput
                                                errors={errors.zipCode}
                                                touched={touched.zipCode}
                                                handleBlur={handleBlur}
                                                handleChange={handleChange}
                                                value={values.zipCode}
                                                label="Zip Code / Postcode"
                                                name="zipCode"
                                                placeholder=""
                                                width="45%"
                                                isRequired={false}
                                            />
                                        </Stack>
                                        <SimpleTextInput
                                            errors={errors.email}
                                            touched={touched.email}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            value={values.email}
                                            label="Official Email Address"
                                            name="email"
                                            placeholder="Enter official email address"
                                            width="100%"
                                            isRequired
                                        />
                                        <SimpleTextInput
                                            errors={errors.phoneNo}
                                            touched={touched.phoneNo}
                                            handleBlur={handleBlur}
                                            handleChange={(e: { target: { value: string } }) => {
                                                e.target.value = e.target.value.trim();
                                                if (
                                                    e.target.value.length >= 1 &&
                                                    e.target.value[0] !== "+"
                                                ) {
                                                    e.target.value = `+234${e.target.value}`;
                                                }
                                                handleChange(e);
                                            }}
                                            value={values.phoneNo}
                                            label="Head Office Phone Number"
                                            name="phoneNo"
                                            placeholder="Enter phone number of contact person"
                                            width="100%"
                                            isRequired
                                        />
                                    </FormContainer>
                                    <FormContainer>
                                        <SectionLabel padding={25} label="Contact Information" />
                                        <SimpleTextInput
                                            errors={errors.contactName}
                                            touched={touched.contactName}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            value={values.contactName}
                                            label="Primary Contact Name"
                                            name="contactName"
                                            placeholder="Enter full name of main contact person"
                                            width="100%"
                                            isRequired
                                        />
                                        <SimpleTextInput
                                            errors={errors.contactEmail}
                                            touched={touched.contactEmail}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            value={values.contactEmail}
                                            label="Contact Email Address"
                                            name="contactEmail"
                                            placeholder="Enter email of main contact person"
                                            width="100%"
                                            isRequired
                                        />
                                        <SimpleTextInput
                                            errors={errors.contactPhoneNo}
                                            touched={touched.contactPhoneNo}
                                            handleBlur={handleBlur}
                                            handleChange={(e: { target: { value: string } }) => {
                                                e.target.value = e.target.value.trim();
                                                if (
                                                    e.target.value.length >= 1 &&
                                                    e.target.value[0] !== "+"
                                                ) {
                                                    e.target.value = `+234${e.target.value}`;
                                                }
                                                handleChange(e);
                                            }}
                                            value={values.contactPhoneNo}
                                            label="Contact Phone Number"
                                            name="contactPhoneNo"
                                            placeholder="Enter phone number of contact person"
                                            width="100%"
                                            isRequired
                                        />
                                    </FormContainer>
                                </Stack>
                            </Box>
                            <Stack direction="row" sx={{ gap: "35px", mt: "88px" }}>
                                {institution && (
                                    <Button variant="outlined" onClick={onSkip}>
                                        Skip, I’ll fill later
                                    </Button>
                                )}
                                <StyledButton
                                    text="Save and continue"
                                    width="197px"
                                    isDisabled={!isValid}
                                    onHandleClick={() => onSubmitForm(values)}
                                />
                            </Stack>
                        </Stack>
                    </Form>
                )}
            </Formik>
            <Modal isModalOpen={isModalOpen} handleClose={handleClose} modalConfig={modalConfig} />
            <Loader isLoaderOpen={isLoaderOpen} />
            <SnackBar
                isModalOpen={isSnackOpen}
                setModalOpen={setSnackOpen}
                modalMessage={snackMessage}
                modalType="error"
            />
        </>
    );
}
