import React from "react";

import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
    Collapse,
    List,
    ListItemButton,
    ListItemText,
    Stack,
    Typography,
    useTheme,
} from "@mui/material";
import { vertSteps } from "utils/constants";

import { CustomLink } from "./CustomLink";

export interface ISidebarItem {
    id: number;
    header: string;
    content: string;
    url: string;
}

interface INavItemProps {
    item: ISidebarItem;
}

export function NavItem({ item }: INavItemProps) {
    const theme = useTheme();
    const { id, header } = item;
    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(!open);
    };
    const isList = id === 3;
    return isList ? (
        <ListItemButton
            onClick={() => handleClick()}
            sx={{
                flexDirection: "column",
                alignSelf: "flex-start",
                alignItems: "flex-start",
                width: "100%",
                pl: 0,
                backgroundColor: "transparent",
                color: theme.palette.secondary.contrastText,
                "&:hover": {
                    backgroundColor: "transparent",
                },
            }}
        >
            <>
                <Stack
                    data-testid="list-item"
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                >
                    <ListItemText primary={<Typography variant="h4">{header}</Typography>} />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </Stack>
                <Collapse in={open} timeout="auto" unmountOnExit sx={{ width: "100%" }}>
                    <List
                        component="div"
                        disablePadding
                        sx={{ width: "100%", alignItems: "flex-end" }}
                    >
                        {vertSteps.map((step) => (
                            <CustomLink step={step} isCollapseElem />
                        ))}
                    </List>
                </Collapse>
            </>
        </ListItemButton>
    ) : (
        <CustomLink step={item} isCollapseElem={false} />
    );
}
