import { useCallback, useMemo, useState } from "react";

import { api } from "Assets/API/api";
import { addEncryptedToStorage, getDecryptedFromStorage } from "utils/encryption";

export type LoginBodyType = {
    email: string;
    password: string;
    isRemembered: boolean;
};

const useAuthService = () => {
    const authDataBuild = useMemo(() => {
        try {
            return JSON.parse(getDecryptedFromStorage(localStorage, "currentUser") || "");
        } catch (e) {
            return null;
        }
    }, []);
    const [authData, setAuthData] = useState(authDataBuild);
    const [isAuthenticated, setIsAuthenticated] = useState(!!authData);

    const login = useCallback(
        (body: LoginBodyType) =>
            api.post("/auth/login", body).then((res) => {
                localStorage.removeItem("bankName");
                localStorage.removeItem("institutionId");
                const { data } = res;
                const currentUser = { ...data.data };
                if (currentUser?.institution?.name) {
                    addEncryptedToStorage(localStorage, "bankName", currentUser.institution.name);
                }
                if (currentUser?.token) {
                    addEncryptedToStorage(localStorage, "currentUser", JSON.stringify(currentUser));
                    if (currentUser?.institution?.id) {
                        addEncryptedToStorage(
                            localStorage,
                            "institutionId",
                            currentUser.institution.id.toString(),
                        );
                    }
                    addEncryptedToStorage(localStorage, "token", currentUser.token);
                    setAuthData(currentUser);
                    setIsAuthenticated(!!authData);
                }
                return res;
            }),
        [],
    );

    const logout = useCallback(
        () =>
            new Promise<void>((resolve) => {
                localStorage.removeItem("token");
                localStorage.removeItem("currentUser");
                localStorage.removeItem("institutionId");
                localStorage.removeItem("bankName");
                localStorage.removeItem("isSubmitted");
                setIsAuthenticated(false);
                resolve();
            }),
        [],
    );

    return {
        authData,
        isAuthenticated,
        login,
        logout,
    };
};

export default useAuthService;
