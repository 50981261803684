import React, { useEffect } from "react";

import { api } from "Assets/API/api";
import { Location, useLocation, useNavigate } from "react-router-dom";
import { routesPaths } from "routesPaths";

const getToken = (path: Location) => path.search.split("=")[1];

export function VerifyPage() {
    const pathname = useLocation();
    const token = getToken(pathname);
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if (!token) {
            navigate(routesPaths.registration);
        } else {
            api.post(`/auth/verification/complete?token=${token}`, {})
                .then(() => {
                    navigate(routesPaths.authorization, { state: { from: location } });
                })
                .catch((err) => {
                    navigate(routesPaths.registration);
                    console.error(err);
                });
        }
    }, []);
    return <div>You will be redirected</div>;
}
