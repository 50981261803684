/* eslint-disable react/no-array-index-key */
import React from "react";

import { Stack } from "@mui/material";

import { IStatuses, ISubmissionData } from "../ApprovalPage.model";
import { FileItem } from "./FileItem";
import { Label } from "./Label";
import { NoInfoMessage } from "./NoInfoMessage";
import { SectionHeader } from "./SectionHeader";

type PropTypes = {
    data: ISubmissionData | undefined;
    statuses: IStatuses | undefined;
};

export function KYCInfoSection({ data, statuses }: PropTypes) {
    const isCompleted = statuses
        ? statuses.certifications === "SUBMITTED" &&
          statuses.identity === "SUBMITTED" &&
          statuses.license === "SUBMITTED" &&
          statuses.policy === "SUBMITTED" &&
          statuses.other_documents === "SUBMITTED"
        : false;
    return (
        <Stack width="75%">
            <SectionHeader name="KYC Information" isCompleted={isCompleted} />
            <Stack spacing={5}>
                <Stack>
                    <Label label="Certification" />
                    <Stack spacing={1}>
                        {data && data.certifications ? (
                            data.certifications.map((elem, index) => (
                                <FileItem
                                    key={index}
                                    name={elem.documentName || ""}
                                    status={elem.reviewStatus || ""}
                                />
                            ))
                        ) : (
                            <NoInfoMessage />
                        )}
                    </Stack>
                </Stack>
                <Stack>
                    <Label label="Identity Verification" />
                    <Stack spacing={1}>
                        {data && data.identity ? (
                            data.identity.map((elem, index) => (
                                <FileItem
                                    key={index}
                                    name={elem.documentName || ""}
                                    status={elem.reviewStatus || ""}
                                />
                            ))
                        ) : (
                            <NoInfoMessage />
                        )}
                    </Stack>
                </Stack>
                <Stack>
                    <Label label="License Verification" />
                    <Stack spacing={1}>
                        {data && data.license ? (
                            data.license.map((elem, index) => (
                                <FileItem
                                    key={index}
                                    name={elem.documentName || ""}
                                    status={elem.reviewStatus || ""}
                                />
                            ))
                        ) : (
                            <NoInfoMessage />
                        )}
                    </Stack>
                </Stack>
                <Stack>
                    <Label label="Policy Documents" />
                    <Stack spacing={1}>
                        {data && data.policy ? (
                            data.policy.map((elem, index) => (
                                <FileItem
                                    key={index}
                                    name={elem.documentName || ""}
                                    status={elem.reviewStatus || ""}
                                />
                            ))
                        ) : (
                            <NoInfoMessage />
                        )}
                    </Stack>
                </Stack>
                <Stack>
                    <Label label="Other Documents" />
                    <Stack spacing={1}>
                        {data && data.other_documents ? (
                            data.other_documents.map((elem, index) => (
                                <FileItem
                                    key={index}
                                    name={elem.documentName || ""}
                                    status={elem.reviewStatus || ""}
                                />
                            ))
                        ) : (
                            <NoInfoMessage />
                        )}
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
}
